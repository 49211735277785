import { Badge, BadgeVariations } from "@aws-amplify/ui-react";
import { useMemo } from "react";
import {
  MdCancel,
  MdDeliveryDining,
  MdOutlineCancel,
  MdOutlineCleaningServices,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { theme } from "../../../theme";
import {
  CoordinationStatus,
  CoordinationTask,
  CoordinationTaskType,
  CoreCoordinationDraftTask,
  TaskProgressStatus,
} from "../../shared/hooks/core-coordination-task";

export default ({ task }: { task: CoordinationTask }) => {
  const badgeVariation = useMemo(() => {
    if (task.status === CoordinationStatus.Prolonged) {
      return "error" as BadgeVariations;
    }

    if (task.progress_status === TaskProgressStatus.Completed) {
      return "success";
    }

    return (
      (task as CoreCoordinationDraftTask).is_system_proposed
        ? "warning"
        : task.user_id
        ? "info"
        : undefined
    ) as BadgeVariations;
  }, [task]);

  const badgeBackgroundColor = useMemo(() => {
    if (task.status === CoordinationStatus.Prolonged) {
      return theme.errorColor;
    }

    return !task.user_id &&
      !(task as CoreCoordinationDraftTask).is_system_proposed
      ? theme.errorColor
      : undefined;
  }, [task]);

  const isCancelled = useMemo(() => {
    return (
      task.status === CoordinationStatus.Prolonged ||
      task.status === CoordinationStatus.Cancelled
    );
  }, [task]);

  if (isCancelled) {
    return <MdOutlineCancel color={theme.errorColor}></MdOutlineCancel>;
  }

  return (
    <Badge
      backgroundColor={badgeBackgroundColor}
      variation={badgeVariation}
      border={
        (task as CoreCoordinationDraftTask).is_system_proposed && !isCancelled
          ? "dashed"
          : undefined
      }
    >
      {isCancelled && <MdCancel color="white"></MdCancel>}

      {!isCancelled && (
        <>
          {task.type === CoordinationTaskType.Cleaning && (
            <MdOutlineCleaningServices
              color={
                (task as CoreCoordinationDraftTask).is_system_proposed
                  ? "black"
                  : "white"
              }
            ></MdOutlineCleaningServices>
          )}

          {task.type === CoordinationTaskType.Delivery && (
            <MdDeliveryDining
              color={
                (task as CoreCoordinationDraftTask).is_system_proposed
                  ? "black"
                  : "white"
              }
            ></MdDeliveryDining>
          )}

          {task.type === CoordinationTaskType.Maintenance && (
            <MdOutlineCleaningServices
              color={
                (task as CoreCoordinationDraftTask).is_system_proposed
                  ? "black"
                  : "white"
              }
            ></MdOutlineCleaningServices>
          )}

          {task.type === CoordinationTaskType.Inspection && (
            <MdOutlineRemoveRedEye
              color={
                (task as CoreCoordinationDraftTask).is_system_proposed
                  ? "black"
                  : "white"
              }
            ></MdOutlineRemoveRedEye>
          )}
        </>
      )}
    </Badge>
  );
};
