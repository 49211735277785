import { Alert, Badge, Divider, Flex, Text } from "@aws-amplify/ui-react";
import { groupBy } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { logger } from "../../logger";
import Headline from "../shared/components/Headline";
import {
  CoordinationStatus,
  CoordinationTaskType,
  CoreCoordinationDraftTask,
  CoreCoordinationTask,
  TaskProgressStatus,
} from "../shared/hooks/core-coordination-task";
import { useTasksApi } from "../shared/hooks/useTasksApi";
import { dateToYYMMDD } from "../shared/utils/dates.util";
import { accentsTidy } from "../shared/utils/generateMassPaymentsFile";
import AddTaskButton from "./AddTaskButton";
import AddTaskModal from "./AddTaskModal";
import CleaningSchedulePlaceholder from "./CleaningSchedulePlaceholder";
import CitySelector from "./components/CitySelector";
import DaySelector from "./components/DaySelector";
import TaskCard from "./components/TaskCard";
import ScheduleColumn from "./ScheduleColumn";
import TaskDetailsModal from "./TaskDetailsModal";

export default function CleaningSchedulePage() {
  // use "en" locale in admin pages
  moment.locale("en");

  const { data, loading, error, load } = useTasksApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentDate, setDate] = useState(
    searchParams.get("date") ? moment(searchParams.get("date")) : moment()
  );
  const [selectedCity, setSelectedCity] = useState<string | undefined>(
    searchParams.has("city") ? String(searchParams.get("city")) : undefined
  );
  const [selectedDraft, setSelectedDraft] = useState<
    CoreCoordinationDraftTask | undefined
  >();

  const [selectedTask, setSelectedTask] = useState<
    Pick<CoreCoordinationTask, "task_id"> | undefined
  >(
    searchParams.get("taskId")
      ? { task_id: searchParams.get("taskId")! }
      : undefined
  );

  const filterByCity = useCallback(
    (tasks: Pick<CoreCoordinationTask, "address">[]) => {
      return tasks.filter(
        (task) =>
          !selectedCity ||
          selectedCity === "All" ||
          accentsTidy(task.address!.toLocaleLowerCase()).includes(selectedCity)
      ) as (CoreCoordinationTask | CoreCoordinationDraftTask)[];
    },
    [selectedCity]
  );

  useEffect(() => {
    load({ date: dateToYYMMDD(currentDate) });

    if (!selectedCity) {
      searchParams.delete("city");
    }

    if (selectedCity) {
      searchParams.set("city", selectedCity);
    }

    if (currentDate) {
      searchParams.set("date", currentDate.format("YYYY-MM-DD"));
    }

    setSearchParams(searchParams);
  }, [currentDate, selectedCity, setSearchParams, load, searchParams]);

  const tasks = useMemo(() => {
    if (!data) {
      return;
    }

    const filteredTasks = filterByCity(data.tasks);

    const deliveryTasks = filteredTasks.filter(
      (task) => task.type === CoordinationTaskType.Delivery
    );

    const cleaningAndCustomTasks = filteredTasks.filter(
      (task) => !(task as CoreCoordinationDraftTask).is_prolonged
    );

    const timeGroups = groupBy(filteredTasks, "scheduled_time");

    return {
      cleaningAndCustomTasks,
      filteredTasks,
      notYetPlanned: data.notYetPlanned,
      timeGroups,
      deliveryTasks,
    };
  }, [data, filterByCity]);

  const handleTaskSelection = useCallback(
    (task: CoreCoordinationDraftTask | CoreCoordinationTask) => {
      if ((task as CoreCoordinationDraftTask)?.is_system_proposed) {
        logger.debug(`Using draft task`, task);

        setSelectedDraft(task as CoreCoordinationDraftTask);
      } else {
        setSearchParams({ ...searchParams, taskId: task.task_id });
        setSelectedTask(task);
      }
    },
    [setSelectedDraft, searchParams, setSelectedTask, setSearchParams]
  );

  return (
    <>
      <Flex direction={"column"} grow={1} overflow={"hidden"}>
        <Flex marginBottom={35} justifyContent={"space-between"}>
          <Headline>Cleaning Schedule</Headline>

          <AddTaskButton
            onCreated={(taskId) => {
              load({ date: dateToYYMMDD(currentDate) });
              setSelectedDraft(undefined);
              setSelectedTask({ task_id: taskId });
            }}
          ></AddTaskButton>
        </Flex>

        <DaySelector currentDate={currentDate} setDate={setDate} />

        {error && (
          <Flex>
            <Alert
              variation="error"
              isDismissible={false}
              hasIcon={true}
              heading="Some error happended!"
            >
              <pre>{JSON.stringify(error.message, undefined, 16)}</pre>
            </Alert>
          </Flex>
        )}

        {data && (
          <Flex overflow={"scroll"}>
            <CitySelector
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
            />
          </Flex>
        )}

        {loading && <CleaningSchedulePlaceholder />}

        {!loading && tasks && (
          <>
            {data && (
              <>
                <Flex direction={"column"}>
                  <Text marginTop={35} fontWeight={"bold"}>
                    Unscheduled tasks
                  </Text>

                  {tasks.notYetPlanned.map((task, index) => (
                    <TaskCard
                      onClick={() => handleTaskSelection(task)}
                      key={`unscheduled-${index}`}
                      task={task}
                    />
                  ))}
                </Flex>

                <Flex marginTop={35}>
                  <Text fontWeight={"bold"}>Schedule</Text>
                  <Badge>Tasks {tasks.cleaningAndCustomTasks.length}</Badge>
                  <Badge>Cancelled {data.cancelled.length}</Badge>
                </Flex>

                <Divider></Divider>
                <Flex
                  grow={1}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  overflow={"scroll"}
                >
                  <ScheduleColumn
                    onClick={(task) => {
                      if (
                        task.progress_status !== TaskProgressStatus.Cancelled
                      ) {
                        handleTaskSelection(task);
                      }
                    }}
                    tasks={[
                      ...(tasks.timeGroups["undefined"] || []),
                      ...(tasks.timeGroups["null"] || []),
                    ].sort((a, b) => {
                      return a.status === CoordinationStatus.Planned ? 0 : -1;
                    })}
                    label={"All day"}
                  ></ScheduleColumn>

                  {Object.keys(tasks.timeGroups)
                    .sort()
                    .filter(
                      (timeGroup) =>
                        timeGroup !== "undefined" && timeGroup !== "null"
                    )
                    .map((timeGroup) => (
                      <Flex
                        direction={"column"}
                        grow={1}
                        basis={0}
                        key={`group-${timeGroup}`}
                      >
                        <Text marginBottom={16}>
                          {timeGroup}{" "}
                          <Badge>{tasks.timeGroups[timeGroup].length}</Badge>
                        </Text>
                        <Flex direction={"column"} margin={8}>
                          {tasks.timeGroups[timeGroup].map((task, index) => (
                            <TaskCard
                              onClick={() => handleTaskSelection(task)}
                              key={task.task_id}
                              task={task}
                            />
                          ))}
                        </Flex>
                      </Flex>
                    ))}
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>

      {selectedTask && (
        <TaskDetailsModal
          taskId={selectedTask.task_id}
          onClose={() => {
            setSelectedTask(undefined);
          }}
        ></TaskDetailsModal>
      )}

      {selectedDraft && (
        <AddTaskModal
          onCreated={(taskId) => {
            load({ date: dateToYYMMDD(currentDate) });
            setSelectedDraft(undefined);
            setSelectedTask({ task_id: taskId });
          }}
          draft={selectedDraft}
          onClose={() => {
            setSelectedDraft(undefined);
          }}
        ></AddTaskModal>
      )}
    </>
  );
}
