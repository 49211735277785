import moment from "moment";
import Filters from "./Filters";

export default ({ loading }: { loading: boolean }) => {
  return (
    <Filters
      loading={loading}
      filters={[
        {
          label: "Month",
          key: "month",
          options: [
            { value: "", label: "Full year" },
            ...moment.months().map((monthLabel, index) => {
              return { label: monthLabel, value: `${index + 1}` };
            }),
          ],
        },
        {
          label: "Year",
          key: "year",
          options: [
            {
              label: moment().format("YYYY"),
              value: moment().format("YYYY"),
            },
            {
              label: moment().subtract("1", "year").format("YYYY"),
              value: moment().subtract("1", "year").format("YYYY"),
            },
            {
              label: moment().subtract("2", "year").format("YYYY"),
              value: moment().subtract("2", "year").format("YYYY"),
            },
            {
              label: moment().subtract("3", "year").format("YYYY"),
              value: moment().subtract("3", "year").format("YYYY"),
            },
          ],
        },
      ]}
    ></Filters>
  );
};
