// exchange rate should be in the wire title???
export default async (amount: number) => {
  const response = await fetch(
    "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_ssdQOU3G4A6iSLaCcmy8fhU2zBpZD5vUXohOIXKz&currencies=PLN&base_currency=EUR"
  );

  const result = (await response.json()) as { data: { PLN: number } };

  const exchangeRate = result.data.PLN; // something around 4

  return { amount: amount / exchangeRate, exchangeRate };
};
