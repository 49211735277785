import FileDropzone from "./FileDropzone";

export default ({
  userId,
  onChange,
}: {
  userId?: number;
  onChange: () => void;
}) => {
  return (
    <FileDropzone
      onChange={onChange}
      url="/v1/admin/docs/upload"
      fields={
        userId
          ? {
              userId: String(userId),
            }
          : undefined
      }
      accept="application/pdf"
    />
  );
};
