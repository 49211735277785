import { Flex, FlexProps, Text } from "@aws-amplify/ui-react";
import { PropsWithChildren } from "react";
import Headline from "./components/Headline";

import SearchFieldCard from "./components/SearchFieldCard";

export default ({
  children,
  title,
  subtitle,
  onSearch,
  ...props
}: PropsWithChildren<
  {
    title: string;
    subtitle?: string;
    onSearch?: (queryString: string) => void;
  } & FlexProps
>) => {
  return (
    <Flex {...props} direction={"column"} grow={1} overflow={"hidden"}>
      <Flex direction={"column"} gap={0}>
        <Headline>{title}</Headline>

        {subtitle && <Text>{subtitle}</Text>}
      </Flex>

      {onSearch && <SearchFieldCard onSearch={onSearch}></SearchFieldCard>}

      {children}
    </Flex>
  );
};
