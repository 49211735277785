import { IPayoutEntry } from "../../shared/hooks/useCoreData";
import { RTableColumn } from "../../shared/components/tables/TableColumn";

export const columns: RTableColumn<IPayoutEntry>[] = [
  { label: "PaymentID" },
  { label: "UserId", fieldKey: "user_id" },
  { label: "Property" },
  { label: "VAT ID", fieldKey: "vat_id" },
  { label: "Settlement Start" },
  { label: "Settlement End" },
  { label: "Type" },
  { label: "Amount", fieldKey: "amount", type: "Number" },
  { label: "Status", fieldKey: "status" },
  { label: "Planned" },
  { label: "Paid" },
  { label: "Settlement" },
  { label: "Receiver", fieldKey: "receiver_name" },
  { label: "Receiver IBAN" },
  { label: "Payout Title" },
  { label: "Actions" },
];
