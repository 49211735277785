import { Alert, Flex, Tabs, TabItem } from "@aws-amplify/ui-react";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Headline from "../shared/components/Headline";
import { UserUpdateDto, useUser } from "../shared/hooks/useUserData";
import UserDetailsForm from "./UserDetailsComponents/UserDetailsForm";
import UserDocumentsList from "./UserDetailsComponents/UserDocumentsList";
import UserBankAccountsForm from "./UserDetailsComponents/UserBankAccountsForm";

export default function AdminUserDetails() {
  const [searchParams, setSearchParams] = useSearchParams();

  const userId = useMemo(() => {
    const raw = searchParams.get("userId");
    return raw ? parseInt(raw, 10) : 0;
  }, [searchParams]);

  const tabIndex = useMemo(() => {
    const raw = searchParams.get("tab");
    return raw ? parseInt(raw, 10) : 0;
  }, [searchParams]);

  const {
    userDetails,
    isLoading,
    loadingError,
    updateUser,
    savingError,
    isSaving,
    savingSuccess,
    refetch,
  } = useUser(userId);

  const saveData = async (userDetails: UserUpdateDto) => {
    updateUser(userId, userDetails);
  };

  const changeTab = (tabId: string | number) => {
    setSearchParams((params) => {
      params.set("tab", String(tabId));
      return params;
    });
  };

  return (
    <Flex grow={1} direction={"column"}>
      <Flex>
        <Headline>
          {userDetails &&
            userDetails[0] &&
            `${userDetails[0].first_name} ${userDetails[0].last_name}`}
        </Headline>
      </Flex>

      {isLoading && (
        <Alert
          marginTop={5}
          variation="info"
          isDismissible={false}
          hasIcon={true}
          heading={"Loading..."}
        />
      )}

      {!isLoading && loadingError && (
        <Alert marginTop={5} variation="error">
          Some error happended! ${loadingError.message}
        </Alert>
      )}

      {!isLoading && !!userDetails && !!userDetails[0] && (
        <Tabs
          spacing="equal"
          marginBottom={35}
          onChange={changeTab}
          currentIndex={tabIndex}
        >
          <TabItem title="Reference Data">
            <UserDetailsForm
              data={userDetails[0]}
              saveData={saveData}
              isSaving={isSaving}
              savingSuccess={savingSuccess}
              savingError={savingError}
            />
          </TabItem>
          <TabItem title="Bank Accounts">
            <UserBankAccountsForm userId={userDetails[0].id} />
          </TabItem>
          <TabItem title="User Documents">
            <UserDocumentsList
              data={userDetails[0]}
              onChange={() => {
                refetch();
              }}
            />
          </TabItem>
        </Tabs>
      )}
    </Flex>
  );
}
