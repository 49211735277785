import { Card, Flex, TextField } from "@aws-amplify/ui-react";
import { useCallback } from "react";
import { debounce } from "lodash";

export default ({ onSearch }: { onSearch?: (queryString: string) => void }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((queryString: string) => {
      if (onSearch && (queryString.length > 2 || !queryString)) {
        onSearch(queryString);
      }
    }, 1000),
    [onSearch]
  );

  return (
    <Flex direction={"column"} paddingLeft={4} paddingRight={4}>
      <Card variation="elevated">
        <Flex direction="column" grow={1}>
          <Flex direction="row" gap={50} justifyContent={"space-between"}>
            <Flex direction={"row"} gap={8}>
              <Flex direction={"column"}>
                <TextField
                  label={""}
                  placeholder="Search..."
                  onChange={(event) => handleSearch(event.target.value)}
                ></TextField>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};
