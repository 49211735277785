import { logger } from "../../../logger";

export const downloadPdf = (data: Blob, fileName: string): void => {
  logger.debug("downloadPdf");

  // create file link in browser's memory
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${fileName}.pdf`); //or any other extension

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(href);
};
