import { RTableColumn } from "../shared/components/tables/TableColumn";
import { PropertyDetails } from "../shared/hooks/useProperties";

export const columns: RTableColumn<PropertyDetails<boolean>>[] = [
  { label: "Internal ID", fieldKey: "internal_id", textAlign: "left" },
  { label: "HostawayID", fieldKey: "hostaway_id", textAlign: "left" },
  { label: "Name", fieldKey: "name", textAlign: "left" },
  { label: "Address", fieldKey: "address", textAlign: "left" },
  { label: "Status", fieldKey: "disabled" }, // Assuming textAlign does not need to be part of the label
  { label: "Property Type", textAlign: "left" },
  { label: "Contracts", textAlign: "left" },
  { label: "BookingCom", fieldKey: "booking_url", textAlign: "left" },
  { label: "Airbnb", fieldKey: "airbnb_url", textAlign: "left" },
  { label: "Actions", textAlign: "left" },
];
