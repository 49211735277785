import { API } from "../utils/API";
import { CognitoGroup } from "./useUserData";

export const inviteNewServiceUser = async (body: {
  email: string;
  firstName: string;
  lastName: string;
}) =>
  await API.post(`/v1/admin/users/invite`, {
    body: { ...body, group: CognitoGroup.Service },
  });
