import {
  Alert,
  Divider,
  Flex,
  Heading,
  Placeholder,
  TextField,
} from "@aws-amplify/ui-react";
import { debounce } from "lodash";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { MdWarning } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import { logger } from "../../logger";
import { theme } from "../../theme";
import Box from "../shared/components/Box";
import ExternalLink from "../shared/components/ExternalLink";
import Headline from "../shared/components/Headline";
import {
  OccupancyReport,
  getOccupancyReport,
} from "../shared/hooks/useCoordinationApi";

export default () => {
  const [result, setResult] = useState<OccupancyReport | undefined>();
  const [error, setError] = useState<Error>();
  const [inputError, setInputError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams({ q: "10" });

  const searchQuery = useMemo(() => {
    return searchParams.get("q");
  }, [searchParams]);

  const loadData = useMemo(
    () =>
      debounce(async (searchQuery: number) => {
        setError(undefined);
        setInputError(false);

        if (!searchQuery) {
          return;
        }

        // reservation hostaway ID has 8 signs
        // extend for other types if needed
        if (Number(searchQuery) < 1 && Number(searchQuery) > 30) {
          setInputError(true);

          return;
        }

        setLoading(true);

        try {
          const report = await getOccupancyReport(Number(searchQuery));

          if (report) {
            setResult(report);
          }
        } catch (error) {
          logger.error(error);
          setError(error as Error);
        } finally {
          setLoading(false);
        }
      }, 1000),
    [setLoading, setError]
  );

  useEffect(() => {
    loadData(Number(searchQuery));
  }, [searchQuery, loadData]);

  return (
    <>
      <Box className="container">
        <Box>
          <Box mb={40}>
            <Headline>Occupancy Overview</Headline>

            <TextField
              label=""
              hasError={inputError}
              errorMessage="Wrong input format"
              type="number"
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setSearchParams({ ...searchParams, q: e.target.value });
              }}
              descriptiveText={"Number of unrented days "}
              value={searchQuery || ""}
            />
          </Box>

          {error && !loading && (
            <Alert
              marginTop={5}
              variation="error"
              isDismissible={false}
              hasIcon={true}
              heading={`An error occured: ${error}`}
            />
          )}

          {loading && <Placeholder height={50}></Placeholder>}

          {!error && result && !loading && (
            <Flex direction={"column"}>
              <Flex alignItems={"center"}>
                <Heading variation="error">
                  # of unrented properties for {searchQuery} days:{" "}
                  {result.summary.unrentedCount}
                </Heading>
              </Flex>

              <Divider></Divider>

              <Flex direction={"column"}>
                {result.listingsWarnings.map((warning, index, array) => (
                  <Flex direction={"column"} marginBottom={24} gap={4}>
                    <Flex>
                      <Heading variation="secondary">
                        {`(${index + 1}/${array.length}) ${warning.name}`}{" "}
                      </Heading>
                    </Flex>
                    <Flex>Airbnb Name: {warning.airbnbName}</Flex>

                    {warning.recommendations.map((line) => (
                      <Flex alignItems={"center"}>
                        <MdWarning
                          fontSize={16}
                          color={theme.accentColor}
                        ></MdWarning>{" "}
                        {line}
                      </Flex>
                    ))}

                    <Flex direction={"row"}>
                      <Flex>
                        <ExternalLink
                          label={"Airbnb Profile"}
                          href={warning.airbnbUrl}
                        ></ExternalLink>
                      </Flex>

                      <Flex>
                        <ExternalLink
                          label={"Price Labs"}
                          href={"https://pricelabs.co/pricing"}
                        ></ExternalLink>
                      </Flex>

                      <Flex>
                        <ExternalLink
                          label={"Edit Listing"}
                          href={warning.editListing}
                        ></ExternalLink>
                      </Flex>
                      <Flex>
                        <ExternalLink
                          label={"Edit Price for Today"}
                          href={warning.editPrice}
                        ></ExternalLink>
                      </Flex>
                      <Flex>
                        <ExternalLink
                          label={"Airbnb URL"}
                          href={warning.airbnbUrl}
                        ></ExternalLink>
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
};
