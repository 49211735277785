import { Link } from "@aws-amplify/ui-react";
import { MdCalendarViewWeek } from "react-icons/md";
import { Contract } from "../../shared/hooks/useAdminContracts";

export default ({
  contractType,
  contractId,
}: {
  contractType: Contract["contract_type"];
  contractId: number;
}) => {
  return (
    <Link
      textDecoration="underline dotted"
      isExternal
      href={`https://portal.rentujemy.pl/owner-cockpit/${contractType}/${contractId}`}
    >
      <MdCalendarViewWeek />
    </Link>
  );
};
