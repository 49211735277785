import { Button } from "@aws-amplify/ui-react";
import { MdCallMade } from "react-icons/md";

export default ({ url }: { url: string }) => {
  return (
    <Button
      size="small"
      variation="link"
      onClick={() => {
        window.open(url, "_window");
      }}
    >
      Help <MdCallMade></MdCallMade>
    </Button>
  );
};
