import GenericTable from "../shared/GenericTable";
import GenericTablePage from "../shared/GenericTablePage";
import { usePropertyInstructions } from "../shared/hooks/usePropertiesInstructions";

export default () => {
  const { loading, data } = usePropertyInstructions();

  return (
    <GenericTablePage
      title={"Properties Access"}
      subtitle="Access instructions should contain photos."
    >
      <GenericTable data={data} loading={loading}></GenericTable>
    </GenericTablePage>
  );
};
