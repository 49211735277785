import { IPayoutEntry, PayoutStatus, PayoutType } from "../hooks/useCoreData";
import moment from "moment";
import { humanizeId } from "./humanizeId";
import ElixirTransferCode from "./ElixirTransferCode";
import convertPLNtoEUR from "./convertPLNtoEUR";

export const accentsTidy = (s: string): string => {
  const polishLetters: { [key: string]: string } = {
    ą: "a",
    ć: "c",
    ę: "e",
    ł: "l",
    ń: "n",
    ó: "o",
    ö: "oe",
    ä: "ae",
    ü: "ue",
    Ö: "Oe",
    Ä: "Ae",
    Ü: "Ue",
    ś: "s",
    ź: "z",
    ż: "z",
    Ą: "A",
    Ć: "C",
    Ę: "E",
    Ł: "L",
    Ń: "N",
    Ó: "O",
    Ś: "S",
    Ź: "Z",
    Ż: "Z",
  };

  let tidyString = "";
  for (let i = 0; i < s.length; i++) {
    const char = s[i];
    tidyString += polishLetters[char] || char;
  }

  const r = tidyString
    // replace non alphabetic chars
    .replace(/[^a-zA-Z0-9\s]/g, "")
    // replace multiple spaces
    .replace(/\s+/g, " ")
    .trim();

  return r;
};

export const isNationalPayout = (payout: IPayoutEntry) => {
  return payout.receiver_iban.substring(0, 2) === "PL";
};

export const isPlannedPayout = (payout: IPayoutEntry) => {
  return payout.status === PayoutStatus.Planned;
};

export const isNotCompany = (payout: IPayoutEntry) => {
  return !payout.vat_id;
};

export const isInternationalPayout = (payout: IPayoutEntry) => {
  return !isNationalPayout(payout);
};

export const filterPlannedNonVatNationalPayouts = (payouts: IPayoutEntry[]) => {
  return payouts
    .filter(isNationalPayout)
    .filter(isPlannedPayout)
    .filter(isNotCompany);
};

export const showTransferTitle = (payout: IPayoutEntry) => {
  const typeLabel =
    payout.type === PayoutType.OWNER_PAYOUT ? "Czynsz" : "Odsetki";

  const formattedReference = humanizeId(String(payout.id));

  const ueberwiesungsZweckLines = [
    `${typeLabel} ${moment(payout.settlement_end).format(
      "YYMM"
    )} Property ${humanizeId(payout.object_id)}`,
    `Payout ${formattedReference}`,
    accentsTidy(payout.payout_title).substring(0, 35), // @TODO SEFA - REPLACE WITH OBJECT NAME
  ];

  const ueberwiesungsZweck = ueberwiesungsZweckLines
    .map((line) => (line || "").substring(0, 35))
    .join("|");

  return ueberwiesungsZweck;
};

async function generateEliksirFile(jsonData: IPayoutEntry[], filename: string) {
  for (let index = 0; index < jsonData.length; index++) {
    let payout = jsonData[index];

    const isInternational = isInternationalPayout(payout);

    if (isInternational) {
      // 1.2% for international transfers
      payout.amount = (await convertPLNtoEUR(payout.amount)).amount * 0.982;
    }
  }

  const eliksirData = convertToEliksirFormat(jsonData);

  const blob = new Blob([eliksirData], { type: "text/plain;charset=utf-8" });
  // Create a download link
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename;

  // Trigger the download
  downloadLink.click();

  // Clean up
  URL.revokeObjectURL(downloadLink.href);
}

function removeAllSpaces(str: string) {
  return str.replace(/ /g, "");
}

function getNextWorkingDay() {
  const now = moment();

  // return today +3 days for Friday, +2 days for Saturday and +1 day for all other days
  return now
    .add(now.isoWeekday() >= 5 ? 8 - now.isoWeekday() : 1, "days")
    .format("YYYYMMDD");
}

/** https://www.pkobp.pl/media_files/840d01b0-978b-4fa8-8d46-0758ca836ea4.pdf */
export function convertToEliksirFormat(jsonData: IPayoutEntry[]): string {
  /**
   *
   * example:
110,
20120108,
120,
10205561,
0,
84102055610000380200040857,
18102055610000310200035501,
ZIELENIEC |ZIELENIEC S.A.|UL.KOŚCIUSZKI 11 m. 44|00-950 WARSZAWA,
KOWALCZYK JAN||UL.CZERNIAKOWSKA 112|00-423 WARSZAWA,
0,
10205561,
OPERACJA TESTOWA|TEST 1,
,
,
51,
Ref:20110527/60
   */

  return jsonData
    .map((payout) => {
      payout.receiver_iban = removeAllSpaces(payout.receiver_iban);

      return payout;
    })
    .map((payout) => {
      const isInternational = payout.receiver_iban.substring(0, 2) !== "PL";

      const transferCode = isInternational
        ? ElixirTransferCode.INTERNATIONAL
        : ElixirTransferCode.NORMAL;

      // we are using NEST now instead of PKOBP
      // const PKOBP = "51102049000000890235108089";
      const NEST = "96187010452078108207760001";
      const formattedSenderAccountNumber = padString(NEST, 0);

      const formattedRecipientName = payout.receiver_name;
      const formattedRecipientAccountNumber = payout.receiver_iban.slice(2);
      const formattedAmount = toCents(payout.amount);

      const ueberwiesungsZweck = showTransferTitle(payout);

      return [
        transferCode, // = Polecenia przelewu (zwykłe, płatności podatku)
        getNextWorkingDay(), // Data płatności RRRRMMDD
        formattedAmount, // Kwota płatności w groszach. Bez separatorów tysięcznych i separatora części ułamkowej.
        "1020", // Bank zleceniodawcy (numer rozliczeniowy wg NBP) - PKOBP,
        isInternational ? "6" : "0",
        formattedSenderAccountNumber, // example: 84102055610000380200040857 - it is without the IBAN "PL", I think
        isInternational
          ? payout.receiver_iban
          : formattedRecipientAccountNumber, // example: 84102055610000380200040857 - it is without the IBAN "PL", I think
        "Rentujemy Sp. z o.o.|Samochodowa 2|02-652 Warszawa", // Nazwa i adres zleceniodawcy
        formattedRecipientName, // Name & Address of the receiver, lines are separated by "|",
        "0",
        formattedRecipientAccountNumber.substring(2, 4), // Bank Kontrahenta (numer rozliczeniowy wg NBP) - PKOBP - ID of the contactor bank.
        ueberwiesungsZweck, // wire topic
        "",
        "",
        "51", // normal transfer
        //
        // our ref
        `Ref:${payout.id}`,
        // `Ref:${moment(payout.settlement_end).format("YYYYMM")}${payout.id}|${isInternational ? "EUR" : "PLN" }`,
      ].join(",");
    })
    .join("\r\n");
}

function padString(value: string, length: number): string {
  return String(value).padEnd(length, " ");
}

function toCents(value: number): string {
  return String(Math.round(value * 100));
}

export const generateMassPaymentsFile = (payouts: IPayoutEntry[]) => {
  generateEliksirFile(payouts, "payouts.txt");
};
