import { Button } from "@aws-amplify/ui-react";
import { MdAddCircleOutline } from "react-icons/md";

export default ({ onClick, label }: { label: string; onClick: () => void }) => {
  return (
    <Button
      variation="primary"
      gap={12}
      onClick={() => {
        onClick();
      }}
    >
      <MdAddCircleOutline></MdAddCircleOutline>
      {label}
    </Button>
  );
};
