import { theme } from "../../../theme";
import {
  CoordinationTask,
  TaskProgressStatus,
} from "../../shared/hooks/core-coordination-task";

export const getColorForTask = ({ task }: { task: CoordinationTask }) => {
  if (task.progress_status === TaskProgressStatus.Completed) {
    return theme.success;
  }

  if (task.user_id && task.scheduled_date) {
    return theme.info;
  }

  return undefined;
};
