import { Flex, Text } from "@aws-amplify/ui-react";
import { CoordinationTask } from "../shared/hooks/core-coordination-task";

import TaskCard from "./components/TaskCard";

export default function ScheduleColumn({
  tasks,
  label,
  onClick,
}: {
  label: string;
  tasks: CoordinationTask[];
  onClick: (task: CoordinationTask) => void;
}) {
  return (
    <Flex direction={"column"} grow={1} basis={0}>
      <Text marginBottom={16}>{label}</Text>
      <Flex direction={"column"} margin={8}>
        {tasks.map((task) => (
          <TaskCard
            onClick={() => onClick(task)}
            key={task.task_id}
            task={task}
          />
        ))}
      </Flex>
    </Flex>
  );
}
