import { Flex, Heading } from "@aws-amplify/ui-react";
import ShowJson from "../shared/components/ShowJson";
import { SearchResult } from "../shared/hooks/useSearch";
import ExternalResourceLink from "../shared/components/ExternalResourceLink";

export default ({ results }: { results: SearchResult[] }) => {
  return (
    <Flex direction={"column"}>
      {results.map((item) => {
        if (item.type === "user") {
          return (
            <Flex direction={"column"}>
              <Heading variation="primary">
                User Data{" "}
                <ExternalResourceLink
                  href={`/users/details?userId=${item.data.id}`}
                />
              </Heading>
              <ShowJson obj={item.data}></ShowJson>
            </Flex>
          );
        }

        if (item.type === "contracts") {
          return (
            <Flex direction={"column"}>
              <Heading variation="primary">Contract Data</Heading>

              {item.data.map((contract) => (
                <Flex direction={"column"}>
                  <Heading variation="primary">{contract.name}</Heading>
                  <ShowJson obj={contract}></ShowJson>
                </Flex>
              ))}
            </Flex>
          );
        }

        return <></>;
      })}
    </Flex>
  );
};
