import { useCallback, useEffect } from "react";
import { CognitoGroup, useLazyUsers } from "../shared/hooks/useUserData";
import Autocomplete from "./Autocomplete";

export default ({
  onSave,
  label,
  defaultValue,
  isReadOnly,
}: {
  isReadOnly?: boolean;
  onSave: (userId: number) => Promise<void>;
  defaultValue?: string;
  label?: string;
}) => {
  const { data, loading, load } = useLazyUsers();

  const handleSearch = useCallback(
    (query: string | undefined) => {
      load({ query: query || "", group: CognitoGroup.Service });
    },
    [load]
  );

  useEffect(() => {
    load({ group: CognitoGroup.Service });
  }, [load]);

  return (
    <Autocomplete
      isReadOnly={isReadOnly}
      defaultLabel={defaultValue}
      onSelected={async (option) => {
        await onSave(Number(option.id));
      }}
      isLoading={loading}
      onOptionsRequested={handleSearch}
      options={
        data?.map((item) => {
          return {
            id: String(item.id),
            label: `${item.first_name} ${item.last_name} (${item.email})`,
          };
        }) || []
      }
      label={label || "User"}
    ></Autocomplete>
  );
};
