import { Button } from "@aws-amplify/ui-react";
import csvDownload from "json-to-csv-export";
import { MdDownload } from "react-icons/md";

interface DownloadData {
  items: Record<string, any>[];
}

export default ({
  filename,
  data,
}: {
  filename: string;
  data?: DownloadData;
}) => {
  return (
    <Button
      disabled={!data}
      onClick={() => {
        if (data) {
          csvDownload({
            data: data.items,
            filename: filename,
            delimiter: ",",
            headers: Object.keys(data.items[0]),
          });
        }
      }}
    >
      <MdDownload></MdDownload>
    </Button>
  );
};
