import { IStatementProposal } from "../../shared/hooks/useOwnerStatement";

export type ResolvedProposalState =
  | "Planned"
  | "Paid"
  | "NeedsApproval"
  | "ReadyForPublish"
  | "Invalid";
export type ProposalStateResolver = (
  proposal: IStatementProposal
) => ResolvedProposalState;

export const getProposalState: ProposalStateResolver = (proposal) => {
  return proposal.published_status === "Planned"
    ? "Planned" // published status has higher priority
    : proposal.published_status === "Paid"
    ? "Paid" // published status has higher priority
    : proposal.status === "new" || proposal.status === "modified"
    ? "NeedsApproval"
    : proposal.status === "approved"
    ? "ReadyForPublish"
    : "Invalid"; // when proposal and payout are out of sync (e.g. payout was deleted)
};
