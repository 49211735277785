import { Button, TableBody, TableCell, TableRow } from "@aws-amplify/ui-react";
import { Modal, Table } from "react-bootstrap";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import DatePicker from "react-date-picker";
import { useState } from "react";
import { IPayoutEntry } from "../../shared/hooks/useCoreData";

interface AdminPayoutProps {
  setPaidDate: (proposal: IPayoutEntry, paidDate: Date) => void;
  handleClose: () => void;
  proposal: IPayoutEntry;
}

export default ({ setPaidDate, handleClose, proposal }: AdminPayoutProps) => {
  const [paidDateValue, setPaidDateValue] = useState<Date | null>(new Date());

  const { title, primaryButton } = ((): {
    title: string;
    primaryButton: null | { label: string; handler: () => void };
  } => {
    return {
      title: "Set Payment Date",
      primaryButton: {
        label: "Save",
        handler: () => {
          if (!paidDateValue) {
            alert("Select Date in Date picker and then confirm value.");
            return;
          }

          setPaidDate(proposal, paidDateValue);
          handleClose();
        },
      },
    };
  })();

  const createTable = () => (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Paid Date</TableCell>
          <TableCell textAlign="right">
            <DatePicker
              value={paidDateValue}
              returnValue="start"
              onChange={(date) =>
                setPaidDateValue(Array.isArray(date) ? date[0] : date)
              }
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{createTable()}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>

        {primaryButton && (
          <Button variation="primary" onClick={primaryButton.handler}>
            {primaryButton.label}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
