import { RTableColumn } from "../shared/components/tables/TableColumn";
import { UserDetails } from "../shared/hooks/useUserData";

export const columns: RTableColumn<UserDetails>[] = [
  { fieldKey: "id" },
  { fieldKey: "email" },
  { fieldKey: "last_name" },
  { fieldKey: "first_name" },
  { fieldKey: "company_name" },
  { fieldKey: "properties" },
];
