import {
  Alert,
  Button,
  Flex,
  Heading,
  Link,
  Placeholder,
  TextField,
} from "@aws-amplify/ui-react";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdChevronLeft } from "react-icons/md";
import { useParams } from "react-router-dom";
import { logger } from "../../logger";
import Headline2 from "../shared/components/Headline2";
import {
  getReservation,
  updateReservation,
} from "../shared/hooks/useCoordinationApi";
import { CoreReservation } from "../shared/types/core-reservation";

export default () => {
  const [propertyDetails, setPropertyDetails] = useState<CoreReservation>();
  const [error, setError] = useState<Error>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [changing, setChanging] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { t } = useTranslation();
  const params = useParams();

  const haReservationId = useMemo(() => {
    return params.id;
  }, [params]);

  const loadData = useCallback(async () => {
    if (!haReservationId) {
      setError(new Error("Something went wrong"));
      logger.error("Something went wrong");
      return;
    }

    try {
      const property = await getReservation(haReservationId);

      if (property) {
        setPropertyDetails(property);
      }
    } catch (error) {
      logger.error(error);
      setError(error as Error);
    }
  }, [haReservationId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleChange =
    <T extends "text" | "switch" | "number">(
      key: keyof CoreReservation,
      type: T
    ) =>
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (propertyDetails) {
        setChanging(true);
        setSuccess(false);
        setError(undefined);

        if (type === "switch") {
          setPropertyDetails({ ...propertyDetails, [key]: e.target.checked });
        } else if (type === "number") {
          const updatedFields = {
            ...propertyDetails,
            [key]: Number(e.target.value) || 0,
          };

          if (
            key === "rental_income" ||
            key === "pm_commission" ||
            key === "total_tax" ||
            key === "reservation_portal_fee"
          ) {
            updatedFields.owner_payout =
              Math.round(
                100 *
                  (updatedFields.rental_income -
                    updatedFields.pm_commission -
                    updatedFields.reservation_portal_fee -
                    updatedFields.total_tax)
              ) / 100 || 0;
          }

          if (
            key === "city_tax" ||
            key === "base_rate" ||
            key === "vat" ||
            key === "cleaning_fee"
          ) {
            updatedFields.total_price =
              Math.round(
                100 *
                  ((Number(updatedFields.base_rate) || 0) +
                    (Number(updatedFields.vat) || 0) +
                    (Number(updatedFields.cleaning_fee) || 0) +
                    (Number(updatedFields.city_tax) || 0))
              ) / 100 || 0;
          }

          setPropertyDetails(updatedFields);
        } else {
          setPropertyDetails({ ...propertyDetails, [key]: e.target.value });
        }
      }
    };

  const handleSubmit = async () => {
    setSubmitting(true);
    if (propertyDetails) {
      try {
        await updateReservation(propertyDetails.internal_id, propertyDetails);

        setSubmitting(false);
        setChanging(false);
        setSuccess(true);

        loadData();
      } catch (err) {
        setError(err as Error);
        setSubmitting(false);
      }
    }
  };

  return (
    <Flex direction={"column"} grow={1}>
      <Flex marginBottom={40} alignItems={"flex-start"} direction={"column"}>
        <Flex alignItems={"center"}>
          <Link href={`/?q=${haReservationId}`}>
            <MdChevronLeft size={25}></MdChevronLeft>
          </Link>
          <Heading level={3}>Edit Accounting Fields</Heading>
        </Flex>
        <Flex>
          <Alert variation="info">
            Changing these fields manually will disable auto sync with PMS
            Manager.
          </Alert>
        </Flex>
      </Flex>

      {error && (
        <Alert
          marginTop={5}
          variation="error"
          isDismissible={true}
          onDismiss={() => setError(undefined)}
          hasIcon={true}
          heading="Some error happended!"
        />
      )}

      {success && (
        <Alert
          marginTop={5}
          variation="success"
          isDismissible={true}
          onDismiss={() => setSuccess(false)}
          hasIcon={true}
          heading="Data saved successfully!"
        />
      )}

      {!propertyDetails && <Placeholder height={90}></Placeholder>}

      {propertyDetails && (
        <Flex direction={"column"}>
          <Flex
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Button
              width={200}
              variation="primary"
              loadingText={t("Confirm")}
              onClick={handleSubmit}
              isLoading={submitting}
              disabled={!changing || submitting}
              alignSelf="start"
            >
              Save
            </Button>
          </Flex>

          <Flex direction={"row"} gap={32}>
            <Flex
              basis={"30%"}
              direction={"column"}
              alignContent={"flex-start"}
              alignSelf={"flex-start"}
            >
              <Headline2>Accounting data</Headline2>

              <TextField
                readOnly
                disabled
                label="Internal ID"
                value={propertyDetails.internal_id}
              />

              <TextField
                readOnly
                disabled
                label="Hostaway ID"
                value={propertyDetails.hostaway_id}
              />

              <TextField
                label="Cleaning Fee"
                type="number"
                value={propertyDetails.cleaning_fee}
                onChange={handleChange("cleaning_fee", "number")}
              />

              <TextField
                label="Base Rate"
                type="number"
                value={propertyDetails.base_rate}
                onChange={handleChange("base_rate", "number")}
              />

              <TextField
                label="VAT"
                type="number"
                descriptiveText={`VAT is now ${
                  Math.round(
                    (1000 * 100 * propertyDetails.vat) /
                      propertyDetails.base_rate
                  ) / 1000
                }% of base rate`}
                value={propertyDetails.vat}
                onChange={handleChange("vat", "number")}
              />

              <TextField
                label="City Tax"
                type="number"
                value={propertyDetails.city_tax}
                onChange={handleChange("city_tax", "number")}
              />

              <TextField
                label="Total Price"
                type="number"
                disabled
                readOnly
                value={propertyDetails.total_price}
              />
            </Flex>

            <Flex direction={"row"} gap={32}>
              <Flex
                direction={"column"}
                alignContent={"flex-start"}
                alignSelf={"flex-start"}
              >
                <Headline2>Payout data</Headline2>

                <TextField
                  label="Payout Rental Income"
                  type="text"
                  value={propertyDetails.rental_income}
                  onChange={handleChange("rental_income", "number")}
                />
                <TextField
                  label="Payout Total Tax"
                  type="number"
                  value={propertyDetails.total_tax}
                  onChange={handleChange("total_tax", "number")}
                />

                <TextField
                  label="Payout Reservation Portal Fee"
                  type="number"
                  value={propertyDetails.reservation_portal_fee}
                  onChange={handleChange("reservation_portal_fee", "number")}
                />

                <TextField
                  label="Payout PM Commission"
                  descriptiveText={`PM Commission is now ${
                    Math.round(
                      (1000 * 100 * propertyDetails.pm_commission) /
                        propertyDetails.rental_income
                    ) / 1000
                  }% of rental income`}
                  type="number"
                  value={propertyDetails.pm_commission}
                  onChange={handleChange("pm_commission", "number")}
                />

                <TextField
                  onChange={handleChange("owner_payout", "number")}
                  label="Payout Owner"
                  type="text"
                  value={propertyDetails.owner_payout}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
