import { RTableColumn } from "../../shared/components/tables/TableColumn";
import { CoreReservation } from "../../shared/types/core-reservation";

export const columns: RTableColumn<CoreReservation>[] = [
  {
    fieldKey: "hostaway_id",
    label: "Hostaway Reservation ID",
  },
  {
    fieldKey: "departure_date",
    label: "Departure date",
  },
  {
    label: "Gross Revenue",
    fieldKey: "total_price",
  },
  {
    label: "Base Rate",
    fieldKey: "base_rate",
  },
  {
    fieldKey: "vat",
    label: "VAT",
  },
  {
    fieldKey: "city_tax",
    label: "City Tax",
  },
  {
    fieldKey: "cleaning_fee",
    label: "Cleaning Fee",
  },
];
