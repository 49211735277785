import { Alert, Flex, Placeholder, TextField } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { logger } from "../../logger";
import {
  HAListing,
  getHostawayListing,
} from "../shared/hooks/useAdminPmsHostawayApi";

export default ({ hostawayListingId }: { hostawayListingId: string }) => {
  const [haListing, setHaListing] = useState<HAListing>();
  const [loading, setLoading] = useState<boolean>(false);
  const [, setError] = useState<Error>();

  useEffect(() => {
    setLoading(true);
    setError(undefined);

    (async () => {
      try {
        const property = await getHostawayListing(hostawayListingId);

        if (property) {
          setHaListing(property);
        }
      } catch (error) {
        logger.error(error);
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    })();
  }, [hostawayListingId]);

  return (
    <Flex direction={"column"} grow={1}>
      {loading && (
        <Flex direction={"column"}>
          <Placeholder height={65}></Placeholder>
          <Placeholder height={65}></Placeholder>
        </Flex>
      )}

      {haListing && !haListing?.cleaningFee && (
        <Alert variation="warning">No cleaning fee</Alert>
      )}
      {haListing && (
        <Alert variation="success">Synchronization with PMS is active.</Alert>
      )}
      {haListing &&
        Object.keys(haListing).map((key) => (
          <TextField
            key={key}
            readOnly
            disabled
            label={key}
            value={
              (haListing as unknown as Record<string, string | number>)[key]
            }
          />
        ))}
    </Flex>
  );
};
