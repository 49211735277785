import { apiEndpoint } from "./config";

const awsmobile = {
  "aws_project_region": "eu-central-1",
  "aws_cloud_logic_custom": [
      {
          "name": "herokuapi",
          "endpoint": apiEndpoint,
          "region": "eu-central-1"
      },
      {
          "name": "coredata",
          "endpoint": "https://1tx9pbh7gh.execute-api.eu-central-1.amazonaws.com/prod",
          "region": "eu-central-1"
      }
  ],
  "aws_appsync_region": "eu-central-1",
  "aws_appsync_authenticationType": "AWS_IAM",
  "aws_appsync_apiKey": "da2-6vn6grr4ovge7oqlkcwktyuugy",
  "aws_cognito_identity_pool_id": "eu-central-1:48604891-6644-4262-9c71-3503e08a0f8d",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_1yJkNJjyI",
  "aws_user_pools_web_client_id": "773dvoqdgfmd18bcja5bj6qpkp",
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "aws_user_files_s3_bucket": "s3publicstorage183528-prod",
  "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
