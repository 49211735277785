import { Flex } from "@aws-amplify/ui-react";
import { MdDownload } from "react-icons/md";
import Loader from "../shared/components/Loader";
import { useDocsDownload } from "../shared/hooks/useDocs";

export default function PdfColumn({ fileKey }: { fileKey: string }) {
  const { download, loading } = useDocsDownload();

  return (
    <Flex
      alignItems={"center"}
      direction={"row"}
      onClick={() => {
        download({ fileKey: fileKey });
      }}
    >
      {loading ? <Loader></Loader> : <MdDownload></MdDownload>}
      {fileKey}
    </Flex>
  );
}
