import { Flex, Heading } from "@aws-amplify/ui-react";
import ImageTimeline from "../CleaningSchedule/ImageTimeline";
import LoaderBox from "../shared/components/LoaderBox";
import { usePropertyImages } from "../shared/hooks/usePropertyImages";

export default ({ propertyId }: { propertyId: number }) => {
  const { data, loading } = usePropertyImages(propertyId);

  return (
    <Flex direction={"column"}>
      <Heading>Photos</Heading>

      {loading && <LoaderBox></LoaderBox>}
      {data && !loading && <ImageTimeline images={data}></ImageTimeline>}
    </Flex>
  );
};
