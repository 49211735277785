import { Flex, Placeholder } from "@aws-amplify/ui-react";

export default function CleaningSchedulePlaceholder() {
  const height = 100;

  return (
    <>
      <Flex direction={"column"} gap={12}>
        <Placeholder height={height}></Placeholder>
      </Flex>
      <Flex direction={"column"} gap={12}>
        <Placeholder height={height}></Placeholder>
      </Flex>
      <Flex direction={"row"} gap={12}>
        <Placeholder height={height}></Placeholder>
        <Placeholder height={height}></Placeholder>
        <Placeholder height={height}></Placeholder>
        <Placeholder height={height}></Placeholder>
      </Flex>
      <Flex direction={"row"} gap={12}>
        <Placeholder height={height}></Placeholder>
        <Placeholder height={height}></Placeholder>
        <Placeholder height={height}></Placeholder>
        <Placeholder height={height}></Placeholder>
      </Flex>
    </>
  );
}
