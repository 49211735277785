import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { columns } from "./columns";
import {
  ReportingCoreReservation,
  ReportingReservations,
} from "../useReportingApi";
import ExternalLink from "../../shared/components/ExternalLink";
import { MdOutlineWarning } from "react-icons/md";
import { theme } from "../../../theme";
import moneyFormatter from "../../shared/utils/moneyFormatter";

export default ({
  sum,
  items,
}: Pick<ReportingReservations, "items" | "sum">) => {
  const createTableRow = (
    item: Partial<ReportingCoreReservation>,
    index: number
  ) => (
    <TableRow key={index}>
      <TableCell textAlign="left">
        <ExternalLink
          label={item.hostaway_id}
          href={`/?q=${item.hostaway_id}`}
        ></ExternalLink>
      </TableCell>

      <TableCell textAlign="left">{item.departure_date}</TableCell>

      <TableCell textAlign="left">
        {item.total_price}{" "}
        {!item.totalPriceOk && (
          <MdOutlineWarning
            size={22}
            color={theme.errorColor}
          ></MdOutlineWarning>
        )}
      </TableCell>

      <TableCell textAlign="left">{item.base_rate}</TableCell>

      <TableCell textAlign="left">
        {item.vat}{" "}
        {!item.vatOk && (
          <MdOutlineWarning
            size={22}
            color={theme.errorColor}
          ></MdOutlineWarning>
        )}{" "}
      </TableCell>

      <TableCell textAlign="left">{item.city_tax}</TableCell>

      <TableCell textAlign="left">{item.cleaning_fee}</TableCell>
    </TableRow>
  );

  return (
    <Table size="small" caption="" highlightOnHover={false}>
      <TableHead>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell key={`column-${index}`} as="th" textAlign="left">
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow key={"sums"} boxShadow={"large"}>
          <TableCell textAlign="left"></TableCell>

          <TableCell textAlign="left"></TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.total_price)}
          </TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.base_rate)}
          </TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.vat)}
          </TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.city_tax)}
          </TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.cleaning_fee)}
          </TableCell>
        </TableRow>

        {items.map(createTableRow)}
      </TableBody>
    </Table>
  );
};
