import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { theme } from "../../../theme";
import StatusIndicator from "../../Properties/StatusIndicator";
import ExternalLink from "../../shared/components/ExternalLink";
import moneyFormatter from "../../shared/utils/moneyFormatter";
import {
  ReportingCoreReservation,
  ReportingReservations,
} from "../useReportingApi";
import { columns } from "./columns";

export default ({
  sum,
  items,
}: Pick<ReportingReservations, "items" | "sum">) => {
  const createTableRow = (
    item: Partial<ReportingCoreReservation>,
    rowIndex: number
  ) => (
    <TableRow
      key={rowIndex}
      backgroundColor={!item.ok ? theme.accentColor : undefined}
    >
      <TableCell key={`head-column-${rowIndex}-ok`} textAlign="left">
        <StatusIndicator ok={!!item.ok}></StatusIndicator>
      </TableCell>

      {columns.map((column, index) => (
        <TableCell key={`head-column-${index}`} textAlign="left">
          {column.type === "Id" && (
            <ExternalLink
              label={item[column.fieldKey!] as string}
              href={`/?q=${item[column.fieldKey!]}`}
            ></ExternalLink>
          )}
          {column.type !== "Id" && item[column.fieldKey!]}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <Table size="small" caption="" highlightOnHover={false}>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          {columns.map((column, index) => (
            <TableCell key={`column-${index}`} as="th" textAlign="left">
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        <TableRow key={"sums"} boxShadow={"large"}>
          <TableCell textAlign="left"></TableCell>

          <TableCell textAlign="left"></TableCell>

          <TableCell textAlign="left"></TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.rental_income)}
          </TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.total_tax)}
          </TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.reservation_portal_fee)}
          </TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.pm_commission)}
          </TableCell>

          <TableCell fontWeight={600} textAlign="left">
            {moneyFormatter(sum.owner_payout)}
          </TableCell>
        </TableRow>

        {items.map(createTableRow)}
      </TableBody>
    </Table>
  );
};
