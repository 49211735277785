import { Alert, Badge, Divider, Flex, Text } from "@aws-amplify/ui-react";
import { groupBy } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "../shared/components/Box";
import Headline from "../shared/components/Headline";
import { useUserProfile } from "../shared/components/UserProfileProvider";
import {
  CoordinationStatus,
  CoordinationTaskType,
  CoreCoordinationDraftTask,
  CoreCoordinationTask,
} from "../shared/hooks/core-coordination-task";
import { useTasksApi } from "../shared/hooks/useTasksApi";
import { dateToYYMMDD } from "../shared/utils/dates.util";
import CleaningSchedulePlaceholder from "./CleaningSchedulePlaceholder";
import DaySelector from "./components/DaySelector";
import TaskCard from "./components/TaskCard";
import ScheduleColumn from "./ScheduleColumn";
import TaskDetailsModal from "./TaskDetailsModal";

export default function ServiceRepSchedulePage() {
  // use "en" locale in admin pages
  moment.locale("en");

  const { data, loading, error, load } = useTasksApi();
  const user = useUserProfile();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentDate, setDate] = useState(
    searchParams.get("date") ? moment(searchParams.get("date")) : moment()
  );
  const [selectedTask, setSelectedTask] = useState<
    Pick<CoreCoordinationTask, "task_id"> | undefined
  >(
    searchParams.get("taskId")
      ? { task_id: searchParams.get("taskId")! }
      : undefined
  );

  useEffect(() => {
    if (user.userProfile) {
      load({ date: dateToYYMMDD(currentDate), userId: user.userProfile.id });
    }

    if (currentDate) {
      searchParams.set("date", currentDate.format("YYYY-MM-DD"));
    }

    setSearchParams(searchParams);
  }, [currentDate, setSearchParams, load, searchParams, user.userProfile]);

  const tasks = useMemo(() => {
    if (!data) {
      return;
    }

    const deliveryTasks = data.tasks.filter(
      (task) => task.type === CoordinationTaskType.Delivery
    );

    const cleaningAndCustomTasks = data.tasks.filter(
      (task) => !(task as CoreCoordinationDraftTask).is_prolonged
    );

    const timeGroups = groupBy(data.tasks, "scheduled_time");

    return {
      cleaningAndCustomTasks,
      filteredTasks: data.tasks,
      notYetPlanned: data.notYetPlanned,
      timeGroups,
      deliveryTasks,
    };
  }, [data]);

  const handleTaskSelection = useCallback(
    (task: CoreCoordinationDraftTask | CoreCoordinationTask) => {
      setSelectedTask({ task_id: task.task_id });
      setSearchParams({ ...searchParams, taskId: task.task_id });
    },
    [setSearchParams, setSelectedTask, searchParams]
  );

  return (
    <>
      <Flex direction={"column"} grow={1} overflow={"hidden"}>
        <Flex marginBottom={35} justifyContent={"space-between"}>
          <Headline>My Tasks</Headline>
        </Flex>

        {error && (
          <Box>
            <Alert
              variation="error"
              isDismissible={true}
              hasIcon={true}
              heading="Some error happended"
            >
              <pre>{JSON.stringify(error, undefined, 16)}</pre>
            </Alert>
          </Box>
        )}

        <DaySelector currentDate={currentDate} setDate={setDate} />

        {loading && <CleaningSchedulePlaceholder />}

        {!loading && !error && tasks?.cleaningAndCustomTasks.length === 0 && (
          <Flex>No tasks assigned to you</Flex>
        )}

        {!loading && tasks && (
          <>
            {data && (
              <>
                <Flex marginTop={35}>
                  <Text fontWeight={"bold"}>Schedule</Text>
                  <Badge>Tasks {tasks.cleaningAndCustomTasks.length}</Badge>
                  <Badge>Cancelled {data.cancelled.length}</Badge>
                </Flex>

                <Divider></Divider>
                <Flex
                  grow={1}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  overflow={"scroll"}
                >
                  <ScheduleColumn
                    onClick={() => void 0}
                    tasks={[
                      ...(tasks.timeGroups["undefined"] || []),
                      ...(tasks.timeGroups["null"] || []),
                    ].sort((a, b) => {
                      return a.status === CoordinationStatus.Planned ? 0 : -1;
                    })}
                    label={"All day"}
                  ></ScheduleColumn>

                  {Object.keys(tasks.timeGroups)
                    .sort()
                    .filter(
                      (timeGroup) =>
                        timeGroup !== "undefined" && timeGroup !== "null"
                    )
                    .map((timeGroup) => (
                      <Flex direction={"column"} grow={1} basis={0}>
                        <Text marginBottom={16}>
                          {timeGroup}{" "}
                          <Badge>{tasks.timeGroups[timeGroup].length}</Badge>
                        </Text>
                        <Flex direction={"column"} margin={8}>
                          {tasks.timeGroups[timeGroup].map((task, index) => (
                            <TaskCard
                              onClick={() => handleTaskSelection(task)}
                              key={task.task_id}
                              task={task}
                            />
                          ))}
                        </Flex>
                      </Flex>
                    ))}
                </Flex>
              </>
            )}
          </>
        )}

        {selectedTask && (
          <TaskDetailsModal
            taskId={selectedTask.task_id}
            onClose={() => {
              setSelectedTask(undefined);
            }}
          ></TaskDetailsModal>
        )}
      </Flex>
    </>
  );
}
