import {
  Autocomplete,
  Button,
  Card,
  Flex,
  Radio,
  RadioGroupField,
} from "@aws-amplify/ui-react";
import React, { useEffect, useMemo, useState } from "react";
import { MdDownload } from "react-icons/md";
import { logger } from "../../../logger";
import Headline from "../../shared/components/Headline";
import HelpButton from "../../shared/components/HelpButton";
import Stats from "../../shared/components/Stats";
import {
  IPayoutEntry,
  PayoutStatus,
  useCoreData,
} from "../../shared/hooks/useCoreData";
import {
  filterPlannedNonVatNationalPayouts,
  generateMassPaymentsFile,
  isInternationalPayout,
  isNationalPayout,
} from "../../shared/utils/generateMassPaymentsFile";
import AdminPayoutFilter from "./AdminPayoutFilter";
import PayoutsTable from "./PayoutsTable";
import useAdminPayoutFilter from "./useAdminPayoutFilter";

const wait = (ms: number) =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(undefined);
    }, ms)
  );

export default () => {
  const { listPayouts, setPayoutPaidDate } = useCoreData();
  const [settingAsPaid, setSettingAsPaid] = useState(false);
  const [payouts, setPayouts] = useState<IPayoutEntry[]>();
  const {
    startMonth,
    setStartMonth,
    endMonth,
    setEndMonth,
    startYear,
    setStartYear,
    endYear,
    setEndYear,
    payoutStatus,
    setPayoutStatus,
    investObjectId,
    setInvestObjectId,
    investObjects,
    handleInvestObjectsAutocompleteClick,
    investContractId,
  } = useAdminPayoutFilter();

  const payoutFilter = useAdminPayoutFilter();

  useEffect(() => {
    const load = async () => {
      try {
        setPayouts(
          await listPayouts(
            payoutStatus,
            new Date(startYear, startMonth, 1),
            new Date(endYear, endMonth + 1, 0),
            true
          )
        );
      } catch (error) {
        logger.error(error);
      }
    };

    load();
  }, [
    listPayouts,
    setPayouts,
    payoutStatus,
    investContractId,
    investObjectId,
    startMonth,
    startYear,
    endMonth,
    endYear,
  ]);

  const uniqueReceivers = useMemo(() => {
    const key = "receiver_name";

    return (payouts || [])
      .map((item) => item[key])
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((value) => {
        return {
          id: value,
          label: value,
        };
      });
  }, [payouts]);

  const payoutsForDisplay = useMemo(() => {
    return (payouts || []).filter((payout) => {
      if (!payoutFilter.accountName) {
        return true;
      }

      if (payoutFilter.accountName === payout.receiver_name) {
        return true;
      }

      return false;
    });
  }, [payouts, payoutFilter]);

  const stats = useMemo(() => {
    if (!payouts?.length) {
      return [];
    }

    return [
      {
        value: payouts.length,
        name: "Payouts",
      },
      {
        value:
          payouts
            .filter((payout) => payout.amount > 0)
            .reduce((acc, payout) => {
              return acc + Math.round(payout.amount);
            }, 0) + " PLN",
        name: "Total",
      },
      {
        value:
          payouts
            .filter((payout) => payout.status !== "Paid" && payout.amount > 0)
            .reduce((acc, payout) => {
              return acc + Math.round(payout.amount);
            }, 0) + " PLN",
        name: "To be paid",
      },
      {
        value:
          payouts
            .filter((payout) => payout.status === "Paid" && payout.amount > 0)
            .reduce((acc, payout) => {
              return acc + Math.round(payout.amount);
            }, 0) + " PLN",
        name: "Paid",
      },
      {
        value: payouts.filter((payout) => !!payout.vat_id).length,
        name: "VAT Payouts",
      },
    ];
  }, [payouts]);

  return (
    <Flex direction={"column"}>
      <Flex marginBottom={40}>
        <Headline>Payouts</Headline>
      </Flex>

      <Flex>
        <Card variation="elevated">
          <Flex direction="column">
            <Flex direction="column">
              <Flex shrink={1}>
                <AdminPayoutFilter
                  hasInvestObjectFilter={false}
                  startMonth={startMonth}
                  setStartMonth={setStartMonth}
                  startYear={startYear}
                  setStartYear={setStartYear}
                  endMonth={endMonth}
                  setEndMonth={setEndMonth}
                  endYear={endYear}
                  setEndYear={setEndYear}
                  setPayoutStatus={setPayoutStatus}
                  investObjectId={investObjectId}
                  setInvestObjectId={setInvestObjectId}
                  investObjects={investObjects}
                  handleInvestObjectsAutocompleteClick={
                    handleInvestObjectsAutocompleteClick
                  }
                />
              </Flex>
            </Flex>

            <Flex direction={"row"}>
              <Flex basis={"50%"} alignItems={"center"}>
                <Autocomplete
                  label=""
                  size="small"
                  labelHidden={false}
                  placeholder="Account Name"
                  isLoading={!payouts}
                  options={uniqueReceivers}
                  onSelect={(option) => {
                    payoutFilter.setAccountName(option.id);
                  }}
                  onClear={() => {
                    payoutFilter.setAccountName(undefined);
                  }}
                />

                <RadioGroupField
                  direction="row"
                  label="Status: "
                  name="status"
                  defaultValue="all"
                  onChange={(e) => {
                    if (e.target.value === "all") {
                      return setPayoutStatus(undefined);
                    }

                    setPayoutStatus(e.target.value as PayoutStatus);
                  }}
                >
                  <Radio value={"all"}>All</Radio>
                  <Radio value={PayoutStatus.Paid}>Paid</Radio>
                  <Radio value={PayoutStatus.Planned}>Planned</Radio>
                </RadioGroupField>
              </Flex>
              <Flex
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                grow={1}
                basis={"50%"}
              >
                <Flex>
                  <Button
                    size="small"
                    gap={12}
                    onClick={() => {
                      if (payouts) {
                        generateMassPaymentsFile(
                          payouts.filter(isNationalPayout)
                        );
                      }
                    }}
                  >
                    <MdDownload /> Elixir (PL)
                  </Button>

                  <Button
                    size="small"
                    gap={12}
                    onClick={() => {
                      if (payouts) {
                        generateMassPaymentsFile(
                          filterPlannedNonVatNationalPayouts(payouts)
                        );
                      }
                    }}
                  >
                    <MdDownload /> Elixir (PL) - Planned Only
                  </Button>

                  <Button
                    size="small"
                    gap={12}
                    loadingText="Loading"
                    isLoading={settingAsPaid}
                    onClick={async () => {
                      const toBePaidPayouts =
                        filterPlannedNonVatNationalPayouts(payouts!).filter(
                          (payout) => !payout.paid_date && payout.amount > 0
                        );

                      // eslint-disable-next-line no-restricted-globals
                      const confirmed = confirm(
                        `WARNING! ${
                          toBePaidPayouts!.length
                        } payouts will be marked as paid with today's date.`
                      );

                      if (confirmed) {
                        setSettingAsPaid(true);

                        try {
                          for (let i = 0; i < toBePaidPayouts.length; i++) {
                            if (!toBePaidPayouts[i].paid_date) {
                              await setPayoutPaidDate(
                                String(toBePaidPayouts[i].id),
                                new Date()
                              );

                              await wait(300);
                            }
                          }

                          alert(
                            `${
                              toBePaidPayouts!.length
                            } payouts were marked as paid. Refresh the site to check the results.`
                          );
                        } catch (err) {
                          alert("ERROR");
                        } finally {
                          setSettingAsPaid(false);

                          // eslint-disable-next-line no-restricted-globals
                          location.reload();
                        }
                      }
                    }}
                  >
                    <MdDownload /> Set As Paid - (PL/Non-VAT)
                  </Button>

                  <Button
                    size="small"
                    gap={12}
                    onClick={() => {
                      if (payouts) {
                        generateMassPaymentsFile(
                          payouts.filter(isInternationalPayout)
                        );
                      }
                    }}
                  >
                    <MdDownload /> Elixir (EU)
                  </Button>

                  <Button
                    size="small"
                    gap={12}
                    onClick={() => {
                      if (payouts) {
                        window.open("test", "_blank");
                      }
                    }}
                  >
                    <MdDownload /> Statements
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Flex>

      <Flex justifyContent={"space-between"}>
        <Stats stats={stats}></Stats>
        <HelpButton url="https://docs.google.com/document/d/1RkJfp8edjVmyZdWI10dW7A06IdJPqIOftP1OJJcCWHM/edit?usp=sharing" />
      </Flex>

      <Flex overflow="hidden">
        {payoutsForDisplay && payoutsForDisplay?.length > 0 ? (
          <PayoutsTable items={payoutsForDisplay}></PayoutsTable>
        ) : (
          <Flex>There are no payouts for the selected criteria.</Flex>
        )}
      </Flex>
    </Flex>
  );
};
