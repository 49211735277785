import { Auth } from "aws-amplify";
import { RTableColumn } from "../components/tables/TableColumn";

export type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null;
};

export interface ISuccessfulFetch<T> {
  status: "success";
  result: T;
}

export interface ApiHookResult<T, Q> {
  data: T | undefined;
  loading: boolean;
  error: any;
  refetch: (params: Q) => void;
}

export interface ReportTable<Row, Sum> {
  items: Row[];
  sums: Sum;
  columns: RTableColumn<Row>[];
}

export type ApiHook<T, Q> = () => ApiHookResult<T, Q>;

export const unmarshallDates = (
  data: Record<string, any>,
  dateKeys: string[]
) => {
  const unmarshalled = { ...data };

  dateKeys.forEach((key) => {
    if (data[key] === null || data[key] === undefined) {
      return;
    }

    unmarshalled[key] = new Date(data[key]);
  });

  return unmarshalled;
};

export const prepareQueryString = (params: Record<string, any>) => {
  const keys = Object.keys(params);

  if (!keys.length) {
    return "";
  }

  return `?${keys
    .filter((key) => params[key] !== undefined)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&")}`;
};

export const prepareHerokuRequest = async (
  init: {
    responseType?: "blob" | "json";
    method?: string;
    body?: any;
    headers?: Record<string, any>;
  } = {}
) => ({
  ...init,
  headers: {
    ...(init.headers || {}),
    "Content-Type": "application/json",
    Authorization: `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`,
  },
});

export const getAuthHeader = async (auxHeaders: Record<string, any> = {}) => ({
  headers: {
    ...auxHeaders,
    Authorization: `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`,
  },
});
