import { Card, Flex } from "@aws-amplify/ui-react";
import { UserDetails } from "../shared/hooks/useUserData";
import { useNavigate } from "react-router-dom";

export default ({ user }: { user: Omit<UserDetails, "email"> }) => {
  const navigate = useNavigate();
  return (
    <Flex grow={1}>
      <Card
        variation="elevated"
        onClick={() => {
          navigate(`/users/details?userId=${user.id}`);
        }}
        style={{ cursor: "pointer" }}
      >
        <Flex>{`ID: ${user.id}`}</Flex>
        <Flex>{`Name: ${user.first_name} ${user.last_name}`}</Flex>
        <Flex>{`Company: ${user.company_name ?? "n/a"}`}</Flex>
        <Flex>{`VAT: ${user.vat_id ?? "n/a"}`}</Flex>
      </Card>
    </Flex>
  );
};
