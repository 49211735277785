import React from "react";
import { Link } from "@aws-amplify/ui-react";
import { theme } from "../../../theme";
import { downloadFile } from "../utils/downloadFile";

export default (props: { label: string; href: string }) => (
  <Link
    color={theme.primaryColor}
    textDecoration="underline dotted"
    isExternal={true}
    onClick={async () => {
      if (props.href) {
        await downloadFile(props.href);
      }
    }}
    target="_blank"
  >
    {props.label}
  </Link>
);
