import { Flex, Badge, Button, Card } from "@aws-amplify/ui-react";
import { MdDownload } from "react-icons/md";
import { useDocsDownload } from "../shared/hooks/useDocs";
import { UserDocument } from "./UserDocument";

export default function DocComponent({ doc }: { doc: UserDocument }) {
  const { download, loading: docLoading } = useDocsDownload();

  return (
    <Card variation="elevated">
      <Flex direction={"column"}>
        <Badge>{doc.type}</Badge>

        <Button
          isLoading={docLoading}
          onClick={() => {
            download({ fileKey: doc.file_key });
          }}
        >
          <MdDownload></MdDownload> {doc.file_key}
        </Button>
      </Flex>
    </Card>
  );
}
