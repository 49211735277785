import { API } from "../utils/API";

export enum SortFlag {
  Ascending = "asc",
  Descending = "desc",
}

export enum ContractType {
  All = "all",
  Mgmt = "mgmt",
  Invest = "invest",
}

interface BaseContract {
  airbnb_url: string;
  booking_url: string;
  company_name: string;
  contract_cancelled: boolean;
  contract_id: number;
  first_name: string;
  google_url: string;
  hostaway_id: number;
  internal_property_id: number;
  last_name: string;
  mgmt_contract_id: number;
  name: string;
  property_disabled: boolean;
  user_id: number;
  vrbo_url: string;
}

interface InvestContract extends BaseContract {
  contract_type: "invest";
  share_of_project_income: number;
}

interface ManagementContract extends BaseContract {
  contract_type: "mgmt";
  participation_rate: number;
}

export type Contract = InvestContract | ManagementContract;

const getContracts = async (
  type = ContractType.All,
  sort = SortFlag.Ascending
): Promise<Contract[]> =>
  API.get(`/v1/admin/contracts/manager?type=${type}&sort=${sort}`);

const getContract = async (type: string, id: string): Promise<Contract> =>
  API.get(`/v1/admin/contracts/manager/${type}/${id}`);

export const useAdminContracts = () => {
  return {
    getContracts,
    getContract,
  };
};
