import {
  Alert,
  Badge,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { useMemo } from "react";
import { useCurrentUser } from "../shared/hooks/useCurrentUser";

const VALIDATION_PRECISION = 1e-2;

export function isApproximatelyEqual(
  a: number,
  b: number,
  epsilon = VALIDATION_PRECISION
) {
  // precision 0.0001
  return Math.abs(a - b) < epsilon;
}

export default ({
  baseRate,
  vat,
  cityTax,
  total,
  cleaningFee,
  ownerPayout,
  payoutRentalIncome,
  payoutTotalTax,
  payoutReservationPortalFee,
  payoutPmCommission,
}: {
  baseRate: number;
  vat: number;
  cityTax: number;
  total: number;
  cleaningFee: number;
  ownerPayout: number;
  payoutRentalIncome: number;
  payoutTotalTax: number;
  payoutReservationPortalFee: number;
  payoutPmCommission: number;
}) => {
  const { isAccounting } = useCurrentUser();
  const validations = useMemo(() => {
    const results = [];

    if (!isApproximatelyEqual(baseRate + vat + cityTax + cleaningFee, total)) {
      results.push({
        title: `"base_rate + vat + city_tax + cleaning_fee" (${
          baseRate + vat + cityTax + cleaningFee
        }) should be "total_price" (${total})`,
      });
    }

    let checksum1 = payoutRentalIncome - (payoutRentalIncome - cityTax) / 1.08;
    let checksum2 = payoutTotalTax;
    if (!isApproximatelyEqual(checksum1, checksum2)) {
      results.push({
        title: `"payoutRentalIncome - (payoutRentalIncome - cityTax) / 1.08" (${checksum1}) is not "payoutTotalTax" (${checksum2})`,
      });
    }

    checksum1 = baseRate * 0.08;
    checksum2 = vat;
    if (!isApproximatelyEqual(checksum1, checksum2)) {
      results.push({
        title: `"baseRate * 0.08" (${checksum1}) is not "vat" (${checksum2})`,
      });
    }

    if (baseRate && !isApproximatelyEqual(vat / baseRate, 0.08)) {
      results.push({ title: "'vat / base_rate' should be 8%" });
    }

    if (ownerPayout > total - vat - cityTax - cleaningFee) {
      results.push({
        title:
          "'ownerPayout' is larger than 'total - vat - cityTax - cleaningFee'",
      });
    }

    if (
      !isApproximatelyEqual(
        ownerPayout,
        payoutRentalIncome -
          payoutTotalTax -
          payoutReservationPortalFee -
          payoutPmCommission
      )
    ) {
      results.push({
        title: `'payoutRentalIncome - payoutTotalTax - payoutReservationPortalFee - payoutPmCommission' (${
          payoutRentalIncome -
          payoutTotalTax -
          payoutReservationPortalFee -
          payoutPmCommission
        })  should be 'ownerPayout' ${ownerPayout}.`,
      });
    }

    if (
      baseRate &&
      payoutRentalIncome &&
      payoutTotalTax &&
      !isApproximatelyEqual(
        (payoutTotalTax - cityTax) / (payoutRentalIncome - payoutTotalTax),
        0.08
      )
    ) {
      results.push({
        title:
          "!isApproximatelyEqual((((payoutTotalTax - cityTax) / (payoutRentalIncome - cityTax))), 0.08))",
      });
    }

    return results;
    // TODO fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseRate, vat, cityTax, total, cleaningFee]);

  return (
    <Flex direction={"column"}>
      <Table
        padding={0}
        size="small"
        variation="striped"
        highlightOnHover={false}
      >
        <TableHead>
          <TableRow>
            <TableCell
              as="th"
              colSpan={5}
              textAlign={"center"}
              boxShadow={"medium"}
            >
              Revenue Accounting
            </TableCell>
          </TableRow>
          <TableRow boxShadow={"medium"}>
            <TableCell as="th">Total</TableCell>

            <TableCell as="th">Base Rate</TableCell>

            <TableCell as="th">VAT</TableCell>

            <TableCell as="th">City Tax</TableCell>

            <TableCell as="th">Cleaning Fee</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>{total}</TableCell>

            <TableCell>{baseRate}</TableCell>

            <TableCell>{vat}</TableCell>

            <TableCell>{cityTax}</TableCell>

            <TableCell>{cleaningFee}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {!isAccounting && (
        <Table
          padding={0}
          size="small"
          variation="striped"
          highlightOnHover={false}
        >
          <TableHead>
            <TableRow>
              <TableCell
                as="th"
                colSpan={5}
                textAlign={"center"}
                boxShadow={"medium"}
              >
                Owner Payout
              </TableCell>
            </TableRow>
            <TableRow boxShadow={"medium"}>
              <TableCell as="th">Payout Rental Income</TableCell>

              <TableCell as="th">Payout Taxes</TableCell>

              <TableCell as="th">Payout Reservation Portal Fee</TableCell>

              <TableCell as="th">Payout PM Commission</TableCell>

              <TableCell as="th">Payout Owner</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>{payoutRentalIncome}</TableCell>
              <TableCell>{payoutTotalTax}</TableCell>
              <TableCell>
                {payoutReservationPortalFee}{" "}
                {!!payoutReservationPortalFee && (
                  <Badge>
                    {Math.round(
                      (payoutReservationPortalFee /
                        (payoutRentalIncome + cleaningFee)) *
                        100
                    )}
                    %
                  </Badge>
                )}
              </TableCell>
              <TableCell>
                {payoutPmCommission}{" "}
                {!!payoutPmCommission && (
                  <Badge>
                    {Math.round(
                      (payoutPmCommission / payoutRentalIncome) * 100
                    )}
                    %
                  </Badge>
                )}
              </TableCell>
              <TableCell>{ownerPayout}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}

      {validations.map((validation) => (
        <Alert variation="error" title={"Validation failed "}>
          {validation.title}
        </Alert>
      ))}
    </Flex>
  );
};
