import FileDropzone from "../Docs/FileDropzone";
import { getUploadUrlForTask } from "../shared/hooks/useTasksApi";

export default ({
  taskId,
  onChange,
}: {
  taskId: string;
  onChange: () => void;
}) => {
  return (
    <FileDropzone
      onChange={onChange}
      url={getUploadUrlForTask(taskId)}
      fields={
        taskId
          ? {
              taskId: String(taskId),
            }
          : undefined
      }
      accept="image/*"
    />
  );
};
