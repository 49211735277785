import {
  Alert,
  Badge,
  Button,
  Card,
  Flex,
  Loader,
} from "@aws-amplify/ui-react";
import { useDocsDownload } from "../../shared/hooks/useDocs";
import { UserDetailsWithDocs } from "../../shared/hooks/useUserData";
import { MdDownload } from "react-icons/md";
import Uploader from "../../Docs/Uploader";

export default function ({
  data,
  onChange,
}: {
  onChange: () => void;
  data: UserDetailsWithDocs;
}) {
  const { download, loading } = useDocsDownload();

  return (
    <Flex direction={"column"}>
      <Uploader onChange={onChange} userId={data.id}></Uploader>

      {loading && <Loader variation="linear" />}

      {data.docs.length === 0 && (
        <Alert variation="info">No documents saved</Alert>
      )}

      {data.docs.map((doc, index) => (
        <Card variation="elevated" key={`doc-${index}`}>
          <Flex direction={"column"}>
            <Badge>{doc.type}</Badge>

            <Button
              isLoading={loading}
              disabled={!data}
              onClick={async () => {
                await download({ fileKey: doc.file_key });
              }}
            >
              <MdDownload /> {doc.file_key}
            </Button>
          </Flex>
        </Card>
      ))}
    </Flex>
  );
}
