import {
  Alert,
  Button,
  TableBody,
  TableCell,
  TableRow,
} from "@aws-amplify/ui-react";
import { Modal, Table } from "react-bootstrap";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { ProposalStateResolver } from "./AdminPayoutHelpers";
import { UIIStatementProposal } from "./AdminPayoutPlanningTable";
import roundFinancialNumber from "../../shared/utils/roundFinancialNumber";

interface AdminPayoutPlanningDetailsProps {
  approveProposal: (proposal: UIIStatementProposal) => void;
  getProposalState: ProposalStateResolver;
  handleClose: () => void;
  proposal: UIIStatementProposal;
}

export default function PayoutPlanningDetails({
  approveProposal,
  getProposalState,
  handleClose,
  proposal,
}: AdminPayoutPlanningDetailsProps) {
  const resolvedState = getProposalState(proposal);

  const { title, primaryButton } = ((): {
    title: string;
    primaryButton: null | { label: string; handler: () => void };
  } => {
    switch (resolvedState) {
      case "NeedsApproval":
        return {
          title: "Approve Proposal",
          primaryButton: {
            label: "Approve",
            handler: () => {
              approveProposal(proposal);
              handleClose();
            },
          },
        };
      default:
        return {
          title: "Details",
          primaryButton: null,
        };
    }
  })();

  const tableConfig: [string, string][] = [
    ["Payout ID", `${proposal.payout_id}`],
    ["Type", `${proposal.statement_type}`],
    ["Rental Income", `${proposal.rental_income} PLN`],
    [
      "Sum of Components",
      `${roundFinancialNumber(
        Number(proposal.reservation_portal_fee) +
          Number(proposal.total_tax) +
          Number(proposal.payout_base) +
          Number(proposal.pm_commission)
      )} PLN`,
    ],
    ["Reservation Portal Fee", `${proposal.reservation_portal_fee}`],
    [
      "Total Tax",
      `${proposal.total_tax} PLN (${roundFinancialNumber(
        (100 * Number(proposal.total_tax)) /
          (Number(proposal.rental_income) - Number(proposal.total_tax))
      )}%)`,
    ],
    [
      "PM Commission",
      `${proposal.pm_commission} PLN (${roundFinancialNumber(
        (100 * Number(proposal.pm_commission)) / Number(proposal.rental_income)
      )}%)`,
    ],
    ["Payout Base", `${proposal.payout_base} PLN`],
    ["Total Extras / Expenses", `${proposal.total_expenses} PLN`],
    ["Statement Total", `${proposal.grand_total} PLN`],
    ["Participation Rate", `${proposal.participation_rate} %`],
    ["Prorated Payout", `${proposal.prorated_payout} PLN`],
  ];

  const createTable = (rows: [string, string][]) => (
    <Table>
      <TableBody>
        {rows.map(([title, value], index) => (
          <TableRow key={index}>
            <TableCell>{title}</TableCell>
            <TableCell textAlign="right">{value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {createTable(tableConfig)}
        {!proposal.passed_plausibility && (
          <Alert variation="warning" width="100%">
            Be careful, this proposal did not pass plausibility checks!
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>

        {primaryButton && (
          <Button variation="primary" onClick={primaryButton.handler}>
            {primaryButton.label}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
