import { Flex, Link, useBreakpointValue } from "@aws-amplify/ui-react";
import { PropsWithChildren } from "react";
import {
  MdAnalytics,
  MdCleaningServices,
  MdCloud,
  MdContentPaste,
  MdDocumentScanner,
  MdHouse,
  MdPages,
  MdPayments,
  MdPerson,
  MdPersonAdd,
  MdReport,
  MdSearch,
  MdSettings,
  MdWork,
} from "react-icons/md";
import { theme } from "../theme";
import { useCurrentUser } from "./shared/hooks/useCurrentUser";
import Header from "./shared/molecules/Header/Header";

export default ({ children }: PropsWithChildren) => {
  const sidebarWidth = 250;
  const isMatchingMobile = useBreakpointValue({ base: true, medium: false });
  const { isAdmin, isAccounting, isService, isCoordinator } = useCurrentUser();

  return (
    <Flex direction={"column"} height={"100vh"}>
      <Flex>
        {!isMatchingMobile && (
          <Flex
            className="noprint"
            position={"fixed"}
            height={"100vh"}
            width={sidebarWidth}
            backgroundColor={theme.background}
            direction={"row"}
            justifyContent={"center"}
          >
            {false && (
              <Flex
                height={"100%"}
                backgroundColor="red"
                width={50}
                overflow={"scroll"}
                alignItems={"center"}
                justifyContent={"space-evenly"}
                direction="column"
              >
                <Flex>
                  <MdCleaningServices></MdCleaningServices>
                </Flex>

                <Flex>
                  <MdSettings></MdSettings>
                </Flex>

                <Flex>
                  <MdPayments></MdPayments>
                </Flex>

                <Flex>
                  <MdAnalytics></MdAnalytics>
                </Flex>
              </Flex>
            )}

            <Flex
              className="noprint"
              direction="column"
              overflow={"scroll"}
              grow={1}
              padding={16}
              paddingBottom={32}
            >
              {(isAdmin || isAccounting) && (
                <Flex>
                  <Link href="/">
                    <MdSearch></MdSearch> Search
                  </Link>
                </Flex>
              )}

              {(isAdmin || isAccounting) && (
                <>
                  <Flex>Accounting</Flex>
                  <Flex>
                    <Link href="/reporting/consolidated">
                      <MdReport></MdReport> Consolidated Report
                    </Link>
                  </Flex>

                  <Flex>
                    <Link href="/reporting">
                      <MdReport></MdReport> Reservations Report
                    </Link>
                  </Flex>

                  <Flex>
                    <Link href="/reporting/charges">
                      <MdReport></MdReport> Charges Report
                    </Link>
                  </Flex>
                </>
              )}

              {(isCoordinator || isAdmin) && (
                <>
                  <Flex>Coordination</Flex>
                  <Flex>
                    <Link href="/cleaning-schedule">
                      <MdContentPaste></MdContentPaste> Cleaning Schedule
                    </Link>
                  </Flex>
                  <Flex>
                    <Link href="/company/people">
                      <MdPersonAdd></MdPersonAdd> Manage People
                    </Link>
                  </Flex>
                </>
              )}

              {(isCoordinator || isService || isAdmin) && (
                <>
                  <Flex>Service</Flex>
                  <Flex>
                    <Link href="/rep/schedule">
                      <MdContentPaste></MdContentPaste> My Schedule
                    </Link>
                  </Flex>
                  <Flex>
                    <Link href="/rep/report">
                      <MdReport></MdReport> My Report
                    </Link>
                  </Flex>
                </>
              )}

              {isAdmin && (
                <>
                  <Flex>Management</Flex>
                  <Flex>
                    <Link href="/users">
                      <MdPerson></MdPerson> Users
                    </Link>
                  </Flex>
                  <Flex>
                    <Link href="/properties">
                      <MdHouse></MdHouse> Properties
                    </Link>
                  </Flex>

                  <Flex>
                    <Link href="/access-instructions">
                      <MdHouse></MdHouse> Access Instructions
                    </Link>
                  </Flex>

                  <Flex>
                    <Link href="/contracts">
                      <MdDocumentScanner></MdDocumentScanner> Contracts
                    </Link>
                  </Flex>

                  <Flex>
                    <Link href="/documents">
                      <MdPages></MdPages> Documents
                    </Link>
                  </Flex>

                  <Flex>
                    <Link href="/occupancy">
                      <MdAnalytics></MdAnalytics> Occupancy Report
                    </Link>
                  </Flex>
                </>
              )}

              {isAdmin && (
                <>
                  <Flex>Payouts</Flex>
                  <Flex>
                    <Link href="/reporting/payouts">
                      <MdReport></MdReport> Validation
                    </Link>
                  </Flex>

                  <Flex>
                    <Link href="/payouts-planning">
                      <MdWork></MdWork> Planning
                    </Link>
                  </Flex>
                  <Flex>
                    <Link href="/payouts">
                      <MdPayments></MdPayments> Execution
                    </Link>
                  </Flex>

                  <Flex>Debug</Flex>
                  <Flex>
                    <Link href="/background-jobs">
                      <MdCloud></MdCloud> Background Jobs
                    </Link>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        )}
        <Flex
          className="print-layout-navcolumn"
          marginLeft={isMatchingMobile ? undefined : sidebarWidth}
          grow={1}
          overflow={"hidden"}
          direction={"column"}
          padding={16}
        >
          <Flex grow={1}>
            <Header />
          </Flex>

          <Flex overflow={"scroll"}>{children}</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
