import {
  Alert,
  Badge,
  Button,
  Flex,
  Heading,
  Placeholder,
  TabItem,
  Tabs,
  Text,
} from "@aws-amplify/ui-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { MdBed, MdCameraAlt, MdTimelapse } from "react-icons/md";
import { useParams } from "react-router-dom";
import ServiceUserAutocomplete from "../Properties/ServiceUserAutocomplete";
import TextMuted from "../shared/components/TextMuted";
import { useUserProfile } from "../shared/components/UserProfileProvider";
import { TaskProgressStatus } from "../shared/hooks/core-coordination-task";
import { useCurrentUser } from "../shared/hooks/useCurrentUser";
import {
  useTask,
  useTaskRequirementValues,
  useTaskTemplates,
  useTaskUpdate,
  useTaskUpdateProgressStatus,
} from "../shared/hooks/useTasksApi";
import ImageTimeline from "./ImageTimeline";
import ImageUploader from "./ImageUploader";
import TaskActivity from "./TaskActivity";
import TaskChat from "./TaskChat";
import TaskNotes from "./TaskNotes";
import TaskRequirements from "./TaskRequirements";
import ScheduledDatePicker from "./components/ScheduledDatePicker";
import TaskStateBadge from "./components/TaskStateBadge";

export default function TaskPage({ taskId }: { taskId?: string }) {
  // use "en" locale in admin pages
  moment.locale("en");
  const params = useParams();
  const { isCoordinatorOrMore } = useCurrentUser();
  const { userProfile } = useUserProfile();
  const { data, loading, refetch, error } = useTask(taskId || params.taskId!);
  const {
    data: templateData,
    loading: templateDataLoading,
    load: loadTemplates,
  } = useTaskTemplates();
  const { update, loading: updating } = useTaskUpdate(taskId || params.taskId!);
  const { data: requirementsData, load: loadRequirements } =
    useTaskRequirementValues(taskId || params.taskId!);

  useEffect(() => {
    if (data?.template_id) {
      loadTemplates({ templateId: data?.template_id });
    }
  }, [data, loadTemplates]);

  useEffect(() => {
    loadRequirements();
  }, [loadRequirements]);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const { update: updateProgressStatus } = useTaskUpdateProgressStatus(
    taskId || params.taskId!
  );

  const changeTab = (tabId: number | string) => {
    setTabIndex(tabId as number);
  };

  return (
    <Flex direction={"column"} grow={1}>
      {error && <Alert variation="error">{error.message}</Alert>}

      {loading && (
        <Flex direction={"column"}>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={200}></Placeholder>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={50}></Placeholder>
          <Placeholder height={50}></Placeholder>
        </Flex>
      )}

      {data && (
        <Flex direction="column" gap={36}>
          <Flex direction={"column"}>
            <Flex direction={"row"} alignItems={"center"}>
              <Flex direction={"column"}>
                <TaskStateBadge task={data}></TaskStateBadge>
              </Flex>
              <Heading level={3}>{data?.address}</Heading>
            </Flex>
            <Text>ID: {data?.task_id}</Text>

            <Flex>
              {data.sameDayCheckinExists && <Badge>Same Day Checkin</Badge>}
            </Flex>

            <Flex>
              {!data.progress_status && (
                <Button
                  disabled={updating || loading}
                  variation="primary"
                  onClick={async () => {
                    await updateProgressStatus({
                      progress_status: TaskProgressStatus.Started,
                    });
                    refetch();
                  }}
                >
                  Start Task
                </Button>
              )}

              {!data.progress_status ||
                (data.progress_status === TaskProgressStatus.Started && (
                  <Flex direction={"column"}>
                    <Button
                      disabled={
                        updating ||
                        loading ||
                        (requirementsData &&
                          Number(requirementsData.fullfilled) <
                            Number(requirementsData.requirementsCount))
                      }
                      variation="primary"
                      onClick={async () => {
                        await updateProgressStatus({
                          progress_status: TaskProgressStatus.Completed,
                        });
                        refetch();
                      }}
                    >
                      Mark as Finished
                    </Button>
                  </Flex>
                ))}

              <Flex direction={"row"}>
                <Flex alignItems={"center"}>
                  {data.progress_status === TaskProgressStatus.Started && (
                    <Badge>Started</Badge>
                  )}
                  {data.progress_status === TaskProgressStatus.Completed && (
                    <Badge variation="success">Finished</Badge>
                  )}
                </Flex>

                {data.duration && (
                  <Flex alignItems={"center"}>
                    <Heading>
                      <MdTimelapse></MdTimelapse>Time
                    </Heading>

                    {data.duration}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
          {requirementsData &&
            requirementsData.fullfilled <
              requirementsData?.requirementsCount && (
              <Alert>
                All requirements must be fullfilled before you can mark this
                task as done.{" "}
                {`${requirementsData.fullfilled} / ${requirementsData?.requirementsCount}`}
              </Alert>
            )}
          <Tabs
            spacing="equal"
            marginBottom={12}
            onChange={changeTab}
            currentIndex={tabIndex}
          >
            <TabItem title={"Overview"}>
              <Flex direction="column">
                <Flex direction={"row"} justifyContent={"flex-start"}>
                  <Flex direction={"column"} minWidth={200}>
                    <Heading>Due on</Heading>
                    {isCoordinatorOrMore && (
                      <ScheduledDatePicker
                        onChange={async (date) => {
                          if (!date) {
                            await update({
                              task_id: taskId || params.taskId!,
                              scheduled_date: null,
                            });
                          }

                          await update({
                            task_id: taskId || params.taskId!,
                            scheduled_date: date,
                          });

                          refetch();
                        }}
                        value={data.scheduled_date}
                      ></ScheduledDatePicker>
                    )}

                    {data.scheduled_date}
                  </Flex>

                  <Flex>
                    <Flex direction="column" gap={0}>
                      <ServiceUserAutocomplete
                        isReadOnly={!isCoordinatorOrMore}
                        label={"Assigned User"}
                        defaultValue={String(data.user_name || "")}
                        onSave={async (userId) => {
                          await update({
                            task_id: taskId || params.taskId!,
                            user_id: userId,
                          });
                        }}
                      ></ServiceUserAutocomplete>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex direction={"column"}>
                  <Heading>Scheduled at</Heading>
                  <Text>{data?.scheduled_time}</Text>
                </Flex>

                <Flex direction={"column"}>
                  <Flex direction={"column"}>
                    <TaskNotes
                      isReadOnly={!isCoordinatorOrMore}
                      taskId={data.task_id}
                      defaultValue={data.notes}
                    ></TaskNotes>
                  </Flex>

                  <Flex direction={"column"} justifyContent={"center"}>
                    <Flex alignItems={"center"}>
                      <MdBed></MdBed>
                      <Heading>Next reservation</Heading>
                    </Flex>
                    <Flex alignItems={"center"}>
                      {data.nextReservation.arrival_date ===
                        data.scheduled_date && (
                        <Badge variation="warning">Same Day</Badge>
                      )}
                      <TextMuted>{`${moment(
                        data.nextReservation.arrival_date
                      ).format("D MMM")} - ${moment(
                        data.nextReservation.departure_date
                      ).format("D MMM")} · ${
                        data.nextReservation.no_of_guests
                      } guests · ${moment(
                        data.nextReservation.departure_date
                      ).diff(
                        data.nextReservation.arrival_date,
                        "days"
                      )} nights`}</TextMuted>
                    </Flex>
                  </Flex>

                  <Flex
                    direction={"column"}
                    justifyContent={"center"}
                    marginTop={32}
                  >
                    <TaskChat taskId={data.task_id}></TaskChat>
                  </Flex>

                  <Flex direction="column" marginTop={32}>
                    <Flex alignItems={"center"}>
                      <MdCameraAlt></MdCameraAlt>
                      <Heading>Attachments</Heading>
                    </Flex>

                    <ImageUploader
                      taskId={taskId || params.taskId!}
                      onChange={() => {
                        refetch();
                      }}
                    ></ImageUploader>
                  </Flex>
                  <Flex direction={"column"} justifyContent={"center"}>
                    <ImageTimeline images={data.images}></ImageTimeline>
                  </Flex>
                </Flex>
              </Flex>
            </TabItem>
            <TabItem title={"Requirements"}>
              {!templateData && (
                <Flex direction="column" justifyContent={"center"} padding={42}>
                  <TextMuted>
                    There are no specific requirements for this task.
                  </TextMuted>
                </Flex>
              )}

              {templateDataLoading && (
                <Flex direction={"column"}>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                  <Placeholder height={50}></Placeholder>
                </Flex>
              )}
              {templateData && !templateDataLoading && (
                <Flex direction={"column"}>
                  <TaskRequirements
                    readonly={data.user_id !== userProfile?.id}
                    data={requirementsData}
                    taskId={taskId || params.taskId!}
                    templateData={templateData}
                    onChange={() => {
                      loadRequirements();
                    }}
                  ></TaskRequirements>
                </Flex>
              )}
            </TabItem>

            <TabItem title={"Activity"}>
              <Flex direction={"column"} justifyContent={"center"}>
                <TaskActivity taskId={data.task_id}></TaskActivity>
              </Flex>
            </TabItem>
          </Tabs>
        </Flex>
      )}
    </Flex>
  );
}
