import { API } from "../shared/utils/API";

export const syncReservationFromHostawayToCore = async (
  hostawayReservationId: string | number
): Promise<void> =>
  (
    await API.post(`/v1/automation/reservation/sync`, {
      body: {
        hostawayReservationId,
      },
    })
  ).data;
