import { Alert, Flex } from "@aws-amplify/ui-react";
import GenericTable from "../shared/GenericTable";
import GenericTablePage from "../shared/GenericTablePage";
import AddButton from "../shared/components/AddButton";
import Box from "../shared/components/Box";
import { useCompanyPeople } from "../shared/hooks/useUserData";
import AddUserToGroup from "./AddUserToGroup";
import { useState } from "react";
import InviteNewUser from "./InviteNewUser";

export default () => {
  const { error, loading, refetch, data } = useCompanyPeople();
  const [addingNew, setAddingNew] = useState<boolean>(false);
  const [invitingNew, setInvitingNew] = useState<boolean>(false);

  return (
    <>
      <GenericTablePage
        title="Service Team"
        subtitle="Members of the service team (cleaning staff, technicians, inspectors) that can be assigned to coordination tasks"
      >
        <Flex marginBottom={35} justifyContent={"flex-start"}>
          <AddButton
            label="Add Member"
            onClick={() => {
              setAddingNew(true);
            }}
          ></AddButton>
          <AddButton
            label="Invite new Member"
            onClick={() => {
              setInvitingNew(true);
            }}
          ></AddButton>
        </Flex>
        <Flex direction={"column"}>
          {error && (
            <Box ml={20} mr={20}>
              <Alert>{error.message}</Alert>
            </Box>
          )}

          <Box>
            <GenericTable loading={loading} data={data}></GenericTable>
          </Box>
        </Flex>
      </GenericTablePage>

      {addingNew && (
        <AddUserToGroup
          onClose={() => {
            setAddingNew(false);
            refetch();
          }}
        ></AddUserToGroup>
      )}

      {invitingNew && (
        <InviteNewUser
          onClose={() => {
            setInvitingNew(false);
            refetch();
          }}
        ></InviteNewUser>
      )}
    </>
  );
};
