import { useCallback } from "react";
import { useSearchProperties } from "../shared/hooks/useSearch";
import Autocomplete from "./Autocomplete";

export default ({
  required,
  onSelected,
  defaultValue,
}: {
  required?: boolean;
  onSelected: (propertyId: number) => Promise<void>;
  defaultValue?: string | undefined;
}) => {
  const { data, loading, load } = useSearchProperties();

  const handleSearch = useCallback(
    (query: string | undefined) => {
      load({ query: query || "" });
    },
    [load]
  );

  return (
    <Autocomplete
      onSelected={async (option) => {
        await onSelected(Number(option.id));
      }}
      isLoading={loading}
      onOptionsRequested={handleSearch}
      options={
        data?.map((item) => {
          return {
            id: String(item.internal_id),
            label: item.name,
          };
        }) || []
      }
      defaultLabel={defaultValue}
      label={`Property ${required ? "*" : ""}`}
    ></Autocomplete>
  );
};
