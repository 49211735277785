import { Flex } from "@aws-amplify/ui-react";
import { theme } from "../../../theme";

export default function DisplayRecordsCount({ count }: { count: number }) {
  return (
    <Flex
      padding={8}
      grow={1}
      alignContent={"flex-end"}
      alignItems={"flex-end"}
      backgroundColor={theme.accentColor}
    >
      Displaying {count} records
    </Flex>
  );
}
