import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { theme } from "../../../../theme";

export const StyledToggleButton = styled(Dropdown.Toggle)`
  width: 45px;
  height: 45px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.primaryColor};

  &::after {
    display: none;
  }
`;

export const StyledItem = styled(Dropdown.Item)`
  padding: 16px;
  &.active {
    background-color: ${theme.primaryColor};
    color: #fff;
  }
`;
