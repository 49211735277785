import { Flex } from "@aws-amplify/ui-react";
import { useMemo } from "react";

export default ({ obj }: { obj: Object }) => {
  const stringified = useMemo(() => {
    try {
      return typeof obj === "string" ? obj : JSON.stringify(obj, undefined, 8);
    } catch (_err) {
      return "Unable to parse obj";
    }
  }, [obj]);

  return (
    <Flex border={"solid"} overflow={"scroll"} grow={1}>
      <pre>
        <Flex paddingLeft={16} paddingTop={16} overflow={"scroll"}>
          {obj ? stringified : "Unable to display obj"}
        </Flex>
      </pre>
    </Flex>
  );
};
