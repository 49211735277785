import { AmplifyProvider } from "@aws-amplify/ui-react";
import React from "react";
import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import AdminRoutes from "./routes";
import "./App.scss";

export default () => (
  <AmplifyProvider>
    <RouterProvider router={createBrowserRouter([...AdminRoutes])} />
  </AmplifyProvider>
);
