import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { apiEndpoint } from "../../config";
import { logger } from "../../logger";
import { getAuthHeader } from "../shared/hooks/common";

export default ({
  fields,
  onChange,
  accept,
  url,
  inputContent,
}: {
  fields?: { [name: string]: string };
  url: string;
  accept: string;
  onChange: () => void;
  inputContent?: React.ReactNode;
}) => {
  return (
    <Dropzone
      inputContent={inputContent}
      onChangeStatus={(file, status) => {
        if (status === "done") {
          onChange();
        }
      }}
      getUploadParams={async () => {
        return {
          ...(await getAuthHeader()),
          url: apiEndpoint + url,
          fields: fields,
        };
      }}
      canRemove={false}
      onSubmit={(files, allFiles) => {
        logger.debug(files.map((f) => f.meta));
        allFiles.forEach((f) => f.remove());
      }}
      submitButtonContent={"OK"}
      accept={accept}
    />
  );
};
