import { Flex, Heading } from "@aws-amplify/ui-react";
import ShowJson from "../shared/components/ShowJson";
import FieldValues from "./FieldValues";
import { HAReservation } from "./types/reservation";
import { Accordion } from "react-bootstrap";

export default ({
  hostawayReservation,
}: {
  hostawayReservation: HAReservation;
}) => {
  return (
    <Flex direction={"column"}>
      <Heading>PMS</Heading>

      <Accordion defaultActiveKey="0" alwaysOpen={true}>
        <Accordion.Item eventKey={"1"}>
          <Accordion.Header>Hostaway Finance Fields</Accordion.Header>
          <Accordion.Body>
            <FieldValues
              values={hostawayReservation.financeField.reduce((acc, item) => {
                acc[item.name] = item.value;

                return acc;
              }, {} as Record<string, string>)}
            ></FieldValues>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey={"2"}>
          <Accordion.Header>Hostaway Full API Data</Accordion.Header>
          <Accordion.Body>
            <ShowJson
              obj={
                hostawayReservation ||
                "Unable to load reservation from Hostaway API"
              }
            ></ShowJson>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Flex>
  );
};
