import { Contract } from "../shared/hooks/useAdminContracts";
import { RTableColumn } from "../shared/components/tables/TableColumn";

export const columns: RTableColumn<Contract>[] = [
  { label: "Contract ID" },
  { label: "User ID", fieldKey: "user_id" },
  { label: "Customer Name", fieldKey: "last_name" },
  { label: "Company Name" },
  { label: "Property Name", fieldKey: "name" },
  { label: "Status", fieldKey: "contract_cancelled", textAlign: "center" }, // Assuming textAlign does not need to be included in the label
  { label: "Contract Type" },
  { label: "Actions" },
];
