import { useEffect, useState } from "react";
import { SortConfig } from "./SortConfig";
import { sortData } from "./sortData";
import { RTableColumnFieldType } from "./TableColumn";

export const useTableFilter = <T>(initialData: T[]) => {
  const [rowData, setRowData] = useState<T[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig<T>>({
    key: null,
    direction: "ascending",
  });

  useEffect(() => {
    if (sortConfig.key) {
      const { sortedData } = sortData(
        sortConfig,
        initialData,
        sortConfig.key,
        sortConfig.type
      );

      setRowData(sortedData);
    } else {
      setRowData(initialData);
    }
  }, [initialData, sortConfig]);

  // Function to sort data
  const sortDataFn = (key: keyof T, type?: RTableColumnFieldType) => {
    const { sortedData, sortConfig: newSortConfig } = sortData(
      sortConfig,
      initialData,
      key,
      type
    );

    setRowData(sortedData);
    setSortConfig(newSortConfig);
  };

  return {
    rowData,
    setRowData,
    sortDataFn,
  };
};
