import { HAReservation } from "./types/reservation";

export const getReservationMessageUrl = (reservationId: string | number) =>
  `https://dashboard.hostaway.com/v3/messages/inbox/${reservationId}`;

export const getReservationUrl = (reservationId: string | number) =>
  `https://dashboard.hostaway.com/reservations/${reservationId}`;

export const getListingUrl = (listingId: string | number) =>
  `https://dashboard.hostaway.com/listing/${listingId}`;

export const getTotalPaidFromReservation = (
  reservation: HAReservation
): number => {
  return (
    reservation.financeField.find((field) => field.name === "totalPaid")
      ?.total || 0
  );
};

export enum ReservationCustomFieldsNames {
  CHECK_IN_STATUS = "CHECK_IN_STATUS",
  LATE_CHECKOUT = "LATE_CHECKOUT",
  EARLY_CHECKIN = "EARLY_CHECKIN",
  PROLONGING_RESERVATION_ID = "PROLONGING_RESERVATION_ID",
  PROLONGED_BY_RESERVATION_ID = "PROLONGED_BY_RESERVATION_ID",
}

export const isReservationCheckedIn = (reservation: HAReservation) => {
  try {
    const customField = reservation.customFieldValues.find(
      (customField) =>
        customField.customField.name ===
        ReservationCustomFieldsNames.CHECK_IN_STATUS
    );

    return customField ? true : false;
  } catch (err) {
    return false;
  }
};
