import { Flex, TextField } from "@aws-amplify/ui-react";
import React from "react";

export default ({ values }: { values: Record<string, string> }) => {
  return (
    <Flex direction={"column"} grow={1}>
      {Object.keys(values).map((key) => (
        <TextField
          readOnly
          disabled
          label={key}
          value={
            typeof values[key] === "object"
              ? JSON.stringify(values[key])
              : values[key]
          }
        />
      ))}
    </Flex>
  );
};
