import { useCallback, useState } from "react";
import { API } from "../utils/API";
import { ApiHook, prepareQueryString } from "./common";
import { Contract } from "./useAdminContracts";
import { UserDetails } from "./useUserData";
import { useLazyApi } from "./useLazyApi";
import { CorePropertyData } from "./core-property";

export interface CorePropertyAccess {
  internal_id: number;
  hostaway_id: number;
  name: string;
  checkin_instructions: string;
  checkin_instructions_pl: string;
  past_checkin_instructions: string;
  past_checkin_instructions_pl: string;
  parking_info: string;
  parking_info_pl: string;
  door_code: string;
  floor: string;
}

export type SearchResult =
  | {
      type: "user";
      data: UserDetails;
    }
  | {
      type: "contracts";
      data: Contract[];
    };

const getSearch = async (query: string): Promise<SearchResult[]> =>
  await API.get(`/v1/admin/search${prepareQueryString({ query })}`);

export const useSearch: ApiHook<SearchResult[], { query: string }> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<SearchResult[]>();
  const [error, setError] = useState<unknown>();

  const refetch = useCallback(
    (params: { query: string }) => {
      setLoading(true);

      (async () => {
        try {
          setData(await getSearch(params.query));
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      })();
    },
    [setLoading, setData, setError]
  );

  return { data, loading, error, refetch };
};

export const useSearchProperties = () => {
  return useLazyApi<CorePropertyData[], { query: string }>(
    `/v1/admin/search/properties`
  );
};
