import type { IDictionary } from "./IDictionary";

export const awsAuthPl = {
  "Incorrect username or password": "Niepoprawna nazwa użytkownika lub hasło",
  "Your passwords must match": "Twoje hasła muszą być zgodne",
  "An account with the given email already exists.":
    "Konto o podanym adresie e-mail już istnieje.",
  "Invalid verification code provided, please try again.":
    "Podano nieprawidłowy kod weryfikacyjny, spróbuj ponownie.",
  "Your code is on the way. To log in, enter the code we emailed to":
    "Kod potwierdzający został wysłany. Wpisz kod tutaj, który otrzymałeś ma emaila",
  "It may take a minute to arrive.": "To może zająć chwilkę zanim dotrze.",
  "We Emailed You": "Sprawdź skrzynkę pocztową",
};

export const errorMessagesPl = {
  "Username cannot be empty": "Nazwa użytkownika nie może być pusta",
  "User does not exist.": "Użytkownik nie istnieje.",
  "An account with the given email already exists.":
    "Konto z podanym adresem e-mail już istnieje.",
  "User is disabled.": "Użytkownik jest zablokowany.",
  "Incorrect username or password.": "Niepoprawna nazwa użytkownika lub hasło.",
  "Custom auth lambda trigger is not configured for the user pool.":
    "Należy podać hasło.",
  "Password cannot be empty": "Hasło nie może być puste",
};

export const pl: IDictionary = {
  translation: {
    Invoice: {
      Required: "Wymagany wpis",
      InvalidPostalCode: "Niepoprawny kod pocztowy dla wybranego kraju",

      NoPaymentAlert:
        "Rezerwacja nie jest w pełni opłacona. Nie możesz wygenerować faktury VAT przed dokonaniem pełnej płatności.",
      BeforeCheckoutAlert:
        "Możesz poprosić o fakturę zarówno w dniu wymeldowania, jak i później. Zachęcamy do poproszenia o fakturę w późniejszym terminie.",
      InvalidInput: "Nieprawidłowy wpis",
      NotAvailable: "Not Available",
      Title: "Poproś o Fakturę",
      InputVatLabel:
        "Proszę podać NIP (dla polskich firm) lub VAT ID (dla firm zagranicznych).",
      InputCompanyName: "Firma",
      Subtitle: "Poproś o Fakturę VAT za pobyt z RENTUJEMY",
      Cancel: "Cancel",
      Success: "Sukces",
      Submit: "Wygeneruj",
      GenerationError:
        "Wystąpił błąd podczas generowania faktury VAT. Popraw dane i spróbuj ponownie.",
    },
    FinalizeBooking: {
      Title: "Dokończ rezerwację",
      Subtitle:
        "Twoja rezerwacja nie jest jeszcze opłacona. Zauważ, że dopóki rezerwacja nie jest opłacona, nie możemy zagwarantować jej utrzymania. Aby ją potwierdzić, finalizuj swoją rezerwację teraz.",
      Submit: "Przejdź do płatności",
      SuccessTitle: "Rezerwacja potwierdzona.",
    },
    EarlyCheckin: {
      NotAvailable: "Brak Możliwości Wczesnego Zameldowania",
      Title: "Wczesne Zameldowanie od 7:00",
      Cancel: "Płatność nie powiodła się. Spróbój ponownie.",
      Success: "Wczesne Zameldowanie od 7:00 zostało potwierdzone.",
      Subtitle:
        "Zacznij swój pobyt wcześniej! Rezerwuj teraz i uzyskaj dostęp do apartamentu nie czekając do 15:00.",
      Submit: "Zamów Wczesny Check-In",
    },
    LateCheckout: {
      NotAvailable: "Brak Możliwości Późnego Wymeldowania",
      Title: "Późne Wymeldowanie do 15:00",
      Subtitle:
        "Wydłuż swój pobyt! Zmień godzinę wymeldowania na 15:00, zamiast kończyć o 11:00. Zarezerwuj już teraz!",
      Cancel: "Płatność nie powiodła się. Spróbój ponownie.",
      Success:
        "Twoje późne wymeldowanie do godziny 15:00 zostało potwierdzone.",
      Submit: "Zamów Pózne Wymeldowanie",
    },
    ReservationId: "Identyfikator rezerwacji",
    TODO: "TODO",
    OwnerPayout: "Wypłata",
    PortalFees: "Prowizja portali i systemów płatniczych",
    ManagementCommission: "Prowizja Rentujemy",
    RentujemyLogo: "Logo Rentujemy",
    YourApartment: "Twój apartment",
    PaymentId: "Identifikator płatności",
    BankAccount: "Konto bankowe",
    OwnerCockpit: "Kokpit Właściciela",
    Search: "Szukaj",
    ChooseContract: "Wybierz obiekt",
    TotalTax: "VAT & Podatki miejskie",
    CoordinatorCockpit: "Coordinator Cockpit",
    Tags: "Tagi:",
    Links: "Links:",
    More: "Więcej...",
    PropertyImage: "Zdjęcie nieruchomości",
    Address: "Adres:",
    Reservations: "Rezerwacje",
    OfferDescription: "Opis oferty",
    CheckIn: "Zameldowanie",
    CheckOut: "Wymeldowanie",
    Channel: "Portal",
    TotalPrice: "Cena wynajmu",
    Other: "Inne",
    MyContracts: "Moje Umowy",
    AllContracts: "Wszystkie Umowy",
    MyClearingReports: "Moje rozliczenia",
    Invest: "Zainwestuj",
    SeeInvestProjects: "Zobacz wszystkie projekty",
    KID: "KID (Key Information Document)",
    Airbnb: "AIRBNB",
    Booking: "BOOKING",
    Stay: "STAY.RENTUJEMY.PL",
    AcceptTerms: {
      AcceptTerms:
        "Klikając na 'Utwórz Konto' potwierdzasz, że przeczytałeś i akceptujesz",
      TermsHyperlink:
        "warunki i regulamin platformy internetowej invest.rentujemy.pl.",
    },

    GuestReservation: {
      OpenBalanceAlert: "Rezerwacja nie jest w pełni opłacona.",
      NewCheckoutDate: "Nowa Data Wyjazdu",
      RequestInvoice: "Wygeneruj Fakturę VAT",
      Title: "Przedłuż Rezerwację",
      Notes:
        "Owner's reservations cannot conflict with current reservations and can last a maximum of 14 days. The reservation itself is free, however, after each reservation, an invoice will be issued for the cleaning service after the reservation. The reservation must be confirmed by Rentujemy.",
      SendRequest: "Sprawdź Dostępność",
      FinalizeBooking: "Dalej do Rezerwacji",
      FinalizePayment: "Dokończ płatność",
      FailedMessage: "Napotkaliśmy problem techniczny. Spróbuj ponownie.",
      SuccessTitle: "Apartament jest dostępny do rezerwacji!",
      SuccessMessage:
        "Wysłaliśmy do Ciebie e-mail, abyś mógł sfinalizować Twoją rezerwację. Możesz również kliknąć przycisk poniżej, aby zakończyć proces rezerwacji.",
      PastDateError: "Dates must be in the future.",
      CheckoutBeforeCheckinError:
        "Checkout date cannot be before checkin date.",
      ReservationDurationError: "Reservation cannot be longer than 14 days.",
      GuestFirstName: "Guest first name",
      GuestLastName: "Guest last name",
      CheckinDate: "Zameldowanie",
      CheckoutDate: "Checkout date",
      NotAvailable: "The apartment is not available for the selected dates.",
      Reservation: "Rezerwacja",
    },
    Payouts: "Wypłaty",
    Account: {
      DeleteAccount:
        "Jeśli chcesz usunąć konto, napisz zapytanie na wlasiciele@rentujemy.pl",
      MyData: "Mój Profil",
      City: "Miasto",
      Country: "Kraj",
      FirstName: "Imię",
      Language: "Język",
      LastName: "Nazwisko",
      PersonalDocumentId: "PESEL",
      Street: "Ulica",
      StreetNo: "Numer budynku / mieszkania",
      Subtitle: "",
      TaxId: "NIP",
      UserId: "Identifikator użytkownika",
      ZipCode: "Kod pocztowy",
    },
    ContractType: "Typ inwestycji",
    Counterparty: "Kontrahent",
    "Dalej do danych umowy": "Dalej do danych umowy",
    "Do mojego inwestycjnego kokpitu": "Do mojego inwestycjnego kokpitu",
    "Do ostatecznego potwierdzenia inwestycji":
      "Do ostatecznego potwierdzenia inwestycji",
    EarliestCancellationDate: "Najwcz. data wypowiedzenia",
    FixedInterestRate: "Oprocentowanie",
    InvestedAmount: "Zainwestowana kwota",
    InvestmentAmountContent: {
      HowIsItCalculated: "Jak to zostało obliczone?",
      CurrencyConversionDisclaimer:
        "Kwota EUR zostanie przeliczona na walutę PLN po pomyślnym zaakceptowaniu oferty. Wypłaty bedą przeliczane w dniu płatności na twoje konto przy użyciu obowiązującego kursu wymiany.",
      InvestInEUR: "Inwestuję w EUR",
      InvestInPLN: "Inwestuję w PLN",
      MonthlyIncomeDisclaimer:
        "* obliczone na podstawie załozej zmiennej stopy oprocentowania zalenej od dochodu z nieruchomości. Tutaj załozono 8%.",
      MonthlyIncomeHeadline:
        "Zmienne miesięczne oprocentowanie na twoje konto *",
      YearlyIncomeDisclaimer:
        "Obliczone na podstawie prognozy dochodu z nieruchomości. Tutaj obliczono {{roi}}%",
      YearlyIncomeHeadline:
        "Zmienne roczne oprocentowanie na twoje konto, z miesięcznymi wypłatami",
    },
    InvestmentConfirm: {
      ConfirmInvestmentInfo:
        "Niniejszym potwierdzam, że otrzymałem i zapoznałem się z dokumentacją kontraktową. Rozumiem ryzyko związane z moją inwestycją.",
      ConfirmNetWorthInfo:
        "Niniejszym potwierdzam, że mój średni miesięczny dochód netto wynosi co najmniej 10000 PLN lub że posiadam aktywa o wartości co najmniej 500 000 PLN, którymi mogę swobodnie dysponować.",
      ConfirmOwnIdentity:
        "Niniejszym potwierdzam, że dokonuję tej inwestycji we własnym imieniu i na własny rachunek.",
      ConfirmTermsOfService: "Niniejszym wyrażam zgodę na warunki użytkowania.",
      Headline: "Potwierdzenie Twojej oferty inwestycjnej",
    },
    InvestmentContractContent: {
      EnsureYouKnowTheRisks: `Upewnij się, że znasz ważne informacje o projekcie. Wszystkie
      inwestycje niosą ze sobą ryzyko utraty kapitału. Firma Rentujemy
      Sp. z.o.o. w swoich dokumentach inwestycyjnych wymienia kilka
      ryzyk związanych z jej działalnością, takich jak: B. Niepewność
      co do prognoz i planów biznesowych, które mogą skutkować
      niższymi od oczekiwanych zwrotami lub utratą kapitału. Inwestuj
      odpowiedzialnie i zawsze zapoznaj się ze wszystkimi materiałami
      inwestycyjnymi przed podjęciem jakiejkolwiek decyzji
      inwestycyjnej.`,
      Headline: "Twoja Umowa Inwestycyjna",
      SaveContracts:
        "Prosimy o zapisanie następujących przykładowych dokumentów do projektu:",
    },
    InvestmentSuccess: {
      Headline: "Dziękujemy za inwestycję, została pomyślnie potwierdzona.",
      Subtitle:
        "Dziękujemy za ofertę inwestycję, została pomyślnie oddana. W przeciągu następnych dni dostaniesz od nas oficjalne potwierdzenie inwestycji.",
    },
    InvestmentWireInvestment: {
      Headline: "Przelew",
      TransferCommitment:
        "Kwotę przeleję na powyższe konto w ciągu 5 dni roboczych od złożenia oferty. Jestem świadomy/a, że Rentujemy Sp. o.o.o może w innym wypadku nie przyjąć propozycji umowy.",
      TransferNow:
        "Prosimy o natychmiastowe przelanie kwoty na poniższe konto podając tytuł przelewu.",
    },
    Inwestycja: "Inwestycja",
    "Kokpit Inwestora": "Kokpit Inwestora",
    Konto: "Konto",
    "Kwota inwestycji": "Kwota inwestycji",
    "Kwota Przelewu": "Kwota Przelewu",
    ContractUrl: "Link do umowy",
    MyInvests: "Moje Inwestycje",
    City: "Miasto",
    "Income Calculator": "Kalkulator przychodów",
    shortTermCalculator: {
      chooseCity: "Wybierz miasto:",
      chooseDistrict: "Wybierz dzielnicę:",
      chooseRegion: "Wybierz region:",
      chooseType: "Wybierz rodzaj apartamentu:",
      chooseStandard: "Wybierz standard:",
      studio: "Studio",
      twoRooms: "Dwa pokoje",
      threeRooms: "Trzy pokoje",
      twoRoomsAlternative: "2 pokoje",
      threeRoomsAlternative: "3 pokoje",
      comfort: "Comfort",
      premium: "Premium",
      Warsaw: "Warszawa",
      Lodz: "Łódź",
      Tricity: "Trójmiasto",
    },
    ROICalculator: {
      CalculateROI: "Oblicz szacunkową wartość ROI",
      Amount: "Wpisz kwotę",
      TaxAmount: "Wpisz stawkę podaku",
      RentalIncome: "Wprowadź przychód z wynajmu",
      UtilityCosts: "Koszty utrzymania nieruchomości",
      TaxRate: "Wprowadź stawkę podatkową (%)",
      PropertyPurchasePrice: "Wprowadź cenę zakupu nieruchomości",
      CheckOut: "Oblicz",
      IncomeDisclaimer:
        "Zarobki netto za noc to cena ustalona przez Rentujemy pomniejszona o opłaty serwisowe portali rezerwacyjnych, opłaty prowizyjne Rentujemy oraz podatek VAT. Nie uwzględniamy kosztów prowadzenia działalności lub podatków od dochodu z wynajmu. Rzeczywiste zarobki zależą od wielu czynników, takich jak dostępność, cena i popyt w Twojej okolicy, a także innych czynników.",
      ApartmentStandard: "Standard mieszkania",
      NoOfRooms: "Liczba pokoi",
      Title: "Kalkulator zwrotu z inwestycji w nieruchomość",
      PricePerNight: "Cena za noc",
      PricePerNightDisclaimer:
        "Dowiedz się, jak Rentujemy ustala ceny wynajmu.",
      OwnerSubtitle:
        "Kalkulator zwrotu z inwestycji w nieruchomość. Nasz kalkulator pomaga oszacować zwrot z inwestycji dla wynajmu Twojego mieszkania, biorąc pod uwagę jego cenę zakupu. Uwzględnia on również czynniki takie jak przychód z wynajmu, podatki, i dodatkowe koszty utrzymania nieruchomości, aby pomóc Ci oszacować zyski. W zależności od wyniku kalkulatora, dowiesz się, czy Twój zwrot z inwestycji jest niski, średni, czy wysoki. Rentowność wynajmu zależy od wielu czynników, a nasz kalkulator pomoże Ci oszacować zwrot z inwestycji dla Twojej nieruchomości.",
      ROI: "Zwrot z inwestycji",
      Value: "Wartość inwestycji",
      PotentialAnalysis: "Analiza potencjału",
      IncomeSubtitle:
        "Obłożenie przy wynajęciu na {{nights}} dni w miesiącu za {{price}} PLN/noc",
      IncomeAnalysis: "Analiza dochodu",
      OccupancyDisclaimer:
        "Dowiedz się, jak Rentujemy zwiększa obłożenie wynajmów krótkoterminowych.",
      Taxes: "Podatki (VAT 8%)",
      Calculation: "Oblicz",
      TotalInvestmentValue: "Wartość całkowita inwestycji",
      ExpectedPricePerNight: "Oczekiwana cena za noc",
      Occupation: "Obłożenie",
      AdditionalRentalCosts:
        "Właściciel odpowiada za opłaty za mieszkanie, media i podatek PIT-28 (8% od przychodów).",
      CTA: "Umów się na bezpłatną konsultację",
      OwnerPayout: "ROI (Zwrot z inwestycji)",
      ManagementFeesCosts: "Prowizja Rentujemy",
      ApartmentSize: "Wielkość mieszkania",
      ReservationFeesCosts: "Opłaty za rezerwacje na portalach",
      Subtitle: "Prognoza finansowa dla inwestycji w '{{name}}'",
      MonthlyIncome: "Miesięczny dochód",
      AfterUtilityCosts: "Po odliczeniu kosztów czynszu i mediów",
      AfterManagementFeesCosts: "Po odliczeniu prowizji Rentujemy",
      AfterReservationPortalCosts:
        "Po odliczeniu kosztów platform rezerwacyjnych",
    },
    IncomeCalculator: {
      PayoutDisclaimer:
        "Otrzymane wyniki są jedynie szacunkowe i służą wyłącznie celom informacyjnym. Zgodnie z obowiązującymi przepisami prawa konsumenckiego, informujemy, że nie ponosimy odpowiedzialności za ewentualne szkody wynikłe z wykorzystania kalkulatora, ani za decyzje podjęte na podstawie wyników kalkulacji. Rekomendujemy dokładną analizę sytuacji oraz skonsultowanie się z ekspertem przed podjęciem jakichkolwiek decyzji biznesowych.",
      CheckOut: "Zobacz",
      IncomeDisclaimer:
        "Zarobki za noc to cena ustalona przez Rentujemy pomniejszona o opłatę serwisową portali rezerwacyjnych, opłaty prowizyjne Rentujemy oraz podatek VAT. Nie odejmujemy podatków od dochodu z wynajmu lub kosztów prowadzenia działalności. Faktyczna wysokość Twoich zarobków będzie zależeć od kilku czynników takich jak dostępność, cena czy zapotrzebowanie w Twojej okolicy. Wpływ na faktyczny zarobek mogą mieć także inne czynniki.",
      ApartmentStandard: "Standard apartamentu",
      NoOfRooms: "Ilość pokoi",
      Title: "Kalkulator przychodów z wynajmu krótkoterminowego",
      PricePerNight: "Cena wynajmu za noc",
      PricePerNightDisclaimer:
        "Dowiedz się jakie czynniki Rentujemy bierze pod uwagę przy ustalaniu ceny wynajmu.",
      OwnerSubtitle:
        "Oblicz, ile możesz zarobić na krótkoterminowym wynajmie swojego mieszkania z Rentujemy. Skorzystaj z naszego kalkulatora przychodów i sprawdź, jakie dochody przyniesie Ci wynajem krótkoterminowy. Dzięki Rentujemy zyskujesz pewność, że Twoje mieszkanie będzie zarabiało dla Ciebie jak najwięcej.",
      ROI: "Zwrot z inwestycji",
      Value: "Wartość",
      PotentialAnalysis: "Oszacowanie potencjału zarobków",
      IncomeSubtitle:
        "Obłożenie przy {{nights}} wynajętych dniach w miesiącu za {{price}}PLN/dobę",
      IncomeAnalysis: "Analiza zarobków",
      OccupancyDisclaimer:
        "Zapoznaj się z metodami, z jakimi Rentujemy zwiększa obłożenie krótkoterminowych wynajmów.",
      Taxes: "Podatki (VAT 8%)",
      Calculation: "Kalkulacja",
      TotalInvestmentValue: "Całkowita Wartość inwestycji",
      ExpectedPricePerNight: "Oczekiwana cena wynajmu za dobę",
      Occupation: "Obłożenie",
      AdditionalRentalCosts:
        "Od powyższej kwoty, właściciel musi odprowadzić podatek PIT-28 w wysokości 8%. Dodatkowo, właściciel jest odpowiedzialny za opłacenie miesięcznych opłat za mieszkanie, wodę, elektryczyność, media.",
      CTA: "Umów się na bezpłatną konsultację",
      OwnerPayout: "Wypłata dla właściciela",
      ManagementFeesCosts: "Prowizja Rentujemy",
      ApartmentSize: "Metraż apartamentu",
      ReservationFeesCosts: "Koszty portali rezerwacyjnych",
      Subtitle: "Prognoza finansowa dla inwestycji '{{name}}'",
      MonthlyIncome: "Miesięczny przychód",
      AfterUtilityCosts: "Po odliczeniu kosztów czynszu i mediów",
      AfterManagementFeesCosts: "Po odliczeniu kosztów zarządzania Rentujemy",
      AfterReservationPortalCosts:
        "Po odliczeniu kosztów platform rezerwacyjnych",
    },
    "Payout to investors": "Miesięczna wypłata do inwestorów",
    MyOffers: "Moje Oferty",
    "Moje Wypłaty": "Moje Wypłaty",
    Od: "Od:",
    Do: "Do:",
    "Całkowita kwota:": "Całkowita kwota:",
    All: "Wszystkie",
    Paid: "Zrealizowne",
    Planned: "Planowane",
    InvestObject: "Obiekt Inwestycji",
    Kontrakt: "Kontrakt",
    BankName: "Nazwa banku",
    ReceiverName: "Nazwa odbiorcy",
    NoActiveInvests: "Nie masz jeszcze aktywnych inwestycji.",
    NoInvestOffers: "Nie masz jeszcze żadnych ofert.",
    NoInvestPayouts: "Nie masz jeszcze żadnych wypłat.",
    GoToIncomeProjection: "Zobacz prognozę przychodu",
    NotConfirmed: "Nicht bestätigt",
    Oczekujący: "Oczekujący",
    Odrzucona: "Odrzucona",
    "Oferta wysłana": "Data wysłania",
    opcjonalne: "opcjonalnie",
    "Ostatnia aktualizacja": "Ostatnia aktualizacja",
    ParticipationInValueIncrease: "Udział we wzroście nieruchomości",
    Payout: {
      TotalAmount: "Całkowita Kwota",
      AvgPayout: "Ø Miesięczna Wypłata",
      Amount: "Kwota",
      FixedInterest: "Stałe",
      OwnerPayout: "Wypłata dla właściciela",
      WrongIBAN: "Proszę wprowadzić prawidłową wartość IBAN",
      MixedInterest: "Stałe + zmienne",
      Paid: "Przelano",
      Planned: "Zaplanowano",
      PlannedPaymentDate: "Planowana wypłata",
      PaidDate: "Data przelewu",
      Settlement: "Rozliczenie",
      SettlementStart: "Rozliczenia od",
      SettlementEnd: "Rozliczenia do",
      Principal: "Spłata",
      Status: "Status wypłaty",
      Type: "Typ",
      VariableInterest: "Zmienne oprocentowanie",
      IsSyncing: "Payouts data is synchronizing. Please wait.",
      IsFetching: "Fetching payouts data. Please wait.",
      Title: "Title",
    },
    PayoutAccount: {
      OwnerName: "Właściciel konta",
      AccountCurrency: "Waluta konta",
      ChooseAccountCurrency: "Wybierz walutę konta",
      BICInfo: "Wymagane tylko dla zagranicznych kont.",
      WrongOwnerName: "Wprowadź nazwę właściciela konta",
      NoAccounts: "Nie masz jeszcze kont do wypłat.",
      ContactSupport:
        "Aby edytować lub usunąć konto wypłat, skontaktuj się z nami pod adresem support@rentujemy.pl",
      AddAccount: "Dodaj nowe konto do wypłat",
    },
    "Pobierz wzór umowy pożyczki": "Pobierz wzór umowy pożyczki",
    Confirm: "Potwierdź",
    "Potwierdzam przelew": "Potwierdzam przelew",
    "Prześlij ofertę inwestycji": "Prześlij ofertę inwestycji",
    ShareOfProjectIncome: "Udział w dochodzie",
    "Status inwestycji": "Status inwestycji",
    "Status płatności": "Status płatności",
    "Teraz Inwestuję": "Teraz Inwestuję",
    "Tytuł przelewu": "Tytuł przelewu",
    "W weryfikacji": "W weryfikacji",
    "Wybierz inwestycje": "Wybierz kwotę inwestycji",
    Logout: "Wyloguj",
    Zaakceptowana: "Zaakceptowana",
    "Zapłać teraz": "Zapłać teraz",
    PurposePayment: "Tytuł przelewu",
    BeneficiaryName: "Nazwa odbiorcy",
    IBAN: "IBAN",
    BIC: "BIC",
    TransferAmount: "Kwota Przelewu",
    CreateContract: "Utwórz umowę",
    Admin: "Admin",
    Impressum: "Impresum",
    Home: "Home",
    Investments: "Inwestycje",
    PaymentStatus: {
      UnknownStatus: "Nieznane",
      Confirmed: "Potwierdzona",
      Declared: "Zadeklarowana",
      Pending: "Oczekująca",
    },
    UpdateOffer: "Update Offer",
    AddPayout: "Add Payout",
    Transferred: "Überwiesen",
    Auth: {
      EnterUsername: "Wypełnij email",
      EnterPassword: "Wypełnij hasło",
      EnterEmail: "Wypełnij email",
    },
    OwnerCockpitError: {
      SomeErrorCode: "Coś poszło nie tak, spróbuj ponownie później.",
      RefreshErrorCode: "Nie można odświeżyć, dane mogą być nieaktualne.",
    },
    Calendar: {
      Calendar: "Kalendarz",
      Today: "dziś",
    },
    Reservation: {
      Id: "Identyfikator rezerwacji",
      NumberOfGuests: "Ilość gości",
      CleaningFee: "Koszt sprzątania",
      ReservationTotal: "Wartość Rezerwacji",
      Details: "Details",
      Cancelled: "Odwołane",
      OwnerStay: "Pobyt właścicielski",
      OwnerReservation: "Rezerwacja właścicielska",
      IsSyncing:
        "Dane rezerwacyjne są synchronizowane. To może trochę potrwać.",
      IsSyncingCheckLater:
        "Dane o rezerwacjach są aktualnie synchronizowane z portalami rezerwacyjnymi. Proszę sprawdzić później.",
      NoData:
        "Nie ma jeszcze rezerwacji dla tego objektu. Sprawdź ponownie później.",
      NoCheckoutsInThisMonth: "Brak rezerwacji z wymeldowaniem na ten miesiąc.",
      OccupancyRate: "Ø obłożenie",
      AverageDailyRate: "Ø wypłata",
      AverageDailyPrice: "Ø cena wynajmu",
      AdditionalCostDisclaimer:
        "Informacje mają charakter informacyjny, jednak dokładamy wszelkich starań, by były jak najdokładniejsze. Wypłata jest ustalana na podstawie rozliczenia na koniec miesiąca.",
      Notes: "Notatki",
    },
    OwnerReservation: {
      Title: "Stwórz rezerwacje właścicielską",
      Notes:
        "Rezerwacje właścicielskie nie mogą kolidować z aktualnymi rezerwacjami oraz mogą wynosić maksymalnie 14 dni. Sama rezerwacja jest bezpłatna, jednak po kazdej rezerwacji zostanie wystawiona faktura za usługę sprzątania po rezerwacji. Rezerwacja musi zostać potwierdzona przez Rentujemy.",
      SendRequest: "Wyślij zapytanie",
      FailedMessage: "Rezerwacja nie mogła zostać stworzona.",
      SuccessMessage:
        "Twoja rezerwacja została wysłana. Jeśli nie koliduje z innymi rezerwacjami, wkrótce zostanie wyświetlona w kalendarzu rezerwacji. Należy jednak zauważyć, że może to potrwać do 30 minut, zanim Twoja rezerwacja pojawi się w kalendarzu.",
      PastDateError: "Daty muszą być w przyszłości.",
      CheckoutBeforeCheckinError:
        "Wymeldowanie nie moze być przed zameldowaniem.",
      ReservationDurationError: "Rezerwacja nie moze być dłuzsza niz 14 dni.",
      GuestFirstName: "Imię gościa",
      GuestLastName: "Nazwisko gościa",
      CheckinDate: "Data zameldowania",
      CheckoutDate: "Data wymeldowania",
    },
    Details: "Details",
    Yes: "Ja",
    SetStatus: "Set Status",
    Synchronize: "Sync",
    CustomerContracts: {
      NoContractsAssigned:
        "Nie masz jeszcze żadnych profilów nieruchomości w swoim panelu właściciela. Powinny pojawić się tutaj wkrótce. Prosimy o cierpliwość. Jeśli masz pytania lub potrzebujesz pomocy, skontaktuj się z nami. Dziękujemy za wyrozumiałość!",
      Loading: "Ładowanie danych kontraktu. Proszę czekać.",
    },
    GuestLogin: {
      ReservationId: "Identyfikator rezerwacji",
      GuestLastName: "Nazwisko gościa",
      Login: "Zaloguj",
    },
    ErrorPage: {
      SomethingWentWrong:
        "Przepraszamy, ale coś poszło nie tak po naszej stronie.",
      TryAgainLater:
        "Prosimy spróbować ponownie później. Jeśli problem będzie się powtarzał, prosimy o kontakt z naszym działem obsługi klienta.",
      ReturnToHomepage: "Wróć na stronę główną",
    },
  },
};
