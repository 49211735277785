import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import ExternalLink from "../../shared/components/ExternalLink";
import ExternalResourceLink from "../../shared/components/ExternalResourceLink";
import { chargesColumns } from "./chargesColumns";
import { ReportingCharge, ReportingCharges } from "../useReportingApi";

export default ({ items, sums }: ReportingCharges) => {
  const createTableRow = (item: Partial<ReportingCharge>, index: number) => (
    <TableRow key={index}>
      <TableCell textAlign="left">
        {item.pms_reservation_id && (
          <ExternalLink
            label={item.pms_reservation_id}
            href={`/?q=${item.pms_reservation_id}`}
          ></ExternalLink>
        )}
      </TableCell>

      <TableCell textAlign="left">{item.payment_date}</TableCell>

      <TableCell textAlign="left">{item.amount}</TableCell>

      <TableCell textAlign="left">{item.currency}</TableCell>

      <TableCell textAlign="left">{item.service_category}</TableCell>

      <TableCell textAlign="left">{item.description}</TableCell>

      <TableCell textAlign="left">{item.customer_name}</TableCell>

      <TableCell textAlign="left">
        <ExternalResourceLink
          href={`https://dashboard.stripe.com/payments/${item.payment_processing_service_id}`}
        ></ExternalResourceLink>
      </TableCell>
    </TableRow>
  );

  return (
    <Table size="small" caption="" highlightOnHover={false}>
      <TableHead>
        {chargesColumns.map((column, index) => (
          <TableCell key={`column-${index}`} as="th" textAlign="left">
            {column.label}
          </TableCell>
        ))}
        <TableRow></TableRow>

        {Object.keys(sums).map((sumKey) => (
          <TableRow key={"sums"} boxShadow={"small"}>
            <TableCell textAlign="left"></TableCell>

            <TableCell textAlign="left"></TableCell>

            <TableCell fontWeight={600} textAlign="left">
              {sums[sumKey]}
            </TableCell>
            <TableCell fontWeight={600} textAlign="left" colSpan={2}>
              {sumKey}
            </TableCell>

            <TableCell fontWeight={600} textAlign="left"></TableCell>
          </TableRow>
        ))}
      </TableHead>

      <TableBody>
        {items.map(createTableRow)}

        {items?.length === 0 && (
          <TableRow>
            <TableCell textAlign="center" colSpan={chargesColumns.length}>
              No Data
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
