import { API } from "@aws-amplify/api";
import { unmarshallDates } from "./common";
import moment from "moment";

export enum PayoutStatus {
  Planned = "Planned",
  Paid = "Paid",
}

export enum PayoutType {
  FIXED_INTEREST = "FixedInterest",
  VARIABLE_INTEREST = "VariableInterest",
  MIXED_INTEREST = "MixedInterest",
  OWNER_PAYOUT = "OwnerPayout",
  PRINCIPAL = "Principal",
}

export type IPayoutEntry = {
  id: number | string;
  user_id: string | number;
  amount: number;
  currency: string;
  type: PayoutType;
  status: PayoutStatus;
  settlement_start: Date;
  settlement_end: Date;
  planned_payment_date: Date;
  paid_date: Date | null;
  settlement_url: string;
  object_id: string;
  contract_id: string | null;
  payout_title: string;
  receiver_name: string;
  receiver_iban: string;
  receiver_bic: string;
  payout_notes: string | null;
  company_name: string | null;
  vat_id: string | null;
};

const listPayouts = async (
  status?: PayoutStatus,
  from?: Date,
  to?: Date,
  allUsers?: boolean
) =>
  (
    await API.get("coredata", `/payouts/list`, {
      queryStringParameters: {
        allUsers: allUsers ?? false,
        status: status ?? "",
        from: from ? moment(from).format("YYYY-MM-DD") : "",
        to: to ? moment(to).format("YYYY-MM-DD") : "",
      },
    })
  ).result.map((row: any) =>
    unmarshallDates(row, [
      "settlement_start",
      "settlement_end",
      "planned_payment_date",
      "paid_date",
    ])
  ) as IPayoutEntry[];

const setPayoutPaidDate = async (payoutId: string, paidDate: Date) => {
  await API.put("coredata", `/payouts/item`, {
    body: {
      id: payoutId,
      status: "Paid",
      paid_date: moment(paidDate).format("YYYY-MM-DD"),
    },
  });
};

export const useCoreData = () => {
  return { listPayouts, setPayoutPaidDate };
};
