import FileDropzone from "../../Docs/FileDropzone";

export default ({ onChange }: { onChange: () => void }) => {
  return (
    <FileDropzone
      inputContent={"Upload CSV file with Airbnb payouts."}
      onChange={onChange}
      url="/v1/automation/airbnb/payouts/upload"
      accept="text/csv"
    />
  );
};
