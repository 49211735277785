import { Flex, Heading } from "@aws-amplify/ui-react";
import moment from "moment-timezone";
import LoaderBox from "../shared/components/LoaderBox";
import { useTaskActivity } from "../shared/hooks/useTasksApi";
import TextMuted from "../shared/components/TextMuted";
import { MdComment } from "react-icons/md";

export default function TaskActivity({ taskId }: { taskId: string }) {
  // use "en" locale in admin pages
  moment.locale("en");
  const { data, loading } = useTaskActivity(taskId);

  return (
    <Flex direction={"column"} grow={1} overflow={"hidden"}>
      <Heading>
        <MdComment></MdComment> Activity
      </Heading>

      {loading && <LoaderBox></LoaderBox>}

      {data?.length === 0 && (
        <Flex
          direction="row"
          padding={80}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <TextMuted>No activity</TextMuted>
        </Flex>
      )}

      {data &&
        data.map((event) => (
          <Flex direction="column">
            <Flex>
              {event.username}
              <TextMuted>{event.event}</TextMuted>
            </Flex>
            <Flex>
              <TextMuted style={{ fontSize: 12 }}>
                {moment(event.timestamp).format("llll")}
              </TextMuted>
            </Flex>
          </Flex>
        ))}
    </Flex>
  );
}
