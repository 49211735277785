import { Modal } from "react-bootstrap";
import React from "react";

interface AdminPayoutPlanningDetailsProps {
  children: React.ReactElement | React.ReactElement[];
  title: string;
  onClose: () => void;
  size?: "xl";
}

export default ({
  children,
  title,
  onClose,
  size,
}: AdminPayoutPlanningDetailsProps) => {
  return (
    <Modal
      size={size}
      show={true}
      scrollable={true}
      onHide={onClose}
      onBackdropClick={onClose}
      onExit={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
