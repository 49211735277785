import { useCallback, useState } from "react";
import { UserDocument } from "../../Docs/UserDocument";
import { API } from "../utils/API";
import { downloadPdf } from "../utils/downloadPdf";
import { prepareQueryString, ReportTable } from "./common";
import { useApi } from "./useApi";

export const deleteDoc = async (fileKey: string): Promise<void> =>
  await API.delete(`/v1/admin/docs`, {
    body: {
      file_key: fileKey,
    },
  });

export const updateDoc = async (
  fileKey: string,
  body: Partial<UserDocument>
): Promise<void> =>
  await API.put(`/v1/admin/docs`, {
    body: {
      file_key: fileKey,
      ...body,
    },
  });

export const downloadDoc = async (fileKey: string): Promise<Blob> =>
  await API.download(
    `/v1/admin/docs/download${prepareQueryString({ fileKey })}`
  );

export const useDocs = () => {
  return useApi<ReportTable<UserDocument, undefined>, { query?: string }>(
    `/v1/admin/docs`
  );
};

export const useDocsDownload = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  const download = useCallback(
    (params: { fileKey: string }) => {
      setLoading(true);

      (async () => {
        try {
          const data = await downloadDoc(params.fileKey);

          downloadPdf(data, params.fileKey);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      })();
    },
    [setLoading, setError]
  );

  return { loading, error, download };
};
