import { Button, Flex } from "@aws-amplify/ui-react";

export default ({
  onSave,
  onCancel,
  disabled,
}: {
  disabled?: boolean;
  onSave: () => void;
  onCancel: () => void;
}) => {
  return (
    <Flex>
      <Button
        disabled={disabled}
        size="small"
        onClick={() => {
          onCancel();
        }}
      >
        Cancel
      </Button>
      <Button
        disabled={disabled}
        variation="primary"
        size="small"
        onClick={() => {
          onSave();
        }}
      >
        Save
      </Button>
    </Flex>
  );
};
