import { RTableColumn } from "../../shared/components/tables/TableColumn";
import { ReportingCoreReservation } from "../useReportingApi";

export const columns: RTableColumn<ReportingCoreReservation>[] = [
  {
    type: "Id",
    fieldKey: "hostaway_id",
    label: "Hostaway Reservation ID",
  },
  {
    fieldKey: "departure_date",
    label: "Departure date",
  },
  {
    label: "Rental Income",
    fieldKey: "rental_income",
  },
  {
    fieldKey: "total_tax",
    label: "Total Tax",
  },
  {
    fieldKey: "reservation_portal_fee",
    label: "Portal Fee",
  },
  {
    fieldKey: "pm_commission",
    label: "PM Commission",
  },
  {
    fieldKey: "owner_payout",
    label: "Owner Payout",
  },
  {
    fieldKey: "comments",
    label: "Comments",
  },
];
