import { Badge, useAuthenticator } from "@aws-amplify/ui-react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import Box from "../../components/Box";
import { clearAllCache } from "../../hooks/useCache";
import { useCurrentUser } from "../../hooks/useCurrentUser";

export default () => {
  const { signOut, user } = useAuthenticator((context) => [context.user]);
  const { t } = useTranslation();
  const { groups } = useCurrentUser();

  return (
    <Box
      style={{ textAlign: "right", flexGrow: 1, gap: 24 }}
      className="noprint"
    >
      <Badge marginRight={8}>{groups.join(", ")}</Badge>

      {!!user && !!signOut && (
        <Button
          variant="light"
          size="sm"
          onClick={() => {
            clearAllCache();
            signOut();
          }}
        >
          {t("Logout")}
        </Button>
      )}
    </Box>
  );
};
