import { Badge, Flex } from "@aws-amplify/ui-react";
import { Accordion } from "react-bootstrap";
import ShowJson from "../shared/components/ShowJson";
import GenericTable from "../shared/GenericTable";
import { useCurrentUser } from "../shared/hooks/useCurrentUser";
import { PropertyDetails } from "../shared/hooks/useProperties";
import { CoreReservation } from "../shared/types/core-reservation";
import FinanceFieldsValidation from "./FinanceFieldsValidation";

export default ({
  coreReservation,
}: {
  coreReservation: {
    reservation: CoreReservation;
    property: PropertyDetails<boolean>;
  };
}) => {
  const { isAdmin } = useCurrentUser();

  return (
    <Flex direction="column">
      <Flex paddingTop={16} direction={"column"}>
        <Flex>
          {coreReservation.reservation.locked && (
            <Badge variation="warning">Locked</Badge>
          )}
        </Flex>

        <FinanceFieldsValidation
          baseRate={Number(coreReservation.reservation.base_rate)}
          vat={Number(coreReservation.reservation.vat)}
          cityTax={Number(coreReservation.reservation.city_tax)}
          cleaningFee={Number(coreReservation.reservation.cleaning_fee) || 0}
          total={Number(coreReservation.reservation.total_price)}
          ownerPayout={Number(coreReservation.reservation.owner_payout) || 0}
          payoutRentalIncome={
            Number(coreReservation.reservation.rental_income) || 0
          }
          payoutTotalTax={Number(coreReservation.reservation.total_tax) || 0}
          payoutReservationPortalFee={
            Number(coreReservation.reservation.reservation_portal_fee) || 0
          }
          payoutPmCommission={
            Number(coreReservation.reservation.pm_commission) || 0
          }
        ></FinanceFieldsValidation>

        <Accordion defaultActiveKey="0" alwaysOpen={true}>
          <Accordion.Item eventKey={"1"}>
            <Accordion.Header>Core Financial Fields</Accordion.Header>
            <Accordion.Body>
              <GenericTable
                loading={false}
                data={{
                  columns: [
                    {
                      fieldKey: "concept",
                    },
                    {
                      fieldKey: "amount",
                    },
                  ],
                  sums: undefined,
                  items: [
                    {
                      concept: "host_channel_fee",
                      amount: coreReservation.reservation.host_channel_fee,
                    },
                    {
                      concept: "payment_service_processing_fee",
                      amount:
                        coreReservation.reservation
                          .payment_service_processing_fee,
                    },
                    {
                      concept: "ota_payment_processing_fee",
                      amount:
                        coreReservation.reservation.ota_payment_processing_fee,
                    },
                    {
                      concept: "cancellation_host_fee",
                      amount: coreReservation.reservation.cancellation_host_fee,
                    },
                    {
                      concept: "booking_com_cancellation_guest_fee",
                      amount:
                        coreReservation.reservation
                          .booking_com_cancellation_guest_fee,
                    },
                  ],
                }}
              ></GenericTable>
            </Accordion.Body>
          </Accordion.Item>

          {isAdmin && (
            <>
              <Accordion.Item eventKey={"3"}>
                <Accordion.Header>Core Reservation API Data</Accordion.Header>
                <Accordion.Body>
                  <ShowJson
                    obj={
                      coreReservation.reservation ||
                      "Unable to locate the reservation in core systems"
                    }
                  ></ShowJson>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={"4"}>
                <Accordion.Header>Core Property API Data</Accordion.Header>
                <Accordion.Body>
                  <ShowJson
                    obj={
                      coreReservation.property ||
                      "Unable to load the property in core system"
                    }
                  ></ShowJson>
                </Accordion.Body>
              </Accordion.Item>
            </>
          )}
        </Accordion>
      </Flex>
    </Flex>
  );
};
