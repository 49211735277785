import { Button, TableBody, TableCell, TableRow } from "@aws-amplify/ui-react";
import { Modal, Table } from "react-bootstrap";
import { UserDocType } from "../Docs/UserDocument";
import { updateDoc } from "../shared/hooks/useDocs";
import DocTypeAutocomplete from "./DocTypeAutocomplete";
import UserAutocomplete from "./UserAutocomplete";

export default ({
  data,
  actions,
  onClose,
  onUpdated,
}: {
  onUpdated: () => void;
  actions: { label: string; action: () => void }[];
  onClose: () => void;
  data: Record<string, string>;
}) => {
  return (
    <Modal show={true} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <TableBody>
            {Object.keys(data).map((dataKey) => {
              if (dataKey === "user_id") {
                return (
                  <TableRow key={dataKey}>
                    <TableCell>{dataKey}</TableCell>
                    <TableCell textAlign="right">
                      <UserAutocomplete
                        defaultValue={data[dataKey]}
                        onSave={async (userId) => {
                          await updateDoc(data["file_key"], {
                            user_id: userId,
                          });
                          onUpdated();
                        }}
                      ></UserAutocomplete>
                    </TableCell>
                  </TableRow>
                );
              }

              if (dataKey === "type") {
                return (
                  <TableRow key={dataKey}>
                    <TableCell>{dataKey}</TableCell>
                    <TableCell textAlign="right">
                      <DocTypeAutocomplete
                        defaultValue={data[dataKey] as UserDocType}
                        onSave={async (type) => {
                          await updateDoc(data["file_key"], {
                            type: type,
                          });
                          onUpdated();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              }
              return (
                <TableRow key={dataKey}>
                  <TableCell>{dataKey}</TableCell>
                  <TableCell textAlign="right">{data[dataKey]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        {actions.map((action, index) => (
          <Button key={"action" + index} onClick={action.action}>
            {action.label}
          </Button>
        ))}
      </Modal.Footer>
    </Modal>
  );
};
