import {
  Alert,
  Autocomplete,
  ComboBoxOption,
  Flex,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useLazyApi } from "../shared/hooks/useLazyApi";
import SaveCancelButtonGroup from "../shared/components/SaveCancelButtonGroup";
import { logger } from "../../logger";

export default ({
  onSave,
  defaultValue,
}: {
  onSave: (hostawayId: number) => Promise<void>;
  defaultValue: number | undefined;
}) => {
  const [value, setValue] = useState<number | undefined>(defaultValue);
  const [selectedValue, setSelectedValue] = useState<number | undefined>(
    defaultValue
  );
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [savingError, setSavingError] = useState<string | undefined>();

  const { data, load, loading } = useLazyApi<ComboBoxOption[], undefined>(
    "/v1/admin/pms/hostaway/ids"
  );

  useEffect(() => {
    if (isEditMode) {
      load();
    }
  }, [isEditMode, load]);

  return (
    <Flex grow={1} direction={"column"}>
      <Text>Hostaway ID</Text>

      {!isEditMode && (
        <Flex direction={"column"}>
          <Flex alignItems={"center"}>
            <TextField
              readOnly
              disabled
              labelHidden
              label
              value={value ? String(value) : "not assigned"}
              grow={1}
            />

            <MdEdit
              onClick={() => {
                setIsEditMode(true);
              }}
            ></MdEdit>
          </Flex>
        </Flex>
      )}

      {isEditMode && (
        <Flex direction={"column"}>
          <Flex>
            <Autocomplete
              width={"100%"}
              inputStyles={{ flex: 1 }}
              style={{ flexGrow: 1 }}
              grow={1}
              flex={1}
              label="Hostaway ID"
              defaultValue={value ? String(value) : ""}
              errorMessage={savingError}
              hasError={!!savingError}
              isLoading={loading}
              options={data || []}
              onSelect={(option) => {
                setValue(parseInt(option.id, 10));

                setSelectedValue(parseInt(option.id, 10));
              }}
              onClear={() => {}}
            />
          </Flex>

          <Flex>
            <Alert variation="info">Only unassigned listings are shown.</Alert>
          </Flex>

          <SaveCancelButtonGroup
            disabled={saving}
            onSave={async () => {
              setSaving(true);
              setSavingError(undefined);

              try {
                await onSave(Number(selectedValue));

                setIsEditMode(false);
              } catch (err) {
                logger.error(err);

                setSavingError(
                  `Error while saving: ${JSON.stringify((err as any)?.message)}`
                );
              } finally {
                setSaving(false);
              }
            }}
            onCancel={() => setIsEditMode(false)}
          ></SaveCancelButtonGroup>
        </Flex>
      )}
    </Flex>
  );
};
