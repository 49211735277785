import { RTableColumn } from "../../shared/components/tables/TableColumn";
import { ReportingCharge } from "../useReportingApi";

export const chargesColumns: RTableColumn<ReportingCharge>[] = [
  {
    label: "HA Reservation ID",
  },
  {
    label: "Date",
  },
  {
    label: "Amount",
  },
  {
    label: "Currency",
  },
  {
    label: "Category",
  },
  {
    label: "Description",
  },
  {
    label: "Customer Name",
  },
  {
    label: "Stripe Charge ID",
  },
];
