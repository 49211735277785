import { translations } from "@aws-amplify/ui-react";
import { I18n as AmplifyI18n } from "aws-amplify";
import i18n from "i18next";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import { logger } from "../logger";
import { en } from "./en";
import { de } from "./de";
import { errorMessagesPl, pl } from "./pl";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const DEFAULT_LANG = "pl";
const resources = { pl, en, de };

const initI18n = () => {
  AmplifyI18n.putVocabularies(translations);

  AmplifyI18n.putVocabularies({
    pl: {
      ...errorMessagesPl,
    },
  });

  // https://github.com/aws-amplify/amplify-ui/blob/de1c874f294a3b21cc9d7a97b310d2744d18b065/packages/ui/src/i18n/dictionaries/authenticator/de.ts
  // I18n.putVocabulariesForLanguage("pl");
  AmplifyI18n.setLanguage(DEFAULT_LANG);

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      fallbackLng: DEFAULT_LANG,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });

  i18n.on("languageChanged", (language) => {
    logger.debug(`Language changed: ${language}`);
    document.documentElement.setAttribute("lang", language);

    AmplifyI18n.setLanguage(language);

    moment.locale(language);
  });

  // The default language is also set here src/components/UserLanguageApplier.tsx
  moment.locale(DEFAULT_LANG);
};

initI18n();

export default {
  // using getter to reflect updated value
  get language() {
    return i18n.language;
  },
  changeLanguage: (lang: string) => {
    if (lang !== "en" && lang !== "pl" && lang !== "de") {
      logger.debug(`Unsupported Language ${lang}`);

      return;
    }

    i18n.changeLanguage(lang);
    moment.locale(lang);
  },
};
