import { useState } from "react";
import AddButton from "../shared/components/AddButton";
import AddTaskModal from "./AddTaskModal";

export default ({ onCreated }: { onCreated: (taskId: string) => void }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddButton
        label="Add Task"
        onClick={() => {
          setOpen(true);
        }}
      ></AddButton>

      {open && (
        <AddTaskModal
          onCreated={onCreated}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
