import moment from "moment";

export const getYearBounds = (year: string | number) => {
  const baseDate = moment(`${year}-01-01`);

  const start = baseDate.startOf("year").format("YYYY-MM-DD");
  const end = baseDate.endOf("year").format("YYYY-MM-DD");

  return { start, end };
};

export const getPeriodBounds = (
  year: string | number,
  month?: string | number
) => {
  if (!month) {
    return getYearBounds(year);
  }
  const baseDate = moment(`${year}-${month}`);

  const start = baseDate.startOf("month").format("YYYY-MM-DD");
  const end = baseDate.endOf("month").format("YYYY-MM-DD");

  return { start, end };
};
