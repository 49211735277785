import { useAuthenticator } from "@aws-amplify/ui-react";
import { useMemo } from "react";
import { UserDetails } from "./useUserData";
import { API } from "aws-amplify";
import { prepareHerokuRequest } from "./common";

export const useCurrentUser = () => {
  const { authStatus, user, signOut } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  return useMemo(() => {
    const groups = user?.getSignInUserSession()?.getIdToken().payload[
      "cognito:groups"
    ];

    const isMemberOfGroup = (group: string) => {
      try {
        return Boolean(groups.includes(group));
      } catch (_err) {
        return false;
      }
    };

    const cognitoId = user.getUsername();

    // We have two access levels now:
    // - Admin can see everything
    // - All Admin-like roles can see everything but payouts
    const isAdmin = isMemberOfGroup("Admin");
    const isCoordinator = isMemberOfGroup("Coordinator");
    const isCoordinatorOrMore = isCoordinator || isAdmin;
    const isAccounting = isMemberOfGroup("Accounting");
    const isService = isMemberOfGroup("Service");

    const canAccess = isAdmin || isCoordinator || isAccounting || isService;

    return {
      groups,
      cognitoUser: user,
      signOut,
      authStatus,
      cognitoId,
      isAdmin,
      isCoordinator,
      isService,
      isCoordinatorOrMore,
      isAccounting,
      canAccess,
    };
    // TODO fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, user]);
};

export const getCurrentUserData = async (): Promise<UserDetails> =>
  await API.get("herokuapi", `/v1/customer/user`, await prepareHerokuRequest());
