import {
  Alert,
  CheckboxField,
  Divider,
  Flex,
  Heading,
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import TextMuted from "../shared/components/TextMuted";
import {
  TaskTemplateRequirements,
  useTaskRequirementValueUpdate,
  TaskRequirementsValues,
} from "../shared/hooks/useTasksApi";
import { keyBy, mapValues } from "lodash";

export default function TaskRequirements({
  templateData,
  data,
  taskId,
  onChange,
  readonly,
}: {
  readonly: boolean;
  data?: TaskRequirementsValues;
  onChange: () => void;
  templateData: TaskTemplateRequirements;
  taskId: string;
}) {
  const [values, setValues] = useState<Record<number, boolean>>({});
  const { update, loading: updating } = useTaskRequirementValueUpdate(taskId);

  useEffect(() => {
    if (data) {
      setValues(
        mapValues(keyBy(data?.requirements || [], "requirement_id"), "value")
      );
    }
  }, [data, setValues]);

  return (
    <Flex direction={"column"}>
      <Flex direction={"column"}>{templateData?.name}</Flex>
      <Flex direction={"column"}>{templateData?.description}</Flex>

      {data && data.fullfilled < data.requirementsCount && (
        <Alert>
          You must fullfill all requirements before you can mark this task as
          done. {`${data.fullfilled} / ${data.requirementsCount}`}
        </Alert>
      )}

      {data && data.fullfilled >= data.requirementsCount && (
        <Alert variation="success">All requirements fullfilled.</Alert>
      )}

      {templateData.sections.map((section) => (
        <Flex direction={"column"}>
          <Flex direction={"column"}>
            <Heading level={5}>{section?.name}</Heading>

            <Divider></Divider>
          </Flex>

          {section.requirements.map((requirement) => (
            <Flex direction={"row"} gap={36} alignItems={"center"}>
              <Flex direction={"column"}>
                <TextMuted>{requirement.requirement_type}</TextMuted>
              </Flex>

              <Flex direction={"column"} alignItems={"center"}>
                <CheckboxField
                  size="large"
                  label={""}
                  disabled={updating || readonly}
                  name={`${requirement.id}`}
                  checked={!!values[requirement.id]}
                  value={String(!!values[requirement.id])}
                  onClick={async () => {
                    setValues({
                      ...values,
                      [requirement.id]: !values[requirement.id],
                    });

                    await update({
                      requirement_id: requirement.id,
                      value: !values[requirement.id],
                    });

                    onChange();
                  }}
                ></CheckboxField>
              </Flex>

              <Flex direction={"column"}>
                {requirement.actions.map((action) => (
                  <Flex direction={"row"}>
                    <Flex direction={"row"} alignItems={"center"}>
                      {action.name}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      ))}
    </Flex>
  );
}
