import { Flex, useBreakpointValue, Image } from "@aws-amplify/ui-react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export default ({ children }: PropsWithChildren) => {
  const isMatchingDesktop = useBreakpointValue({ base: false, large: true });
  const { t } = useTranslation();

  return (
    <Flex height="100vh" direction={{ base: "column", large: "row" }}>
      <Flex
        width={{ base: "100%", large: "50%" }}
        justifyContent="center"
        overflow="hidden"
      >
        {isMatchingDesktop ? (
          <Image
            src="/rentujemy_promo.jpg"
            width="100%"
            objectFit="cover"
            alt={t("RentujemyLogo")}
          />
        ) : (
          <Image
            src="/logo.svg"
            objectFit="fit"
            marginTop={150}
            paddingLeft={40}
            paddingRight={40}
            width={{
              base: "100%",
              small: "80%",
              medium: "60%",
            }}
            alt={t("RentujemyLogo")}
          />
        )}
      </Flex>
      <Flex
        width={{ base: "100%", large: "50%" }}
        justifyContent="center"
        padding={10}
      >
        {children}
      </Flex>
    </Flex>
  );
};
