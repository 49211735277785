import { Flex, Image, useBreakpointValue } from "@aws-amplify/ui-react";
import React from "react";
import MenuMobile from "./MenuMobile";
import MenuDesktop from "./MenuDesktop";
import { theme } from "../../../../theme";

export default () => {
  const isMatchingMobile = useBreakpointValue({ base: true, medium: false });

  return (
    <Flex
      style={{
        zIndex: 1000,
        borderBottomColor: theme.lines,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
      }}
      grow={1}
      position={{
        base: "sticky",
        medium: "static",
      }}
      width={{ base: "100%", medium: "auto" }}
      top={0}
      right={0}
      marginLeft="auto"
      marginRight="auto"
      maxWidth={{ base: "auto" }}
      padding={10}
      paddingTop={16}
      paddingBottom={16}
      backgroundColor={"#FFF"}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      marginBottom={16}
      boxShadow={{
        base: "0px 4px 12px 0px rgba(0, 0, 0, 0.2)",
        medium: "none",
      }}
    >
      <Image
        alt="Rentujemy Logo"
        src="/logo.svg"
        objectFit="initial"
        objectPosition="50% 50%"
        backgroundColor="initial"
        width="175px"
        opacity="100%"
      />

      {isMatchingMobile ? <MenuMobile /> : <MenuDesktop />}
    </Flex>
  );
};
