import {
  Alert,
  Badge,
  Button,
  Divider,
  Flex,
  Heading,
  Placeholder,
  TextField,
} from "@aws-amplify/ui-react";
import { debounce } from "lodash";
import moment from "moment";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  MdCalendarToday,
  MdCheckCircleOutline,
  MdEdit,
  MdOutlinePending,
  MdRefresh,
  MdWarning,
} from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { logger } from "../../logger";
import { theme } from "../../theme";
import Box from "../shared/components/Box";
import ExternalLink from "../shared/components/ExternalLink";
import Headline from "../shared/components/Headline";
import { getHostawayReservation } from "../shared/hooks/useAdminPmsHostawayApi";
import { Invoice, getInvoice } from "../shared/hooks/useCoordinationApi";
import { useCoreReservation } from "../shared/hooks/useCoreReservation";
import { useCurrentUser } from "../shared/hooks/useCurrentUser";
import { useSearch } from "../shared/hooks/useSearch";
import SearchResultHostawayData from "./SearchResultHostawayData";
import SearchResultReservation from "./SearchResultReservation";
import SearchResults from "./SearchResults";
import { getReservationUrl, isReservationCheckedIn } from "./hostaway.utils";
import { getGuestPortalUrl } from "./navigation.utils";
import { HAReservation } from "./types/reservation";
import { syncReservationFromHostawayToCore } from "./useSync";

export default () => {
  const [hostawayReservation, setHostawayReservation] = useState<
    HAReservation | undefined
  >();
  const { isAdmin, isAccounting } = useCurrentUser();
  const {
    data: searchResult,
    loading: searchLoading,
    refetch: searchRefetch,
  } = useSearch();
  const [invoice, setInvoice] = useState<Invoice | undefined>();
  const [errors, setErrors] = useState<string[] | undefined>();
  const [invoiceError, setInvoiceError] = useState<string>();
  const [inputError, setInputError] = useState<boolean>();
  const navigate = useNavigate();
  const [synching, setSynching] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: coreReservation,
    load: loadReservation,
    loading: coreReservationLoading,
    error: reservationError,
    clear: clearReservation,
  } = useCoreReservation();

  useEffect(() => {
    const arr = [];

    if (reservationError) {
      arr.push(`Could not load reservation: ${reservationError.message}`);
    }

    if (invoiceError) {
      arr.push(`Could not load reservation: ${invoiceError}`);
    }

    setErrors(arr);
  }, [reservationError, invoiceError]);

  const searchQuery = useMemo(() => {
    return searchParams.get("q");
  }, [searchParams]);

  const loadData = useMemo(
    () =>
      debounce(async (searchQuery: string | null) => {
        clearReservation();
        setInvoiceError(undefined);
        setInputError(false);
        setInvoice(undefined);
        setHostawayReservation(undefined);

        if (!searchQuery) {
          return;
        }

        const invoiceRegex = /^\d{4}\/\d{2}\/\d{1,3}$/;

        let searchType: "invoice" | "reservation" | "customer" | undefined;

        // reservation hostaway ID has 8 signs
        // extend for other types if needed
        if (String(Number(searchQuery)).length === 8) {
          searchType = "reservation";
        } else if (invoiceRegex.test(searchQuery)) {
          searchType = "invoice";
        } else if (typeof searchQuery === "string" && searchQuery.length > 4) {
          searchType = "customer";
        }

        if (!searchType) {
          setInputError(true);

          return;
        }

        logger.debug(`Search Type: ${searchType}`);

        let invoiceRes: Invoice;

        if (searchType === "customer") {
          searchRefetch({ query: searchQuery });

          return;
        }

        if (searchType === "invoice") {
          invoiceRes = await getInvoice(searchQuery);

          if (invoiceRes) {
            setInvoice(invoiceRes);
          } else {
            setInvoiceError(`Could not find invoice ${searchQuery}`);
          }
        }

        if (searchType === "reservation" || invoiceRes!) {
          const reservationID = invoiceRes!?.reservation_id || searchQuery;

          loadReservation({
            reservationId: reservationID,
          });

          // external systems
          try {
            const hostawayReservation = await getHostawayReservation(
              reservationID
            );

            setHostawayReservation(hostawayReservation);
          } catch (err) {
            logger.debug("Could not load hostawayReservation", err);
          }
        }
      }, 1000),
    [searchRefetch, clearReservation, loadReservation]
  );

  useEffect(() => {
    loadData(searchQuery);
  }, [loadData, searchQuery]);

  return (
    <>
      <Box className="container">
        <Box>
          <Box mb={40}>
            <Headline>Search</Headline>

            <TextField
              label=""
              placeholder={"28166198 or 2024/06/9 or Owner Name"}
              hasError={inputError}
              errorMessage="Wrong input format"
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setSearchParams({ ...searchParams, q: e.target.value });
              }}
              descriptiveText={
                "Search for Hostaway Reservation ID or Invoice Number or Owner Name"
              }
              value={searchQuery || ""}
            />
          </Box>

          {searchResult && !searchLoading && (
            <SearchResults results={searchResult}></SearchResults>
          )}

          {invoice && (
            <Flex direction={"column"}>
              <Heading variation="primary">Invoice Object</Heading>

              <Flex>
                <TextField
                  readOnly
                  disabled
                  label="Invoice Number"
                  value={invoice.invoice_number}
                />
              </Flex>

              <Flex>
                <TextField
                  readOnly
                  disabled
                  label="Reservation ID"
                  value={invoice.reservation_id}
                />
              </Flex>
              <Divider></Divider>
            </Flex>
          )}

          {!!errors?.length && (
            <Alert
              marginTop={5}
              variation="error"
              isDismissible={false}
              hasIcon={true}
              heading={`Errors`}
            >
              <Flex direction={"column"}>
                {errors.map((error) => (
                  <Flex>{error}</Flex>
                ))}
              </Flex>
            </Alert>
          )}

          {(coreReservationLoading || searchLoading || synching) && (
            <Flex marginTop={8} marginBottom={8} direction={"column"}>
              <Placeholder height={50}></Placeholder>
              <Placeholder height={50}></Placeholder>
              <Placeholder height={50}></Placeholder>
              <Placeholder height={50}></Placeholder>
            </Flex>
          )}

          {!coreReservationLoading && coreReservation && (
            <Flex direction={"column"} marginTop={16}>
              <Flex direction={"column"}>
                <Flex>
                  <Badge>Reservation</Badge>
                </Flex>
                <Flex alignItems={"center"}>
                  <MdCalendarToday></MdCalendarToday>

                  <Heading>
                    {coreReservation.property.name} /{" "}
                    {coreReservation.reservation.guest_name}{" "}
                  </Heading>
                </Flex>

                <Flex alignItems={"center"}>
                  <Flex>
                    <Badge variation="info">
                      {coreReservation.reservation.channel_name}
                    </Badge>
                  </Flex>
                  <Flex>
                    {moment(coreReservation.reservation.arrival_date).format(
                      "DD.MM.YYYY"
                    )}{" "}
                    -{" "}
                    {moment(coreReservation.reservation.departure_date).format(
                      "DD.MM.YYYY"
                    )}
                  </Flex>
                  <Flex>
                    Channel Reservation Id:
                    {coreReservation.reservation.channel_reference_id}
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction={"row"} overflow={"scroll"}>
                {hostawayReservation && (
                  <Flex alignItems={"center"}>
                    {isReservationCheckedIn(hostawayReservation) ? (
                      <>
                        <MdCheckCircleOutline
                          color={theme.success}
                          size={32}
                        ></MdCheckCircleOutline>{" "}
                        Checked-In
                      </>
                    ) : (
                      <>
                        <MdOutlinePending
                          color={theme.accentColor}
                          size={32}
                        ></MdOutlinePending>{" "}
                        Not Checked-In
                      </>
                    )}
                  </Flex>
                )}

                {coreReservation && (
                  <Flex alignItems={"center"}>
                    <Flex>
                      {coreReservation.reservation.status === "cancelled" && (
                        <Badge variation="error">Cancelled</Badge>
                      )}

                      {coreReservation.reservation.status === "cancelled" &&
                        moment(
                          coreReservation.reservation.cancellation_date
                        ).isAfter(coreReservation.reservation.arrival_date) && (
                          <Badge variation="error">
                            Cancelled after Arrival{" "}
                            {coreReservation.reservation.cancellation_date}
                          </Badge>
                        )}

                      {coreReservation.reservation.status === "ownerStay" && (
                        <Badge variation="info">OwnerStay</Badge>
                      )}

                      {hostawayReservation && (
                        <Badge
                          variation={
                            hostawayReservation.paymentStatus === "Paid"
                              ? "success"
                              : "error"
                          }
                        >{`Payment ${
                          hostawayReservation.paymentStatus || "Unpaid"
                        }`}</Badge>
                      )}

                      {moment().isSameOrAfter(
                        moment(coreReservation.reservation.arrival_date)
                      ) &&
                        moment().isSameOrBefore(
                          moment(coreReservation.reservation.departure_date)
                        ) && <Badge>Ongoing</Badge>}
                      {moment().isBefore(
                        moment(coreReservation.reservation.arrival_date)
                      ) && <Badge>Upcoming</Badge>}
                      {moment().isAfter(
                        moment(coreReservation.reservation.departure_date)
                      ) && <Badge variation="success">Past</Badge>}
                    </Flex>
                  </Flex>
                )}

                {coreReservation && (
                  <Flex>
                    <Button
                      disabled={coreReservationLoading || !isAdmin}
                      onClick={async () => {
                        try {
                          setSynching(true);
                          await syncReservationFromHostawayToCore(
                            coreReservation.reservation.hostaway_id
                          );
                        } catch (err) {
                          logger.error(err);

                          alert(
                            `Unable to sync from Hostaway API to Core System: ${
                              (err as Error).message
                            }`
                          );
                        } finally {
                          setSynching(false);
                        }
                      }}
                    >
                      <MdRefresh></MdRefresh>
                    </Button>

                    <Button
                      disabled={coreReservationLoading || !isAdmin}
                      onClick={async () => {
                        navigate(
                          `reservation/${coreReservation.reservation.hostaway_id}`
                        );
                      }}
                    >
                      <MdEdit></MdEdit>
                    </Button>
                  </Flex>
                )}
              </Flex>

              {!isAccounting && coreReservation && (
                <Flex alignItems={"center"} overflow={"scroll"}>
                  <ExternalLink
                    label={"PMS Reservation"}
                    href={getReservationUrl(
                      coreReservation.reservation.hostaway_id
                    )}
                  />

                  {coreReservation.reservation.channel_name === "partner" && (
                    <ExternalLink
                      href={"https://host.dtravel.com/reservations"}
                      label={"DTravel"}
                    />
                  )}

                  <ExternalLink
                    label={"Guest Portal"}
                    href={getGuestPortalUrl(
                      String(
                        coreReservation.reservation.hostaway_id ||
                          hostawayReservation?.id
                      )
                    )}
                  />

                  {hostawayReservation &&
                    hostawayReservation.channelName === "bookingcom" && (
                      <ExternalLink
                        label={"Reservation Booking"}
                        href={`https://admin.booking.com/hotel/hoteladmin/extranet_ng/manage/booking.html?hotel_id=${hostawayReservation.externalPropertyId}&lang=en&res_id=${hostawayReservation.channelReservationId}&extranet_search=1&tlc=1`}
                      />
                    )}

                  <ExternalLink
                    label={"Listing Airbnb"}
                    href={coreReservation.property.airbnb_url}
                  />

                  <ExternalLink
                    label={"Listing Google"}
                    href={coreReservation.property.google_url}
                  />

                  <>
                    {coreReservation.property.assigned_mgmt_contracts[0] ? (
                      <ExternalLink
                        label={"Owner Calendar"}
                        href={`https://invest.rentujemy.pl/owner-cockpit/mgmt/${coreReservation.property.assigned_mgmt_contracts[0]}`}
                      />
                    ) : (
                      <MdWarning color={theme.accentColor}>
                        No Contract
                      </MdWarning>
                    )}
                  </>
                  <ExternalLink
                    label={"Core Property"}
                    href={`/property/details?id=${coreReservation.property.internal_id}`}
                  />
                </Flex>
              )}
              <Divider></Divider>

              {coreReservation && (
                <SearchResultReservation
                  coreReservation={coreReservation}
                ></SearchResultReservation>
              )}

              {hostawayReservation && (
                <SearchResultHostawayData
                  hostawayReservation={hostawayReservation}
                ></SearchResultHostawayData>
              )}
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
};
