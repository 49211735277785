import { Button, Flex, TextField } from "@aws-amplify/ui-react";
import { useState } from "react";
import { logger } from "../../logger";
import { theme } from "../../theme";
import Modal from "../shared/components/Modal";
import { inviteNewServiceUser } from "../shared/hooks/useInviteNewUser";

export default ({ onClose }: { onClose: () => void }) => {
  const [email, setEmail] = useState<undefined | string>();
  const [firstName, setFirstName] = useState<undefined | string>();
  const [lastName, setLastName] = useState<undefined | string>();

  return (
    <>
      <Modal title={"Add User to Service Team"} onClose={onClose}>
        <Flex direction={"column"} gap={16}>
          <Flex direction={"column"} gap={0}>
            <TextField
              type="email"
              label={"E-mail"}
              value={email}
              onChange={(event) => {
                setEmail(event.currentTarget.value);
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              type="email"
              label={"First name"}
              value={firstName}
              onChange={(event) => {
                setFirstName(event.currentTarget.value);
              }}
            ></TextField>
          </Flex>

          <Flex direction={"column"} gap={0}>
            <TextField
              type="email"
              label={"Last name"}
              value={lastName}
              onChange={(event) => {
                setLastName(event.currentTarget.value);
              }}
            ></TextField>
          </Flex>
        </Flex>

        <Flex marginTop={32}>
          <Button
            onClick={async () => {
              if (email && firstName && lastName) {
                const result = await inviteNewServiceUser({
                  email,
                  firstName,
                  lastName,
                });

                logger.debug(result);

                onClose();
              }
            }}
            isDisabled={!email && !firstName && !lastName}
            variation="primary"
            backgroundColor={
              !email || !firstName || !lastName ? theme.errorColor : undefined
            }
            grow={1}
            disabled={!email || !firstName || !lastName}
          >
            Invite New User
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
