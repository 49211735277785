import { CoreReservation } from "../shared/types/core-reservation";
import { Payment } from "./payment";
import { API } from "../shared/utils/API";

export interface ReportingCoreReservation extends CoreReservation {
  vatOk: boolean;
  ok: boolean;
  comments: string;
  totalPriceOk: boolean;
  cleaningFeeOk: boolean;
}

export interface ReportingReservations {
  ok: boolean;
  count: number;
  sum: {
    cleaning_fee: number;
    vat: number;
    total_price: number;
    base_rate: number;
    city_tax: number;
    owner_payout: number;
    reservation_portal_fee: number;
    pm_commission: number;
    rental_income: number;
    total_tax: number;
  };
  items: ReportingCoreReservation[];
}

export interface ReportingCharge extends Payment {}

export interface ReportingCharges {
  sums: {
    [currencyAmountKey: string]: number;
  };
  items: Payment[];
}

export interface ConsolidatedSums {
  amountNetto: { [currency: string]: number };
  amountVAT: { [currency: string]: number };
  amountTotal: { [currency: string]: number };
  amountCityTax: { [currency: string]: number };
}

export interface ConsolidatedItem {
  refType: "reservation" | "charge";
  refId: string;
  recognisionDate: string; // YYYY-MM-DD
  amountTotal: number;
  amountNetto: number;
  amountVAT: number;
  amountCityTax: number;
  currency: string;
  ok: boolean;
}

export interface Report<Row, Sum> {
  items: Row[];
  sums: Sum;
  columns: { fieldKey: keyof Row; fieldType?: "id" }[];
}

export type ConsolidatedReport = Report<ConsolidatedItem, ConsolidatedSums>;
export type ChargesReport = Report<
  Payment,
  {
    [currencyAmountKey: string]: number;
  }
>;

export const getReportingReservations = async (
  start: string,
  end: string
): Promise<ReportingReservations> =>
  await API.get(`/v1/admin/reporting/reservations?start=${start}&end=${end}`);

export const getReportingCharges = async (
  start: string,
  end: string
): Promise<ChargesReport> =>
  await API.get(`/v1/admin/reporting/upsells?start=${start}&end=${end}`);

export const syncReportingPayments = async (
  start: string,
  end: string
): Promise<void> =>
  await API.get(`/v1/admin/reporting/upsells/sync?start=${start}&end=${end}`);

export const getReportingConsolidated = async (
  start: string,
  end: string,
  sort?: string,
  recognisionMethod?: string
): Promise<ConsolidatedReport> =>
  await API.get(
    `/v1/admin/reporting/consolidated?start=${start}&end=${end}` +
      (sort ? `&sort=${sort}` : "") +
      (recognisionMethod ? `&recognisionMethod=${recognisionMethod}` : "")
  );
