import { Flex, Heading } from "@aws-amplify/ui-react";
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button } from "react-bootstrap";
import { logger } from "../../../logger";
import { getCurrentUserData, useCurrentUser } from "../hooks/useCurrentUser";
import { UserDetails } from "../hooks/useUserData";

const UserProfileContext = createContext<{
  reloadUserProfile: () => void;
  userProfile: UserDetails | null;
}>({
  reloadUserProfile: () => {},
  userProfile: null,
});

UserProfileContext.displayName = "User Profile";

const useUserProfile = () => useContext(UserProfileContext);

const { Consumer, Provider } = UserProfileContext;

function UserProfileProvider({ children }: PropsWithChildren) {
  const { authStatus, cognitoId, canAccess, signOut } = useCurrentUser();

  const [userProfile, setUserProfile] = useState<UserDetails | null>(null);

  const loadUserProfile = useCallback(async () => {
    try {
      if (authStatus !== "authenticated") {
        setUserProfile(null);
        return;
      }

      setUserProfile(await getCurrentUserData());
    } catch (error) {
      logger.error(
        `Could not load user data for ID ${cognitoId}`,
        (error as Error).message
      );
    }
  }, [authStatus, cognitoId]);

  useEffect(() => {
    loadUserProfile();
  }, [loadUserProfile]);

  return canAccess ? (
    <Provider value={{ userProfile, reloadUserProfile: loadUserProfile }}>
      {children}
    </Provider>
  ) : (
    <Flex
      direction={"column"}
      grow={1}
      justifyContent={"center"}
      alignItems={"center"}
      padding={64}
    >
      <Flex
        grow={1}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
      >
        <Heading>No access</Heading>
      </Flex>
      <Button onClick={signOut}>Log Out</Button>
    </Flex>
  );
}

export { Consumer as UserProfileConsumer, UserProfileProvider, useUserProfile };
