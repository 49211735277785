import { Card, Flex, Heading, Text } from "@aws-amplify/ui-react";
import React from "react";

export type StatItem = { value: string | number; name: string };

export default ({ stats }: { stats: StatItem[] }) => {
  return (
    <Flex justifyContent={"flex-start"}>
      {stats.map((stat, index) => (
        <Card key={`stat-${index}`} variation="elevated" minWidth={120}>
          <Flex justifyContent={"center"} direction={"column"} basis={"auto"}>
            <Heading level={5}>{stat.value}</Heading>
            <Text>{stat.name}</Text>
          </Flex>
        </Card>
      ))}
    </Flex>
  );
};
