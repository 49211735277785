import { Alert, Flex } from "@aws-amplify/ui-react";
import Box from "../shared/components/Box";
import LoaderBox from "../shared/components/LoaderBox";
import GenericTablePage from "../shared/GenericTablePage";
import { UserDetails, useUsers } from "../shared/hooks/useUserData";
import GenericTable from "../shared/GenericTable";
import { columns } from "./columns";

export default () => {
  const { data, error, loading, refetch } = useUsers();

  return (
    <GenericTablePage title="Users" onSearch={(query) => refetch({ query })}>
      <Flex direction={"column"}>
        <Flex>{loading && <LoaderBox></LoaderBox>}</Flex>

        {error && (
          <Box ml={20} mr={20}>
            <Alert>{error.message}</Alert>
          </Box>
        )}

        {data && !loading && (
          <Flex grow={1} overflow={"scroll"}>
            <GenericTable
              loading={loading}
              data={{
                columns: columns,
                items: data,
                sums: undefined,
              }}
              detailsUrl={(item: UserDetails) =>
                `/users/details?userId=${item.id}`
              }
            ></GenericTable>
          </Flex>
        )}
      </Flex>
    </GenericTablePage>
  );
};
