import { CoreReservation } from "../types/core-reservation";
import { useLazyApi } from "./useLazyApi";
import { PropertyDetails } from "./useProperties";

type StringNumber = number | string;

export const useCoreReservation = () =>
  useLazyApi<
    { reservation: CoreReservation; property: PropertyDetails<boolean> },
    { reservationId: StringNumber }
  >(`/v1/admin/reservations`);
