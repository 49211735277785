import Box from "../shared/components/Box";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { logger } from "../../logger";
import { Contract, useAdminContracts } from "../shared/hooks/useAdminContracts";
import { Alert } from "@aws-amplify/ui-react";
import Headline from "../shared/components/Headline";

export default () => {
  const [contract, setContract] = useState<Contract>();
  const [error, setError] = useState<Error>();
  const [success, setSuccess] = useState<boolean>(false);

  const { contractType, contractId } = useParams();
  const { getContract } = useAdminContracts();

  useEffect(() => {
    const loadData = async () => {
      if (!contractId) {
        setError(new Error("Something went wrong"));
        logger.error("Something went wrong");
        return;
      }

      try {
        setContract(await getContract(contractType!, contractId));
      } catch (error) {
        logger.error(error);
        setError(error as Error);
      }
    };

    loadData();
  }, [contractType, contractId, getContract]);

  // const handleChange =
  //   <T extends "text" | "switch">(key: keyof Contract, type: T) =>
  //   (e: ChangeEvent<HTMLInputElement>): void => {
  //     if (contract) {
  //       setSuccess(false);
  //       setError(undefined);

  //       if (type === "switch") {
  //         setContract({ ...contract, [key]: e.target.checked });
  //       } else {
  //         setContract({ ...contract, [key]: e.target.value });
  //       }
  //     }
  //   };

  return (
    <Box>
      <Box mb={40}>
        <Headline>Contract Data</Headline>
      </Box>

      {contract && (
        <Box pl={20}>
          <pre>{JSON.stringify(contract, undefined, 8)}</pre>
        </Box>
      )}

      {error && (
        <Alert
          marginTop={5}
          variation="error"
          isDismissible={true}
          onDismiss={() => setError(undefined)}
          hasIcon={true}
          heading="Some error happended!"
        />
      )}
      {success && (
        <Alert
          marginTop={5}
          variation="success"
          isDismissible={true}
          onDismiss={() => setSuccess(false)}
          hasIcon={true}
          heading="Data saved successfully!"
        />
      )}
    </Box>
  );
};
