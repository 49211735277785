export function snakeToPascal(snakeStr: string): string {
  return snakeStr
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");
}

export function formatHeader(input: string | number): string {
  // Replace underscores with spaces
  const withSpaces = String(input).replace(/_/g, " ");

  // Convert camelCase to Pascal case with spaces
  const result = withSpaces.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter of each word
  return result
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
