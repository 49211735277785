import { Dispatch, SetStateAction } from "react";
import Box from "../../shared/components/Box";
import { PayoutStatus } from "../../shared/hooks/useCoreData";
import PayoutFilterTab from "../PayoutFilterTab";
import useAccountActiveYears from "../useAccountActiveYears";
import moment from "moment";
import { PropertyDetails } from "../../shared/hooks/useProperties";

interface PayoutFilterProps {
  hasInvestObjectFilter: boolean;
  startMonth: number;
  setStartMonth: Dispatch<React.SetStateAction<number>>;
  startYear: number;
  setStartYear: Dispatch<React.SetStateAction<number>>;
  endMonth: number;
  setEndMonth: Dispatch<React.SetStateAction<number>>;
  endYear: number;
  setEndYear: Dispatch<React.SetStateAction<number>>;
  setPayoutStatus: Dispatch<SetStateAction<PayoutStatus | undefined>>;
  investObjectId?: number;
  setInvestObjectId?: Dispatch<SetStateAction<number | undefined>>;
  investObjects?: PropertyDetails<boolean>[];
  handleInvestObjectsAutocompleteClick?: () => void;
}

export default ({
  hasInvestObjectFilter,
  startMonth,
  setStartMonth,
  endMonth,
  setEndMonth,
  startYear,
  setStartYear,
  endYear,
  setEndYear,
  investObjectId,
  setInvestObjectId,
  investObjects,
  handleInvestObjectsAutocompleteClick,
}: PayoutFilterProps) => {
  const { activeYears } = useAccountActiveYears();
  // use "en" locale in admin pages
  moment.locale("en");

  return (
    <Box mb={30}>
      <PayoutFilterTab
        hasInvestObjectFilter={hasInvestObjectFilter}
        startMonth={startMonth}
        setStartMonth={setStartMonth}
        startYear={startYear}
        setStartYear={setStartYear}
        endMonth={endMonth}
        setEndMonth={setEndMonth}
        endYear={endYear}
        setEndYear={setEndYear}
        activeYears={activeYears}
        investObjectId={investObjectId}
        setInvestObjectId={setInvestObjectId}
        investObjects={investObjects}
        handleInvestObjectsAutocompleteClick={
          handleInvestObjectsAutocompleteClick
        }
      />
    </Box>
  );
};
