import { useApi } from "./useApi";

export interface PropertyImage {
  url: string;
  created_at: string;
}

export const usePropertyImages = (propertyId: number) =>
  useApi<PropertyImage[], undefined>(
    `/v1/admin/coordination/property/${propertyId}/images`
  );
