import { MdCheckCircleOutline, MdOutlineWarning } from "react-icons/md";
import { theme } from "../../theme";

export default (item: { ok: boolean }) => {
  return (
    <>
      {item.ok && (
        <MdCheckCircleOutline
          size={20}
          color={theme.success}
        ></MdCheckCircleOutline>
      )}
      {!item.ok && (
        <MdOutlineWarning size={20} color={theme.errorColor}></MdOutlineWarning>
      )}
    </>
  );
};
