import { API } from "../utils/API";
import { prepareQueryString, unmarshallDates } from "./common";

export enum SortFlag {
  Ascending = "asc",
  Descending = "desc",
}

export enum JobStatus {
  Queued = "Queued",
  Running = "Running",
  Failed = "Failed",
  Succeeded = "Succeeded",
}

export enum JobType {
  SyncPayoutProposals = "SyncPayoutProposals",
}

export interface IBackgroundJob {
  id: number;
  type: JobType;
  queuedAt: Date;
  startedAt: Date;
  endedAt: Date;
  status: JobStatus;
  progress: string;
  resultMessage: string;
}

/**
 * Synchronize owner statement proposals from Hostaway.
 * You need to provide `from` and `to` parameters to select all proposals where start and end date of proposal is within the given range.
 *
 * @param from
 * @param to
 */
const getJobs = async (
  type?: JobType,
  status?: JobStatus,
  limit?: number,
  sort?: SortFlag
): Promise<IBackgroundJob[]> =>
  (
    await API.get(
      `/v1/admin/backend-jobs/manager${prepareQueryString({
        type,
        status,
        limit,
        sort,
      })}`
    )
  ).map((row: any) =>
    unmarshallDates(row, ["queuedAt", "startedAt", "endedAt"])
  );

export const useJobsApi = () => {
  return {
    getJobs,
  };
};
