import {
  Button,
  Flex,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from "@aws-amplify/ui-react";
import moment from "moment";
import React, { useCallback, useState } from "react";
import DownloadLink from "../../shared/components/DownloadLink";
import ExternalLink from "../../shared/components/ExternalLink";
import {
  IPayoutEntry,
  PayoutStatus,
  useCoreData,
} from "../../shared/hooks/useCoreData";
import { theme } from "../../../theme";
import { showTransferTitle } from "../../shared/utils/generateMassPaymentsFile";
import { humanizeId } from "../../shared/utils/humanizeId";
import { useTableFilter } from "../../shared/components/tables/useTableFiltering";
import AdminPayoutDetails from "./AdminPayoutDetails";
import { columns } from "./columns";

interface IUIPayoutEntry extends IPayoutEntry {
  isLoading?: boolean;
}

export default ({ items }: { items: IPayoutEntry[] }) => {
  // use "en" locale in admin pages
  moment.locale("en");
  const { rowData, sortDataFn, setRowData } = useTableFilter(
    items as IUIPayoutEntry[]
  );
  const [selectedRow, setSelectedRow] = useState<IUIPayoutEntry>();
  const { setPayoutPaidDate } = useCoreData();

  const setPaidDate = useCallback(
    async (proposal: IPayoutEntry, paidDate: Date) => {
      try {
        rowData.find((item) => item.id === proposal.id)!.isLoading = true;

        setRowData(rowData);

        await setPayoutPaidDate(String(proposal.id), paidDate);

        const newItems = rowData.map((item) => {
          if (item.id === proposal.id) {
            item.paid_date = paidDate;
            item.isLoading = false;
            item.status = PayoutStatus.Paid;
          }

          return item;
        });

        setRowData(newItems);
      } catch (error) {
        alert("Could not mark payout as paid");
      }
    },
    [setPayoutPaidDate, setRowData, rowData]
  );

  return (
    <Flex overflow={"hidden"}>
      <Table
        size="small"
        caption=""
        highlightOnHover={false}
        overflow={"auto"}
        flex={1}
      >
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={`column-${index}`}
                as="th"
                textAlign={column.textAlign || "center"}
                onClick={() =>
                  column.fieldKey
                    ? sortDataFn(column.fieldKey, column.type)
                    : undefined
                }
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((item, index) => (
            <TableRow
              key={item.id || index}
              backgroundColor={
                item.status === PayoutStatus.Planned && item.amount > 0
                  ? theme.errorColor
                  : "transparent"
              }
            >
              <TableCell>{item.id}</TableCell>

              <TableCell>
                <ExternalLink
                  label={humanizeId(String(item.user_id))}
                  href={`/users/details?userId=${item.user_id}`}
                ></ExternalLink>
              </TableCell>

              <TableCell>
                <ExternalLink
                  label={humanizeId(item.object_id)}
                  href={`/property/details?investObjectId=${item.object_id}`}
                ></ExternalLink>
              </TableCell>
              <TableCell fontWeight={"bolder"}>{item.vat_id}</TableCell>
              <TableCell>
                {moment(item.settlement_start).format("DD.MM.YYYY")}
              </TableCell>
              <TableCell>
                {moment(item.settlement_end).format("DD.MM.YYYY")}
              </TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>
                {item.amount}
                <br />
                {item.currency}
              </TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>
                {moment(item.planned_payment_date).format("DD.MM.YYYY")}
              </TableCell>
              <TableCell>
                {item.paid_date
                  ? moment(item.paid_date).format("DD.MM.YYYY")
                  : "-"}
              </TableCell>
              <TableCell>
                {item.settlement_url && (
                  <DownloadLink
                    label={"Download"}
                    href={item.settlement_url}
                  ></DownloadLink>
                )}
              </TableCell>
              <TableCell>{item.receiver_name}</TableCell>
              <TableCell>
                <Text fontSize={12}>{item.receiver_iban}</Text>
              </TableCell>
              <TableCell>
                <Text fontSize={12}>
                  {showTransferTitle(item).split("|").join("\n")}
                </Text>
              </TableCell>
              <TableCell>
                {!item.paid_date && item.amount > 0 ? (
                  <Button
                    isLoading={item.isLoading}
                    size="small"
                    disabled={item.isLoading || selectedRow?.id === item.id}
                    variation={!!item.paid_date ? undefined : "primary"}
                    onClick={() => setSelectedRow(item)}
                  >
                    Mark as paid
                  </Button>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedRow && (
        <AdminPayoutDetails
          setPaidDate={setPaidDate}
          handleClose={() => {
            setSelectedRow(undefined);
          }}
          proposal={selectedRow}
        />
      )}
    </Flex>
  );
};
