import { Autocomplete, Flex, TextField } from "@aws-amplify/ui-react";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { logger } from "../../logger";
import SaveCancelButtonGroup from "../shared/components/SaveCancelButtonGroup";
import { UserDocType } from "../Docs/UserDocument";

export default ({
  onSave,
  defaultValue,
}: {
  onSave: (type: UserDocType) => Promise<void>;
  defaultValue: UserDocType | undefined;
}) => {
  const [value, setValue] = useState<string | undefined>(defaultValue);
  const [selectedValue, setSelectedValue] = useState<UserDocType | undefined>(
    defaultValue
  );
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [savingError, setSavingError] = useState<string | undefined>();

  return (
    <Flex grow={1} direction={"column"}>
      {!isEditMode && (
        <Flex direction={"column"}>
          <Flex alignItems={"center"}>
            <TextField readOnly disabled label="" value={value} grow={1} />

            <MdEdit
              onClick={() => {
                setIsEditMode(true);
              }}
            ></MdEdit>
          </Flex>
        </Flex>
      )}

      {isEditMode && (
        <Flex direction={"column"}>
          <Flex>
            {
              <Autocomplete
                width={"100%"}
                inputStyles={{ flex: 1 }}
                style={{ flexGrow: 1 }}
                grow={1}
                flex={1}
                label="USER ID"
                defaultValue={value}
                errorMessage={savingError}
                hasError={!!savingError}
                options={[
                  {
                    id: "contract",
                    label: `Contract`,
                  },
                  {
                    id: "handover",
                    label: `Handover`,
                  },
                ]}
                onSelect={(option) => {
                  setValue(option.id);

                  setSelectedValue(option.id as UserDocType);
                }}
                onClear={() => {}}
              />
            }
          </Flex>

          <SaveCancelButtonGroup
            disabled={saving}
            onSave={async () => {
              setSaving(true);
              setSavingError(undefined);

              try {
                await onSave(selectedValue as UserDocType);

                setIsEditMode(false);
              } catch (err) {
                logger.error(err);

                setSavingError(
                  `Error while saving: ${JSON.stringify((err as any)?.message)}`
                );
              } finally {
                setSaving(false);
              }
            }}
            onCancel={() => setIsEditMode(false)}
          ></SaveCancelButtonGroup>
        </Flex>
      )}
    </Flex>
  );
};
