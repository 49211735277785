import { Button, Flex } from "@aws-amplify/ui-react";
import Loader from "../../shared/components/Loader";
import { useDestinations } from "../../shared/hooks/useDestinations";

export default function CitySelector({
  selectedCity,
  setSelectedCity,
}: {
  selectedCity: string | undefined;
  setSelectedCity: (city: string | undefined) => void;
}) {
  const { data, loading } = useDestinations();

  return (
    <Flex direction="row">
      {loading && <Loader></Loader>}

      {!loading &&
        data?.destinations.map((option, index) => (
          <Button
            key={option.id}
            onClick={() => {
              if (selectedCity === option.id) {
                return setSelectedCity(undefined);
              }

              setSelectedCity(option.id);
            }}
            variation={option.id === selectedCity ? "primary" : undefined}
          >
            {option.label}
          </Button>
        ))}
    </Flex>
  );
}
