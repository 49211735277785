import { useEffect } from "react";
import GenericTable from "../shared/GenericTable";
import GenericTablePage from "../shared/GenericTablePage";
import { useTasksReport } from "../shared/hooks/useTasksApi";
import { useUserProfile } from "../shared/components/UserProfileProvider";
import { Button, Flex } from "@aws-amplify/ui-react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { getPeriodBounds } from "../shared/utils/getPeriodBounds";
import MonthYearFilters from "../shared/components/MonthYearFilters";
import DownloadReportButton from "../Reporting/DownloadReportButton";
import Stats from "../shared/components/Stats";
import { MdPrint } from "react-icons/md";

export default () => {
  const { loading, data, load } = useTasksReport();
  const { userProfile } = useUserProfile();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const year = searchParams.get("year")
      ? Number(searchParams.get("year"))
      : undefined;
    const month = searchParams.get("month")
      ? Number(searchParams.get("month"))
      : undefined;

    if (!year || (!year && !month)) {
      setSearchParams({
        year: String(moment().subtract(1, "months").year()),
        month: String(moment().month() + 1),
      });

      return;
    }

    if (userProfile) {
      const { start, end } = getPeriodBounds(year, month);

      load({ userId: userProfile.id, start, end });
    }
  }, [userProfile, searchParams, load, setSearchParams]);

  return (
    <GenericTablePage id={"printable-report"} title={"Tasks report"}>
      <Flex grow={1} padding={1}>
        <MonthYearFilters loading={loading}></MonthYearFilters>

        <Flex direction={"column"} justifyContent={"center"}>
          <Flex direction="row">
            <Button
              onClick={() => {
                window.print();

                return;
              }}
            >
              <MdPrint></MdPrint>
            </Button>
            <DownloadReportButton
              filename={
                searchParams.get("month")
                  ? `${searchParams.get("year")}-${Number(
                      searchParams.get("month")
                    )}-rentujemy-tasks-${moment().format("YYYY-MM-DD HHmm")}`
                  : `${searchParams.get(
                      "year"
                    )}-rentujemy-tasks-${moment().format("YYYY-MM-DD HHmm")}`
              }
              data={data}
            ></DownloadReportButton>
          </Flex>
        </Flex>
      </Flex>

      {data && (
        <Flex>
          <Flex overflow={"scroll"} padding={1}>
            <Stats stats={data.stats} />
          </Flex>
        </Flex>
      )}
      <GenericTable data={data} loading={loading}></GenericTable>
    </GenericTablePage>
  );
};
