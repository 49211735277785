import { Button, Flex } from "@aws-amplify/ui-react";
import moment, { Moment } from "moment";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

export default function DaySelector({
  currentDate,
  setDate,
}: {
  currentDate: Moment;
  setDate: (date: Moment) => void;
}) {
  const previous = async () => {
    setDate(moment(currentDate).subtract(1, "day"));
  };

  const next = async () => {
    setDate(moment(currentDate).add(1, "day"));
  };

  return (
    <Flex>
      <Button onClick={previous} size="small">
        <MdArrowBackIos />
      </Button>
      {currentDate.format("dddd") + " " + currentDate.format("YYYY-MM-DD")}
      <Button onClick={next} size="small">
        <MdArrowForwardIos />
      </Button>
    </Flex>
  );
}
