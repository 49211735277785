import { ColumnType } from "./ColumnType";
import { SortConfig, SortDirection } from "./SortConfig";

// Function to sort data
export const sortData = <T>(
  sortConfig: SortConfig<T>,
  items: T[],
  key: keyof T,
  type?: ColumnType
): {
  sortedData: T[];
  sortConfig: SortConfig<T>;
} => {
  let direction: SortDirection = "ascending";

  if (sortConfig.key === key && sortConfig.direction === "ascending") {
    direction = "descending";
  }

  if (!key) {
    return { sortedData: items, sortConfig: { key, direction } };
  }

  const sortedData = [...items].sort((a, b) => {
    if (a[key] && b[key]) {
      const valueA = type === "Number" ? Number(a[key]) : (a[key] as string);
      const valueB = type === "Number" ? Number(b[key]) : (b[key] as string);

      if (valueA < valueB) {
        return direction === "ascending" ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === "ascending" ? 1 : -1;
      }
    }

    return 0;
  });

  return { sortedData, sortConfig: { key, direction, type } };
};
