import {
  Alert,
  Badge,
  Card,
  Flex,
  Placeholder,
  SelectField,
  Text,
} from "@aws-amplify/ui-react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useAccountActiveYears from "../../Payouts/useAccountActiveYears";
import Headline from "../../shared/components/Headline";
import DownloadReportButton from "../DownloadReportButton";
import {
  ReportingReservations,
  getReportingReservations,
} from "../useReportingApi";
import ReportingReservationsTable from "./ReportingReservationsTable";

export default () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [reservations, setReservations] = useState<ReportingReservations>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [month, setMonth] = useState<number>(
    parseInt(searchParams.get("month")!)
      ? parseInt(searchParams.get("month")!) - 1
      : moment().month()
  );
  const [year, setYear] = useState<number>(
    parseInt(searchParams.get("year")!) || moment().subtract(1, "months").year()
  );

  const load = useCallback(
    async (year: number, month: number) => {
      setLoading(true);

      try {
        const baseDate = moment(`${year}-${month + 1}`);

        const data = await getReportingReservations(
          baseDate.startOf("month").format("YYYY-MM-DD"),
          baseDate.endOf("month").format("YYYY-MM-DD")
        );

        setReservations(data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError]
  );

  useEffect(() => {
    setSearchParams({ year: String(year), month: String(month + 1) });
    load(year, month);
    // TODO fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, load]);

  const { activeYears } = useAccountActiveYears();

  return (
    <Flex direction={"column"} grow={1}>
      <Flex direction="column">
        <Headline>Reporting</Headline>
        <Text>
          Revenue Recognition for reservations without separate charges
        </Text>
      </Flex>

      <Flex direction={"column"}>
        <Card variation="elevated">
          <Flex direction="column" grow={1}>
            <Flex direction="row" gap={50} justifyContent={"space-between"}>
              <Flex direction={"column"} gap={8}>
                <Text textAlign={"flex-start"}>Month</Text>
                <Flex gap={5} alignItems="center">
                  <SelectField
                    labelHidden={true}
                    label=""
                    size="small"
                    defaultValue={String(month)}
                    disabled={loading}
                    onChange={(e) => {
                      setMonth(parseInt(e.target.value));
                    }}
                  >
                    {moment.months().map((monthLabel, index) => (
                      <option key={monthLabel} value={index}>
                        {monthLabel}
                      </option>
                    ))}
                  </SelectField>
                  <SelectField
                    labelHidden={true}
                    label=""
                    size="small"
                    defaultValue={String(year)}
                    disabled={loading}
                    onChange={(e) => {
                      setYear(parseInt(e.target.value));
                    }}
                  >
                    {activeYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </SelectField>
                </Flex>
              </Flex>

              <Flex direction={"column"} justifyContent={"center"}>
                <Flex direction="row">
                  <Badge>by departure</Badge>

                  <DownloadReportButton
                    data={reservations}
                    filename={`${year}-${
                      month + 1
                    }-reservations-${moment().format("YYYY-MM-DD HHmm")}`}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        {error && (
          <Alert
            marginTop={5}
            variation="error"
            isDismissible={true}
            onDismiss={() => {
              setError(undefined);
            }}
          >
            {error.message}
          </Alert>
        )}

        {loading && (
          <Flex direction={"column"} grow={1} gap={12}>
            <Placeholder height={50}></Placeholder>
            <Placeholder height={50}></Placeholder>
            <Placeholder height={50}></Placeholder>
            <Placeholder height={50}></Placeholder>
          </Flex>
        )}

        {!loading && !!reservations && (
          <Flex>
            <ReportingReservationsTable
              sum={reservations.sum}
              items={reservations.items}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
