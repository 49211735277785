import { Flex, Image, Text } from "@aws-amplify/ui-react";
import moment from "moment";
import { PropertyImage } from "../shared/hooks/usePropertyImages";
import FsLightbox from "fslightbox-react";
import { useMemo, useState } from "react";

export default ({ images }: { images: PropertyImage[] }) => {
  const [selectedImage, setSelected] = useState<string | undefined>(undefined);

  const urls = useMemo(() => {
    return images.map((image) => image.url);
  }, [images]);

  return (
    <Flex direction="row" gap="20px" wrap="wrap" justifyContent="center">
      {images.map((image, index) => (
        <Flex
          key={index}
          boxShadow={"medium"}
          direction="column"
          onClick={() => {
            setSelected(image.url);
          }}
        >
          <Image
            height={250}
            width={250}
            alt={"Property Image"}
            src={image.url}
            objectFit={"contain"}
          ></Image>
          <Text textAlign={"center"}>
            {moment(image.created_at).format("llll")}
          </Text>
        </Flex>
      ))}

      <FsLightbox
        onClose={() => {
          setSelected(undefined);
        }}
        toggler={!!selectedImage}
        sources={urls}
        source={selectedImage}
      />
    </Flex>
  );
};
