import moment from "moment";
import { useState, useCallback } from "react";
import {
  PropertyDetails,
  useProperties,
} from "../../shared/hooks/useProperties";
import { logger } from "../../../logger";
import { PayoutStatus } from "../../shared/hooks/useCoreData";

// TODO Refactor to React Context
export default (userId?: string) => {
  const [startMonth, setStartMonth] = useState(
    moment().subtract(1, "month").month()
  );
  const [startYear, setStartYear] = useState(
    moment().subtract(0, "year").year()
  );
  const [endMonth, setEndMonth] = useState(moment().month());
  const [endYear, setEndYear] = useState(moment().year());
  const [payoutStatus, setPayoutStatus] = useState<PayoutStatus>();
  const [investContractId] = useState("");
  const [investObjectId, setInvestObjectId] = useState<number | undefined>(
    undefined
  );
  const [accountName, setAccountName] = useState<string | undefined>(undefined);
  const [investObjects, setInvestObjects] =
    useState<PropertyDetails<boolean>[]>();
  const { getProperties } = useProperties();

  const handleInvestObjectsAutocompleteClick = useCallback(() => {
    const load = async () => {
      try {
        const items = await getProperties();

        setInvestObjects(items);
      } catch (error) {
        logger.error(error);
      }
    };

    load();
  }, [getProperties]);

  return {
    accountName,
    setAccountName,
    startMonth,
    setStartMonth,
    endMonth,
    setEndMonth,
    startYear,
    setStartYear,
    endYear,
    setEndYear,
    payoutStatus,
    setPayoutStatus,
    investObjectId,
    setInvestObjectId,
    investObjects,
    handleInvestObjectsAutocompleteClick,
    investContractId,
  };
};
