import React from "react";
import Autocomplete from "./Autocomplete";
import { CoordinationTaskType } from "../shared/hooks/core-coordination-task";

export default ({
  onSelected,
  label,
  defaultValue,
}: {
  onSelected: (type: CoordinationTaskType) => Promise<void>;
  defaultValue?: string;
  label?: string;
}) => {
  return (
    <Autocomplete
      defaultLabel={defaultValue}
      onSelected={async (option) => {
        await onSelected(option.id as CoordinationTaskType);
      }}
      isLoading={false}
      onOptionsRequested={() => void 0}
      options={
        [
          {
            id: CoordinationTaskType.Cleaning,
            label: `Cleaning`,
          },
          {
            id: CoordinationTaskType.Delivery,
            label: `Delivery`,
          },
          {
            id: CoordinationTaskType.Inspection,
            label: `Inspection`,
          },
          {
            id: CoordinationTaskType.Maintenance,
            label: `Maintenance`,
          },
        ] as { id: CoordinationTaskType; label: string }[]
      }
      label={label || "Department"}
    ></Autocomplete>
  );
};
