import { Badge, Flex } from "@aws-amplify/ui-react";
import { MdInfo } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function BadgeField({
  label,
  noEntriesText = "No entries",
  badges,
}: {
  label: string;
  noEntriesText?: string;
  badges: { displayValue: string; link?: string }[];
}) {
  const navigate = useNavigate();

  return (
    <>
      {label}
      <Flex
        direction={"row"}
        border={"1px solid var(--amplify-components-fieldcontrol-border-color)"}
        borderRadius={"var(--amplify-components-fieldcontrol-border-radius)"}
        padding={6}
        alignItems={"center"}
        minHeight={42}
        marginTop={-10}
      >
        <MdInfo style={{ marginLeft: 10 }} />
        {badges.length === 0 && noEntriesText}
        {badges.map((item, index) => (
          <Badge
            key={`${item.displayValue}-${index}`}
            onClick={() => void (item.link && navigate(item.link))}
            style={{
              cursor: item.link ? "pointer" : "unset",
            }}
          >
            {item.displayValue}
          </Badge>
        ))}
      </Flex>
    </>
  );
}
