import { Flex } from "@aws-amplify/ui-react";
import moment from "moment";
import DatePicker from "react-date-picker";

export default ({
  onChange,
  value,
}: {
  value: string | undefined | null;
  onChange: (value: string | undefined) => void;
}) => {
  return (
    <Flex>
      <DatePicker
        minDate={new Date()}
        value={value}
        returnValue="start"
        onChange={(date) => {
          if (!date) {
            onChange(undefined);
          }
          onChange(moment(date?.toString()).format("YYYY-MM-DD"));
        }}
      />
    </Flex>
  );
};
