import { Flex } from "@aws-amplify/ui-react";
import { PropsWithChildren } from "react";

import { useCurrentUser } from "../shared/hooks/useCurrentUser";
import Redirect from "../shared/components/Redirect";

export default ({
  children,
  forwardCoordinatorTo,
  forwardServiceTo,
  shouldBeAdmin,
  shouldBeService,
  shouldBeAccounting,
  shouldBeCoordinator,
}: PropsWithChildren<{
  forwardCoordinatorTo?: string;
  forwardServiceTo?: string;
  shouldBeAdmin?: boolean;
  shouldBeService?: boolean;
  shouldBeAccounting?: boolean;
  shouldBeCoordinator?: boolean;
}>) => {
  const { isAdmin, isService, isAccounting, isCoordinator } = useCurrentUser();

  let canAccess = false;

  if (isAdmin) {
    canAccess = true;
  }

  if (isCoordinator && shouldBeCoordinator) {
    canAccess = true;
  }

  if (isAccounting && shouldBeAccounting) {
    canAccess = true;
  }

  if (isService && shouldBeService) {
    canAccess = true;
  }

  if (!canAccess && isCoordinator && forwardCoordinatorTo) {
    return <Redirect to={forwardCoordinatorTo} paramNames={[]} />;
  }

  if (!canAccess && isService && forwardServiceTo) {
    return <Redirect to={forwardServiceTo} paramNames={[]} />;
  }

  if (!canAccess) {
    return <Flex>No Access.</Flex>;
  }

  return <>{children}</>;
};
