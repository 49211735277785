import { useCallback } from "react";
import { useContractsLazyUsers } from "../shared/hooks/useUserData";
import Autocomplete from "./Autocomplete";

export default ({
  onSave,
  label,
  defaultValue,
}: {
  onSave: (userId: number) => Promise<void>;
  defaultValue?: string;
  label?: string;
}) => {
  const { data, load, loading } = useContractsLazyUsers();

  const handleSearch = useCallback(
    (query: string | undefined) => {
      load({ query: query || "" });
    },
    [load]
  );

  return (
    <Autocomplete
      defaultLabel={defaultValue}
      onSelected={async (option) => {
        await onSave(Number(option.id));
      }}
      isLoading={loading}
      onOptionsRequested={handleSearch}
      options={
        data?.map((item) => {
          return {
            id: String(item.id),
            label: `${item.first_name} ${item.last_name} - ${item.email}`,
          };
        }) || []
      }
      label={label || "User"}
    ></Autocomplete>
  );
};
