import React from "react";

import { Link } from "@aws-amplify/ui-react";

import { theme } from "../../../theme";
import { MdCallMade } from "react-icons/md";

export default (props: { href: string }) => (
  <Link
    color={theme.primaryColor}
    isExternal={true}
    href={props.href}
    target="_blank"
  >
    <MdCallMade></MdCallMade>
  </Link>
);
