import { useCallback, useState } from "react";
import { ApiHook } from "../shared/hooks/common";
import { ReportingReservations } from "./useReportingApi";
import { API } from "../shared/utils/API";

export const getReportingPayouts = async (
  start: string,
  end: string
): Promise<ReportingReservations> =>
  await API.get(`/v1/admin/reporting/payouts?start=${start}&end=${end}`);

export const useReportingPayoutsApi: ApiHook<
  ReportingReservations,
  { start: string; end: string }
> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ReportingReservations>();
  const [error, setError] = useState<unknown>();

  const refetch = useCallback(
    (params: { start: string; end: string }) => {
      setLoading(true);

      (async () => {
        try {
          setData(await getReportingPayouts(params.start, params.end));
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      })();
    },
    [setLoading, setData, setError]
  );

  return { data, loading, error, refetch };
};
