import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import ConfirmTermsComponent from "./ConfirmTermsComponent";
import Box from "../shared/components/Box";
import LoginPage from "./LoginPage";

export default ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { user, isPending } = useAuthenticator();

  const authFormFields = {
    signIn: {
      username: {
        placeholder: t("Auth.EnterUsername"),
      },
      password: {
        placeholder: t("Auth.EnterPassword"),
      },
    },
    signUp: {
      email: {
        placeholder: t("Auth.EnterEmail"),
      },
      password: {
        placeholder: "",
      },
      confirm_password: {
        placeholder: "",
      },
    },
  };

  const authComponents = {
    SignUp: {
      Footer() {
        return (
          <Box pl={32} pr={32} pb={32}>
            <ConfirmTermsComponent />
          </Box>
        );
      },
    },
  };

  if (!user || isPending) {
    return (
      <LoginPage>
        <Authenticator
          components={authComponents}
          formFields={authFormFields}
          variation="default"
        ></Authenticator>
      </LoginPage>
    );
  }

  return <Authenticator>{children}</Authenticator>;
};
