import { Divider, useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";
import { clearAllCache } from "../../hooks/useCache";
import { StyledItem, StyledToggleButton } from "./MenuMobile.styled";
import { useCurrentUser } from "../../hooks/useCurrentUser";

export default () => {
  const [, setCurrentLocation] = useState("");
  const { signOut, user } = useAuthenticator((context) => [context.user]);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isService, isCoordinator, isAdmin } = useCurrentUser();

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  return (
    <Dropdown className="noprint">
      <StyledToggleButton>
        <GiHamburgerMenu />
      </StyledToggleButton>
      <DropdownMenu>
        {!!user && isAdmin && (
          <StyledItem
            onClick={() => {
              navigate("/");
            }}
          >
            Search
          </StyledItem>
        )}

        {!!user && isAdmin && (
          <StyledItem
            onClick={() => {
              navigate("/occupancy");
            }}
          >
            Occupancy
          </StyledItem>
        )}

        {!!user && (isCoordinator || isAdmin) && (
          <StyledItem
            onClick={() => {
              navigate("/cleaning-schedule");
            }}
          >
            Cleaning Schedule
          </StyledItem>
        )}

        {!!user && (isCoordinator || isAdmin) && (
          <StyledItem
            onClick={() => {
              navigate("/company/people");
            }}
          >
            Manage People
          </StyledItem>
        )}

        {!!user && isService && (
          <StyledItem
            onClick={() => {
              navigate("/rep/schedule");
            }}
          >
            My Schedule
          </StyledItem>
        )}

        {!!user && isService && (
          <StyledItem
            onClick={() => {
              navigate("/rep/report");
            }}
          >
            My Task Report
          </StyledItem>
        )}

        <Divider></Divider>

        {!!user && !!signOut && (
          <StyledItem
            marginTop={16}
            onClick={() => {
              clearAllCache();
              signOut();
            }}
          >
            {t("Logout")}
          </StyledItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
