import {
  Button,
  Alert,
  Card,
  FieldGroupIcon,
  Flex,
  Loader,
  SwitchField,
  TextField,
  SelectField,
} from "@aws-amplify/ui-react";
import { MdAdd, MdCheck, MdEdit, MdInfo } from "react-icons/md";
import {
  BankAccount,
  useBankAccounts,
} from "../../shared/hooks/useBankAccount";
import { useEffect, useMemo, useState } from "react";
import iban from "iban";

export default function UserBankAccountsForm({ userId }: { userId: number }) {
  const [addingAccount, setAddingAccount] = useState(false);

  const {
    loadBankAccounts,
    bankAccounts,
    isLoading,
    loadingError,
    updateBankAccount,
    isSaving,
    savingError,
    savingSuccess,
  } = useBankAccounts(userId);

  const [formValues, setFormValues] = useState<BankAccount>({
    id: 0,
    bic: "",
    currency: "PLN",
    iban: "",
    owner_name: "",
    use_for_payout: true,
    user_id: userId,
  });

  const formValid = useMemo(
    () => ({
      id: true,
      bic: true,
      currency: true,
      iban: iban.isValid(formValues.iban),
      owner_name: !!formValues.owner_name,
      use_for_payout: true,
      user_id: true,
    }),
    [formValues]
  );

  const updateFormValues = (key: keyof typeof formValues, value: string) =>
    setFormValues({
      ...formValues,
      [key]: value,
    });

  const getOnChangeHandler =
    (field: keyof typeof formValues, blacklist?: RegExp) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      updateFormValues(
        field,
        blacklist
          ? event.target.value.replaceAll(blacklist, "")
          : event.target.value
      );
    };

  const saveData = async () => {
    await updateBankAccount(formValues);
    setAddingAccount(false);
    await loadBankAccounts();
  };

  const getIcon = (readOnly = false) => (
    <FieldGroupIcon>{readOnly ? <MdInfo /> : <MdEdit />}</FieldGroupIcon>
  );

  useEffect(() => {
    loadBankAccounts();
  }, [loadBankAccounts]);

  const getAccountCard = (
    bankAccount: BankAccount,
    key: string,
    validationObject?: Record<keyof BankAccount, boolean>,
    readOnly = true
  ) => (
    <Card key={key} variation="outlined">
      <Flex direction={"column"}>
        {readOnly && (
          <SwitchField
            name="use_for_payout"
            label={`Use for Payout (ID ${bankAccount.id})`}
            labelPosition={"end"}
            isChecked={bankAccount.use_for_payout}
          />
        )}

        <TextField
          label={"Owner Name"}
          value={bankAccount.owner_name}
          onChange={getOnChangeHandler("owner_name")}
          hasError={validationObject && !validationObject.owner_name}
          errorMessage="Owner name is required"
          readOnly={readOnly}
          innerStartComponent={getIcon(readOnly)}
        />

        <TextField
          label={"IBAN"}
          value={bankAccount.iban}
          onChange={getOnChangeHandler("iban", /[^a-z0-9]/gi)}
          hasError={validationObject && !validationObject.iban}
          errorMessage="Please enter valid IBAN"
          readOnly={readOnly}
          innerStartComponent={getIcon(readOnly)}
        />

        <TextField
          label={"BIC"}
          value={bankAccount.bic}
          onChange={getOnChangeHandler("bic", /[^a-z0-9]/gi)}
          hasError={validationObject && !validationObject.bic}
          errorMessage="Please enter valid BIC"
          readOnly={readOnly}
          innerStartComponent={getIcon(readOnly)}
        />

        <SelectField
          label={"Currency"}
          value={bankAccount.currency}
          onChange={getOnChangeHandler("currency")}
        >
          <option value="PLN">PLN</option>
          <option value="EUR">EUR</option>
        </SelectField>

        {!readOnly && (
          <Flex direction={"row"}>
            <Button
              variation="primary"
              gap={"0.5rem"}
              onClick={saveData}
              isDisabled={Object.values(validationObject || {}).some(
                (value) => !value
              )}
              isLoading={isSaving}
            >
              <MdCheck /> Save
            </Button>
          </Flex>
        )}
      </Flex>
    </Card>
  );

  return (
    <Flex direction={"column"}>
      {!isLoading && loadingError && (
        <Alert marginTop={5} variation="error" isDismissible>
          Can not load bank accounts. {loadingError.message}
        </Alert>
      )}

      {!isLoading && !loadingError && bankAccounts && !bankAccounts.length && (
        <Alert variation="info">No bank accounts setup</Alert>
      )}

      {!isSaving && savingError && (
        <Alert
          marginTop={5}
          variation="error"
          isDismissible={true}
          hasIcon={true}
          heading={"Can't save bank account"}
        >
          {savingError.message}
        </Alert>
      )}

      {!isSaving && savingSuccess && (
        <Alert
          marginTop={5}
          variation="success"
          isDismissible={true}
          hasIcon={true}
          heading={"Successfully saved bank account"}
        ></Alert>
      )}

      {(isLoading || isSaving) && <Loader variation="linear" />}

      {!loadingError && !isLoading && !addingAccount && (
        <Flex direction={"row"}>
          <Button
            variation="primary"
            gap={"0.5rem"}
            onClick={() => {
              setAddingAccount(true);
            }}
            isDisabled={isLoading}
          >
            <MdAdd /> Add bank account
          </Button>
        </Flex>
      )}

      {addingAccount &&
        getAccountCard(formValues, "new-account", formValid, false)}

      {!isLoading &&
        !loadingError &&
        bankAccounts &&
        bankAccounts
          .sort((a, b) => b.id - a.id)
          .map((bankAccount, index) =>
            getAccountCard(bankAccount, `bank-account-${index}`)
          )}
    </Flex>
  );
}
