import { Alert, Card, Flex, SelectField, Text } from "@aws-amplify/ui-react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DownloadReportButton from "../DownloadReportButton";
import {
  ConsolidatedReport,
  getReportingConsolidated,
} from "../useReportingApi";
import GenericTable from "../../shared/GenericTable";
import GenericTablePage from "../../shared/GenericTablePage";
import { getPeriodBounds } from "../../shared/utils/getPeriodBounds";
import MonthYearFilters from "../../shared/components/MonthYearFilters";

export default () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [report, setReport] = useState<ConsolidatedReport>();
  const [searchParams, setSearchParams] = useSearchParams();

  const load = useCallback(
    async (year: number, month?: number) => {
      setLoading(true);

      try {
        const { start, end } = getPeriodBounds(year, month);

        const data = await getReportingConsolidated(
          start,
          end,
          searchParams.get("sort") || undefined,
          searchParams.get("recognisionMethod") || undefined
        );

        setReport(data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, searchParams]
  );

  useEffect(() => {
    const year = searchParams.get("year")
      ? Number(searchParams.get("year"))
      : undefined;
    const month = searchParams.get("month")
      ? Number(searchParams.get("month"))
      : undefined;
    const recognisionMethod =
      searchParams.get("recognisionMethod") || undefined;

    if (!year || (!year && !month)) {
      setSearchParams({
        year: String(moment().subtract(1, "months").year()),
        month: String(moment().month() + 1),
        recognisionMethod: recognisionMethod || "per-day",
      });

      return;
    }

    load(year, month);
    // TODO fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, load]);

  return (
    <GenericTablePage
      title={"Consolidated Sales"}
      subtitle="Report of all sales including reservations and separate charge items. You can either run report based on arrival date as recognision date or with monthly pro-rata recognision."
    >
      <Card variation="elevated">
        <Flex direction="column" grow={1}>
          <Flex direction="row" gap={50} justifyContent={"space-between"}>
            <Flex direction={"row"} gap={8}>
              <Flex direction={"column"}>
                <Text textAlign={"flex-start"}>Recognision Method</Text>
                <SelectField
                  labelHidden={true}
                  label=""
                  size="small"
                  defaultValue={String(searchParams.get("recognisionMethod")!)}
                  value={String(searchParams.get("recognisionMethod")!)}
                  disabled={loading}
                  onChange={(e) => {
                    searchParams.set("recognisionMethod", e.target.value);

                    setSearchParams(searchParams);
                  }}
                >
                  <option key={"per-day"} value={"per-day"}>
                    Per Day (for Income)
                  </option>
                  <option key={"arrival"} value={"arrival"}>
                    Arrival (for VAT)
                  </option>
                </SelectField>
              </Flex>
              <Flex direction={"column"}>
                <MonthYearFilters loading={loading}></MonthYearFilters>
              </Flex>
            </Flex>

            <Flex direction={"column"} justifyContent={"center"}>
              <Flex direction="row">
                <DownloadReportButton
                  filename={
                    searchParams.get("month")
                      ? `${searchParams.get("year")}-${Number(
                          searchParams.get("month")
                        )}-rentujemy-consolidated-${searchParams.get(
                          "recognisionMethod"
                        )}-${moment().format("YYYY-MM-DD HHmm")}`
                      : `${searchParams.get(
                          "year"
                        )}-rentujemy-consolidated-${searchParams.get(
                          "recognisionMethod"
                        )}-${moment().format("YYYY-MM-DD HHmm")}`
                  }
                  data={report}
                ></DownloadReportButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card>

      {error && (
        <Alert
          marginTop={5}
          variation="error"
          isDismissible={true}
          onDismiss={() => {
            setError(undefined);
          }}
        >
          {error.message}
        </Alert>
      )}

      <Flex overflow={"scroll"}>
        <GenericTable data={report} loading={loading}></GenericTable>
      </Flex>
    </GenericTablePage>
  );
};
