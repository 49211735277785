import type { IDictionary } from "./IDictionary";

export const de: IDictionary = {
  translation: {
    Invoice: {
      InvalidPostalCode: "Ungültige Postleitzahl für das ausgewählte Land",
      BeforeCheckoutAlert:
        "Sie können eine Rechnung entweder am Abreisetag oder danach anfordern. Bitte zögern Sie nicht, die Rechnung zu einem späteren Zeitpunkt anzufordern.",
      InvalidInput: "Invalid Input",
      NoPaymentAlert:
        "The reservation is not fully paid. You cannot request invoice before payment.",
      Required: "Missing input",
      NotAvailable: "Late Checkout Unavailable",
      Title: "Poproś o Fakturę VAT za pobyt z RENTUJEMY",
      InputVatLabel:
        "Please specify NIP (for polish companies) or VAT ID (for foreign companies)",
      InputCompanyName: "Company name",
      Subtitle:
        "Fordern Sie Ihre VAT-Faktur für Ihren Aufenthalt bei RENTUJEMY an",
      Cancel: "The payment failed. Would you like to try again?",
      Success: "Your late checkout until 3PM is confirmed",
      Submit: "Order Late Checkout",
      GenerationError:
        "Wystąpił błąd podczas generowania faktury VAT. Popraw dane i spróbuj ponownie.",
    },
    FinalizeBooking: {
      Title: "Finalize Booking",
      Subtitle: "Finalize Booking",
      Submit: "Proceed to Payment",
      SuccessTitle: "Your Reservation is Confirmed",
    },
    EarlyCheckin: {
      Title: "Early Check-In from 7AM",
      NotAvailable: "Early Check-In Unavailable",
      Cancel: "The payment failed. Would you like to try again?",
      Success: "Your late checkout until 3PM is confirmed",
      Subtitle:
        "Early Check-In - If you'd like to check in earlier from 7 AM, please fill out the following form.",
      Submit: "Request Early-Checkin",
    },
    LateCheckout: {
      Title: "Late Checkout at 3pm",
      NotAvailable: "Late Checkout Unavailable",
      Subtitle: "Extend Your Checkout Time to 3PM. Order now.",
      Cancel: "The payment failed. Would you like to try again?",
      Success: "Your late checkout until 3PM is confirmed",
      Submit: "Order Late Checkout",
    },
    ReservationId: "Reservierung ID",
    TODO: "TODO",
    OwnerPayout: "Auszahlung",
    PortalFees: "Provision von Portalen",
    ManagementCommission: "Provision von Rentujemy",
    RentujemyLogo: "Rentujemy Logo",
    YourApartment: "Dein Apartment",
    PaymentId: "ZahlungsID",
    BankAccount: "Bankkonto",
    TotalTax: "VAT & Kursteuer",
    Search: "Suchen",
    ChooseContract: "Suchen",
    OwnerCockpit: "Eigentümer-Cockpit",
    CoordinatorCockpit: "Coordinator Cockpit",
    Tags: "Stichworte:",
    Links: "Links:",
    More: "Mehr...",
    PropertyImage: "Immobilienbild",
    Address: "Adresse:",
    Reservations: "Reservierungen",
    OfferDescription: "Angebotsbeschreibung",
    CheckIn: "Einchecken",
    CheckOut: "Kasse",
    Channel: "Kanal",
    TotalPrice: "Gesamtpreis",
    Other: "Andere",
    MyContracts: "Meine Verträge",
    AllContracts: "Alle Verträge",
    MyClearingReports: "Meine Abbrechnungen",
    Invest: "Investieren",
    SeeInvestProjects: "Zu allen Projekten",
    KID: "BIB (Basisinformationsblatt)",
    Airbnb: "AIRBNB",
    Booking: "BOOKING",
    Stay: "STAY.RENTUJEMY.PL",
    AcceptTerms: {
      AcceptTerms:
        "Indem Sie auf 'Konto erstellen' klicken, bestätigen Sie, dass Sie sie gelesen und akzeptiert haben.",
      TermsHyperlink:
        "die Geschäftsbedingungen der Internetplattform invest.rentujemy.pl.",
    },
    GuestReservation: {
      OpenBalanceAlert: "Rezerwacja nie jest w pełni opłacona.",
      Reservation: "Buchung",
      RequestInvoice: "Request VAT Invoice",
      FinalizePayment: "Dokończ płatność",
      NewCheckoutDate: "Neue Checkoutsdatum",
      Title: "Deinen Aufenthalt verlängern",
      SuccessTitle: "The apartment is available to book!",
      Notes:
        "Owner's reservations cannot conflict with current reservations and can last a maximum of 14 days. The reservation itself is free, however, after each reservation, an invoice will be issued for the cleaning service after the reservation. The reservation must be confirmed by Rentujemy.",
      SendRequest: "Send request",
      FailedMessage:
        "Wir haben ein technisches Problem. Versuchen Sie es noch einmal.",
      SuccessMessage:
        "Wir haben Ihnen eine E-Mail geschickt, um Ihre Buchung abzuschließen. Sie können auch auf den untenstehenden Button klicken, um Ihre Buchung zu finalisieren.",
      FinalizeBooking: "Finalize Booking",
      PastDateError: "Dates must be in the future.",
      CheckoutBeforeCheckinError:
        "Check-out Datum kann nicht vor Check-In Datum liegen.",
      ReservationDurationError:
        "Die Reservierung darf nicht länger als 14 Tage sein.",
      GuestFirstName: "Vorname Gast",
      GuestLastName: "Nachname Gast",
      CheckinDate: "Check-In Datum",
      CheckoutDate: "Check-Out Datum",
      NotAvailable:
        "Die Wohnung ist für die ausgewählten Daten nicht verfügbar.",
    },
    Account: {
      DeleteAccount:
        "Wenn Sie Ihr Konto löschen möchten, schreiben Sie eine Anfrage an wlasciciele@rentujemy.pl",
      MyData: "Persönliche Daten",
      City: "Ort",
      Country: "Land",
      FirstName: "Vorname",
      Language: "Sprache",
      LastName: "Nachname",
      PersonalDocumentId: "Personalausweis-Nummer",
      Street: "Straße",
      StreetNo: "Straßen- o/u Hausnummer",
      Subtitle: "",
      TaxId: "Steuernummer",
      UserId: "Nutzeridentifizierungsnummer",
      ZipCode: "PLZ",
    },
    ContractType: "Investitionstyp",
    Counterparty: "Vertragspartner",
    "Dalej do danych umowy": "Weiter zu Vertragsangaben",
    "Do mojego inwestycjnego kokpitu": "Zum Portfolio",
    "Do ostatecznego potwierdzenia inwestycji": "Zur finalen Bestätigung",
    EarliestCancellationDate: "Frühestens kündbar zum",
    FixedInterestRate: "Verzinsung",
    InvestedAmount: "Investitionsbetrag",
    InvestmentAmountContent: {
      HowIsItCalculated: "Wie wird es berechnet?",
      CurrencyConversionDisclaimer:
        "Der EUR-Betrag wird in die PLN-Währung bei erfolgreicher Annahme des Angebots konvertiert. Die Auszahlungen werden auf dein Konto am Zahlungsdatum mit jeweiligen Kurs umgerechnet.",
      InvestInEUR: "In EUR investieren",
      InvestInPLN: "In PLN investieren",
      MonthlyIncomeDisclaimer:
        "* berechnet auf Basis von angenommenen variablen Zinssatz abhängig vom Einkommen aus der Vermietung des Objektes. Hier wurden 8% angenommen.",
      MonthlyIncomeHeadline:
        "Variable monatliche Zinsauszahlung auf dein Konto *",
      YearlyIncomeDisclaimer:
        "* berechnet auf Basis von angenommenen variablen Zinssatz abhängig vom Einkommen aus der Vermietung des Objektes. Hier wurden {{roi}}% angenommen.",
      YearlyIncomeHeadline:
        "Erwarteter jährlicher Betrag, mit monatlicher Auszahlung auf dein Konto *",
    },
    InvestmentConfirm: {
      ConfirmInvestmentInfo:
        "Hiermit bestätige ich, dass ich die Vertragsunterlagen soeben erhalten und gelesen habe. Ich verstehe das mit meiner Investition verbundene Risiko.",
      ConfirmNetWorthInfo:
        "Hiermit bestätige ich, dass mein durchschnittliches monatliches Nettoeinkommen mindestens 10.000 PLN beträgt oder dass ich über ein Vermögen von mindestens 500.000 PLN verfüge, über das ich frei verfügen kann.",
      ConfirmOwnIdentity:
        "Hiermit bestätige ich, dass ich diese Investition in meinem eigenen Namen und auf eigene Rechnung tätige.",
      ConfirmTermsOfService: "Hiermit stimme ich den Nutzungsbedingungen zu.",
      Headline: "Bestätigung Ihres Investitionsangebots",
    },
    InvestmentContractContent: {
      EnsureYouKnowTheRisks: `Stelle sicher, dass du wichtige Informationen über das Projekt kennst. Alle Investitionen haben das Risiko Kapital zu verlieren. Das Unternehmen Rentujemy Sp. z o.o. listet einige Risiken in seinen Unterlagen. Investiere verantwortungsvoll und mach dich stets mit allen Unterlagen vertraut, bevor du eine Entscheidung für eine Investition triffst.`,
      Headline: "Deine Investitionsverträge",
      SaveContracts:
        "Bitte speichere die folgenden Vertragsvorlagen für das Projekt:",
    },
    InvestmentSuccess: {
      Headline: "Vielen Dank für das Investitionsangebot.",
      Subtitle:
        "Vielen Dank für das Investitionsangebot, es wurde erfolgreich abgesendet. Innerhalb der nächsten Tage erhältst du von uns eine offizielle Vertragsbestätigung.",
    },
    InvestmentWireInvestment: {
      Headline: "Überweisung",
      TransferCommitment:
        "Ich überweise den Betrag innerhalb von 5 Werktagen auf obiges Konto. Mir ist bekannt, dass Rentujemy Sp. z o.o. das Vertragsangebot ablehnen kann.",
      TransferNow:
        "Bitte überweise den Betrag unter Angabe des Verwendungszwecks umgehend auf unten stehendes Konto.",
    },
    Inwestycja: "Investition",
    "Kokpit Inwestora": "Portfolio",
    Konto: "Konto",
    "Kwota inwestycji": "Investitionsbetrag",
    "Kwota Przelewu": "Überweisungsbetrag",
    ContractUrl: "Link zum Vertrag",
    MyInvests: "Meine aktiven Investitionen",
    MyOffers: "Meine Angebote",
    "Moje Wypłaty": "Meine Auszahlungen",
    Od: "From:",
    Do: "To:",
    "Całkowita kwota:": "Gesamtbetrag:",
    All: "Alle",
    Paid: "Bezahlt",
    Planned: "Geplant",
    InvestObject: "Investmentobjekt",
    Kontrakt: "Kontrakt",
    BankName: "Name des Finanzinstituts",
    ReceiverName: "Name des Empfängers",
    NoActiveInvests: "Es gibt noch keine aktiven Investitionen.",
    NoInvestOffers: "Es wurden noch keine Investitionsangebote abgegeben.",
    NoInvestPayouts: "Es gibt noch keine Auszahlungen aus den Investitionen.",
    NotConfirmed: "Nicht bestätigt",
    Oczekujący: "Anstehend",
    Odrzucona: "Abgelehnt",
    "Oferta wysłana": "Angebotssendungsdatum",
    opcjonalne: "optional",
    "Ostatnia aktualizacja": "Letzte Aktualisierung",
    ParticipationInValueIncrease: "Partizipierung an Wertzuwachs",
    Payout: {
      TotalAmount: "Betrag",
      AvgPayout: "Ø Monatliche Auszahlung",
      Amount: "Betrag",

      FixedInterest: "Fixer Zinsatz",
      MixedInterest: "Fixer + Variabler Zinsatz",
      OwnerPayout: "Miete an den Eigentümer",
      Paid: "Überwiesen",
      Planned: "Eingeplant",
      PaidDate: "Überweisungsdatum",
      WrongIBAN: "Bitte geben Sie einen gültigen IBAN-Wert ein",
      Settlement: "Abbrechnung",
      SettlementStart: "Abbrechnungstart",
      SettlementEnd: "Abbrechnungsende",
      PlannedPaymentDate: "Auszahlungsdatum",
      Principal: "Tilgung",
      Status: "Status",
      Type: "Typ",
      VariableInterest: "Variabler Zinsatz",
      IsSyncing: "Payouts data is synchronizing. Please wait.",
      IsFetching: "Fetching payouts data. Please wait.",
      Title: "Titel",
    },
    PayoutAccount: {
      OwnerName: "Kontoinhaber",
      AccountCurrency: "Kontowährung",
      ChooseAccountCurrency: "Wählen Sie Ihre Kontowährung aus",
      BICInfo: "Nur für nicht polnischen Konten erforderlich.",
      WrongOwnerName: "Bitte geben Sie den Kontoinhaber ein",
      NoAccounts: "Noch keine Auszahlungskonten",
      ContactSupport:
        "Um ein Auszahlungskonto zu bearbeiten oder zu löschen, kontaktieren Sie bitte support@rentujemy.pl",
      AddAccount: "Neues Auszahlungskonto hinzufügen",
    },
    "Pobierz wzór umowy pożyczki": "Vertragsvorlage herunterladen",
    Confirm: "Bestätigen",
    "Potwierdzam przelew": "Überweisung bestätigen",
    "Prześlij ofertę inwestycji": "Investitionsangebot absenden",
    Payouts: "Auszahlungen",
    ShareOfProjectIncome: "Beteiligung an Gewinn",
    "Status inwestycji": "Investitionsstatus",
    "Status płatności": "Zahlungsstatus",
    "Teraz Inwestuję": "Jetzt Investieren",
    "Tytuł przelewu": "Verwendungszweck",
    "W weryfikacji": "Wird verifiziert",
    "Wybierz inwestycje": "Investitionsbetrag wählen",
    Logout: "Ausloggen",
    Zaakceptowana: "Akzeptiert",
    "Zapłać teraz": "Jetzt überweisen",
    PurposePayment: "Überweisungszweck",
    BeneficiaryName: "Name des Begünstigten",
    IBAN: "IBAN",
    BIC: "BIC",
    City: "Stadt",
    TransferAmount: "Überweisungssumme",
    CreateContract: "Vertrag erstellen",
    Admin: "Admin",
    Impressum: "Impressum",
    Home: "Home",
    Investments: "Investments",
    "Payout to investors": "Ausschüttung",
    shortTermCalculator: {
      chooseCity: "Stadt wählen:",
      chooseRegion: "Region wählen:",
      chooseDistrict: "Bezirk wählen:",
      chooseType: "Wohnungstyp wählen:",
      chooseStandard: "Wählen Sie Wohnungsstandard:",
      studio: "Studio",
      twoRooms: "Zwei Räume",
      threeRooms: "Drei Räume",
      twoRoomsAlternative: "2 Räume",
      threeRoomsAlternative: "3 Räume",
      comfort: "Comfort",
      premium: "Premium",
      Warsaw: "Warschau",
      Lodz: "Lodz",
      Tricity: "Dreistadt",
    },
    ROICalculator: {
      CalculateROI: "Calculate ROI",
      Amount: "Geben Sie den Betrag ein",
      TaxAmount: "Enter the tax rate",
      RentalIncome: "Mieteinnahmen",
      UtilityCosts: "Betriebskosten der Immobilie",
      TaxRate: "Steuersatz (%)",
      PropertyPurchasePrice: "Kaufpreis der Immobilie",
      CheckOut: "Weiter",
      ApartmentStandard: "Apartmentstandard",
      NoOfRooms: "Zimmeranzahl",
      AdditionalRentalCosts:
        "Von dem oben genannten Betrag muss der Eigentümer eine Steuer von 8% zahlen. Zusätzlich ist der Eigentümer für die Zahlung der monatlichen Mietkosten für das Apartment, Wasser, Strom und andere Versorgungseinrichtungen verantwortlich.",
      PricePerNight: "Preis pro Nacht",
      IncomeDisclaimer:
        "Der Preis pro Nacht ist der von Rentujemy festgelegte Preis, der um die Servicegebühr der Buchungsportale, die Vermittlungsgebühr von Rentujemy und die Mehrwertsteuer reduziert ist. Wir ziehen keine Einkommenssteuer oder Geschäftskosten ab. Die tatsächliche Höhe deines Einkommens hängt von verschiedenen Faktoren wie Verfügbarkeit, Preis und Nachfrage in deiner Region ab. Andere Faktoren können sich ebenfalls auf dein tatsächliches Einkommen auswirken.",
      OccupancyDisclaimer:
        "Machen Sie sich mit den Methoden vertraut, mit denen Rentujemy die Belegung von Kurzzeitmieten erhöht.",
      PricePerNightDisclaimer:
        "Erfahren Sie, welche Faktoren Rentujemy bei der Festlegung des Mietpreises berücksichtigt.",
      Title: "Einkommensrechner für Eigentümer",
      OwnerSubtitle:
        "Wie viel kann Ihre Wohnung in Polen mit Rentujemy verdienen?",
      ROI: "ROI",
      Value: "Wert",
      IncomeSubtitle:
        "{{nights}} Tage pro Monat für {{price}} PLN/Tag vermietet",
      PotentialAnalysis: "Einkommensprognose",
      CTA: "Suchen Sie einen Immobilienverwalter in Polen?",
      Calculation: "Berechnung",
      OwnerPayout: "Auszahlung an den Eigentümer",
      IncomeAnalysis: "Einkommensanalyse",
      ManagementFeesCosts: "Rentujemy-Provision",
      ApartmentSize: "Apartmentgröße",
      Taxes: "Mehrwertsteuer (8%)",
      TotalInvestmentValue: "Gesamtinvestitionswert",
      ReservationFeesCosts: "Kosten für Reservierungsportale",
      ExpectedPricePerNight: "Erwarteter täglicher Mietpreis",
      Occupation: "Belegung",
      Subtitle:
        "Die prognostizierten Finanzzahlen für die Investition '{{name}}'",
      MonthlyIncome: "Monatlicher Umsatz",
      AfterUtilityCosts: "Nach Abzug der laufenden Mietkosten",
      AfterManagementFeesCosts:
        "Nach Abzug der Verwaltungskosten von Rentujemy",
      AfterReservationPortalCosts: "Nach Abzug der Reservierungsportalgebühren",
    },
    IncomeCalculator: {
      PayoutDisclaimer:
        "Die Ergebnisse sind nur Schätzungen und dienen ausschließlich Informationszwecken.",
      CheckOut: "Weiter",
      ApartmentStandard: "Apartmentstandard",
      NoOfRooms: "Zimmeranzahl",
      AdditionalRentalCosts:
        "Von dem oben genannten Betrag muss der Eigentümer eine Steuer von 8% zahlen. Zusätzlich ist der Eigentümer für die Zahlung der monatlichen Mietkosten für das Apartment, Wasser, Strom und andere Versorgungseinrichtungen verantwortlich.",
      PricePerNight: "Preis pro Nacht",
      IncomeDisclaimer:
        "Der Preis pro Nacht ist der von Rentujemy festgelegte Preis, der um die Servicegebühr der Buchungsportale, die Vermittlungsgebühr von Rentujemy und die Mehrwertsteuer reduziert ist. Wir ziehen keine Einkommenssteuer oder Geschäftskosten ab. Die tatsächliche Höhe deines Einkommens hängt von verschiedenen Faktoren wie Verfügbarkeit, Preis und Nachfrage in deiner Region ab. Andere Faktoren können sich ebenfalls auf dein tatsächliches Einkommen auswirken.",
      OccupancyDisclaimer:
        "Machen Sie sich mit den Methoden vertraut, mit denen Rentujemy die Belegung von Kurzzeitmieten erhöht.",
      PricePerNightDisclaimer:
        "Erfahren Sie, welche Faktoren Rentujemy bei der Festlegung des Mietpreises berücksichtigt.",
      Title: "Einkommensrechner für Eigentümer",
      OwnerSubtitle:
        "Wie viel kann Ihre Wohnung in Polen mit Rentujemy verdienen?",
      ROI: "ROI",
      Value: "Wert",
      IncomeSubtitle:
        "{{nights}} Tage pro Monat für {{price}} PLN/Tag vermietet",
      PotentialAnalysis: "Einkommensprognose",
      CTA: "Suchen Sie einen Immobilienverwalter in Polen?",
      Calculation: "Berechnung",
      OwnerPayout: "Auszahlung an den Eigentümer",
      IncomeAnalysis: "Einkommensanalyse",
      ManagementFeesCosts: "Rentujemy-Provision",
      ApartmentSize: "Apartmentgröße",
      Taxes: "Mehrwertsteuer (8%)",
      TotalInvestmentValue: "Gesamtinvestitionswert",
      ReservationFeesCosts: "Kosten für Reservierungsportale",
      ExpectedPricePerNight: "Erwarteter täglicher Mietpreis",
      Occupation: "Belegung",
      Subtitle:
        "Die prognostizierten Finanzzahlen für die Investition '{{name}}'",
      MonthlyIncome: "Monatlicher Umsatz",
      AfterUtilityCosts: "Nach Abzug der laufenden Mietkosten",
      AfterManagementFeesCosts:
        "Nach Abzug der Verwaltungskosten von Rentujemy",
      AfterReservationPortalCosts: "Nach Abzug der Reservierungsportalgebühren",
    },
    "Income Calculator": "Einkommensrechner",
    GoToIncomeProjection: "Einkommensprognose ansehen",
    PaymentStatus: {
      UnknownStatus: "Unbekannter Status",
      Confirmed: "Bestätigt",
      Declared: "Deklariert",
      Pending: "Ausstehend",
    },
    UpdateOffer: "Angebot aktualisieren",
    AddPayout: "Auszahlung hinzufügen",
    Transferred: "Überwiesen",
    Auth: {
      EnterUsername: "Benutzername eingeben",
      EnterPassword: "Passwort eingeben",
      EnterEmail: "Mailadresse eingeben",
    },
    OwnerCockpitError: {
      SomeErrorCode:
        "Etwas ist schief gelaufen, bitte versuchen Sie es später erneut.",
      RefreshErrorCode:
        "Aktualisierung nicht möglich, Daten möglicherweise veraltet.",
    },
    Calendar: {
      Calendar: "Kalendar",
      Today: "Heute",
    },
    Reservation: {
      Id: "Reservierungsnummer",
      NumberOfGuests: "Number of guests",
      CleaningFee: "Cleaning fee",
      ReservationTotal: "Reservation Total Value",
      Details: "Details",
      OwnerStay: "Eigentümeraufenthalt",
      OwnerReservation: "Eigentümerreservierung",
      Cancelled: "Storniert",
      IsSyncing: "Bitte warten Reservierungsdaten werden geladen.",
      IsSyncingCheckLater:
        "Daten werden mit den Reservierungsportalen synchronisiert. Bitte schauen Sie später wieder rein!",
      NoData:
        "Diese Immobilie wurde noch nicht gebucht. Bitte schauen Sie später wieder rein.",
      NoCheckoutsInThisMonth: "Keine Checkouts für diesen Monat.",
      OccupancyRate: "Durchschnittliche Belegung",
      AverageDailyRate: "Durchschnittliche Nettoauszahlung pro gemietetem Tag",
      AverageDailyPrice: "Durchschnittspreis der verkauften Nächte",
      AdditionalCostDisclaimer:
        "Die Informationen dienen zu Informationszwecken, jedoch bemühen wir uns, sie so genau wie möglich zu gestalten. Die Auszahlung wird auf Grundlage der Abrechnung am Ende des Monats festgelegt.",
      Notes: "Notizen",
    },
    OwnerReservation: {
      Title: "Eigentümerreservierung erstellen",
      Notes:
        "Eigentümerreservierungen dürfen nicht mit aktuellen Reservierungen kollidieren und dürfen maximal 14 Tage dauern. Die Reservierung selbst ist kostenlos, jedoch wird nach jeder Reservierung eine Rechnung für den Reinigungsservice nach der Reservierung ausgestellt. Die Reservierung muss von Rentujemy bestätigt werden.",
      SendRequest: "Anfrage senden",
      FailedMessage: "Fehler beim Erstellen Ihrer Reservierung.",
      SuccessMessage:
        "Ihre Reservierung wurde gesendet. Wenn sie nicht mit anderen Reservierungen kollidiert, wird sie in Kürze im Reservierungskalender angezeigt. Bitte beachten Sie, dass es bis zu 30 Minuten dauern kann bis Ihre Reservierung im Kalender erscheint.",
      PastDateError: "Die Daten müssen in der Zukunft liegen.",
      CheckoutBeforeCheckinError:
        "Das Check-out-Datum kann nicht vor dem Check-In-Datum liegen.",
      ReservationDurationError:
        "Die Reservierung darf nicht länger als 14 Tage dauern.",
      GuestFirstName: "Vorname Gast",
      GuestLastName: "Nachname Gast",
      CheckinDate: "Ankunft",
      CheckoutDate: "Abreise",
    },
    Details: "Details",
    Yes: "Ja",
    SetStatus: "Set Status",
    Synchronize: "Sync",
    CustomerContracts: {
      NoContractsAssigned:
        "Sie haben noch keine Verträge im Eigentümerportal. Sie sollten hier bald erscheinen. Wir bitten um Ihre Geduld. Wenn Sie Fragen haben oder Hilfe benötigen, kontaktieren Sie uns bitte. Vielen Dank für Ihr Verständnis!",
      Loading: "Vertragsdaten werden geladen. Bitte warten.",
    },
    GuestLogin: {
      ReservationId: "Reservierungsnummer",
      GuestLastName: "Nachname Gast",
      Login: "Login",
    },
    ErrorPage: {
      SomethingWentWrong: "Da ist etwas schief gelaufen!",
      TryAgainLater:
        "Bitte versuchen Sie es später erneut. Falls das Problem weiterhin besteht, so wenden Sie sich bitte an unseren Kunden Support.",
      ReturnToHomepage: "Zurück zur Homepage",
    },
  },
};
