import { HAReservation } from "../../Search/types/reservation";
import { API } from "../utils/API";

export interface HAListing {
  id: number;
  propertyTypeId: number;
  name: string;
  externalListingName: string;
  internalListingName: string;
  description: string;
  thumbnailUrl: string;
  houseRules: string;
  keyPickup: string | null;
  specialInstruction: string | null;
  doorSecurityCode: string;
  country: string;
  countryCode: string;
  state: string;
  city: string;
  street: string;
  address: string;
  publicAddress: string;
  zipcode: string;
  price: number;
  starRating: number | null;
  weeklyDiscount: number;
  monthlyDiscount: number;
  propertyRentTax: number;
  guestPerPersonPerNightTax: number;
  guestStayTax: number;
  guestNightlyTax: number;
  refundableDamageDeposit: number;
  isDepositStayCollected: number;
  personCapacity: number;
  maxChildrenAllowed: number | null;
  maxInfantsAllowed: number | null;
  maxPetsAllowed: number | null;
  lat: number;
  lng: number;
  checkInTimeStart: number;
  checkInTimeEnd: number | null;
  checkOutTime: number;
  cancellationPolicy: string;
  squareMeters: number;
  roomType: string;
  bathroomType: string;
  bedroomsNumber: number;
  bedsNumber: number;
  bathroomsNumber: number;
  minNights: number;
  maxNights: number;
  guestsIncluded: number;
  cleaningFee: number;
  checkinFee: number;
  priceForExtraPerson: number;
  instantBookable: number;
  instantBookableLeadTime: number | null;
  airbnbBookingLeadTime: number;
  airbnbBookingLeadTimeAllowRequestToBook: number;
  airbnbName: string;
  airbnbSummary: string;
  airbnbSpace: string;
  airbnbAccess: string;
  airbnbInteraction: string;
  airbnbNeighborhoodOverview: string;
  airbnbTransit: string;
  airbnbNotes: string;
  airbnbExportStatus: string;
  vrboExportStatus: string;
  marriotExportStatus: string | null;
  bookingcomExportStatus: string;
  expediaExportStatus: string | null;
  googleExportStatus: string;
  allowSameDayBooking: number;
  sameDayBookingLeadTime: number;
  contactName: string | null;
  contactSurName: string | null;
  contactPhone1: string | null;
  contactPhone2: string | null;
  contactLanguage: string | null;
  contactEmail: string | null;
  contactAddress: string | null;
  language: string;
  currencyCode: string;
  timeZoneName: string;
  wifiUsername: string;
  wifiPassword: string;
  cleannessStatus: string;
  cleaningInstruction: string | null;
  cleannessStatusUpdatedOn: string | null;
  homeawayPropertyName: string;
  homeawayPropertyHeadline: string;
  homeawayPropertyDescription: string;
  bookingcomPropertyName: string;
  bookingcomPropertyRoomName: string;
  bookingcomPropertyDescription: string;
  invoicingContactName: string | null;
  invoicingContactSurName: string | null;
  invoicingContactPhone1: string | null;
  invoicingContactPhone2: string | null;
  invoicingContactLanguage: string | null;
  invoicingContactEmail: string | null;
  invoicingContactAddress: string | null;
  invoicingContactCity: string | null;
  invoicingContactZipcode: string | null;
  invoicingContactCountry: string | null;
  attachment: string | null;
  applyPropertyRentTaxToFees: number | null;
  bookingEngineLeadTime: number | null;
  cancellationPolicyId: number | null;
  vrboCancellationPolicyId: number;
  marriottCancellationPolicyId: number | null;
  bookingCancellationPolicyId: number | null;
  listingFeeSetting: any[];
  isRentalAgreementActive: number | null;
  averageNightlyPrice: number | null;
  bookingcomPropertyRegisteredInVcs: number | null;
  bookingcomPropertyHasVat: number | null;
  bookingcomPropertyDeclaresRevenue: number | null;
  airbnbCancellationPolicyId: number;
  airbnbListingUrl: string;
  vrboListingUrl: string;
  googleVrListingUrl: string;
  averageReviewRating: number;
  partnersListingMarkup: number;
  airbnbOfficialListingMarkup: number;
  bookingEngineMarkup: number;
  homeawayApiMarkup: number;
  marriottListingMarkup: number;
  latestActivityOn: string;
  bookingEngineUrls: string[];
  marriottListingName: string | null;
  airbnbPetFeeAmount: number;
  insertedOn: string;
  listingSettings: ListingSettings;
}

interface ListingSettings {
  id: number;
  accountId: number;
  listingMapId: number;
  showInvoices: number;
  showInvoicesStage: string;
  showInvoicesChannels: string | null;
  showReceipts: number;
  insertedOn: string;
  updatedOn: string;
}

export const getHostawayListing = async (id: string): Promise<HAListing> =>
  (await API.get(`/v1/admin/pms/hostaway/listings/${id}`)).reservation;

export const syncHostwayListing = async (id: number): Promise<HAListing> =>
  await API.post(`/v1/admin/pms/hostaway/listings/${id}/sync`, {});

export const getHostawayReservation = async (
  id: string
): Promise<HAReservation> =>
  (await API.get(`/v1/admin/pms/hostaway/reservations/${id}`)).reservation;
