import { Flex, Heading, TextAreaField } from "@aws-amplify/ui-react";
import Loader from "../shared/components/Loader";

export default ({
  defaultValue,
  required,
  onChange,
  updating,
  isReadOnly,
}: {
  isReadOnly?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
  updating: boolean;
  defaultValue?: string;
}) => {
  return (
    <Flex marginBottom={35} direction={"column"}>
      <Heading>Notes {required ? "*" : ""}</Heading>

      <Flex direction={"column"}>
        <Flex boxShadow={"small"} position="relative">
          <TextAreaField
            isReadOnly={isReadOnly}
            defaultValue={defaultValue}
            grow={1}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            label=""
            placeholder="Add notes here"
          ></TextAreaField>
          {updating && (
            <Flex position={"absolute"} right={16} top={16}>
              <Loader></Loader>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
