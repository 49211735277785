import { useState } from "react";
import DocsDetailsModal from "../Properties/DocsDetailsModal";
import GenericTable from "../shared/GenericTable";
import GenericTablePage from "../shared/GenericTablePage";
import { deleteDoc, useDocs } from "../shared/hooks/useDocs";
import Uploader from "./Uploader";

export default () => {
  const { loading, data, refetch } = useDocs();
  const [selectedRow, setSelectedRow] = useState<any>();

  return (
    <GenericTablePage
      title={"Documents"}
      subtitle=" Contracts and Handover Protocols"
      onSearch={(query) => refetch({ query })}
    >
      <Uploader
        onChange={() => {
          refetch(undefined);
        }}
      ></Uploader>

      <GenericTable
        data={data}
        loading={loading}
        onSelected={(item) => {
          setSelectedRow(item);
        }}
      ></GenericTable>

      {selectedRow && (
        <DocsDetailsModal
          onUpdated={() => {
            refetch(undefined);
          }}
          onClose={() => {
            setSelectedRow(undefined);
          }}
          actions={[
            {
              label: "Delete",
              action: async () => {
                const message = prompt("Type DELETE to delete");

                if (message === "DELETE") {
                  await deleteDoc(selectedRow.file_key);

                  setSelectedRow(undefined);
                  refetch();
                }
              },
            },
          ]}
          data={selectedRow}
        ></DocsDetailsModal>
      )}
    </GenericTablePage>
  );
};
