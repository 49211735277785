import { Flex, Text } from "@aws-amplify/ui-react";
import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Headline2 from "../shared/components/Headline2";
import DocComponent from "./DocComponent";
import { UserDocument } from "./UserDocument";

export default function DocsSection({ docs }: { docs: UserDocument[] }) {
  return (
    <Flex direction={"column"}>
      <Headline2>Documents</Headline2>
      {docs.map((doc) => (
        <DocComponent doc={doc}></DocComponent>
      ))}

      {docs.length === 0 && <Text>No documents</Text>}
    </Flex>
  );
}
