import {
  Alert,
  Badge,
  Card,
  Flex,
  Placeholder,
  SelectField,
  Text,
} from "@aws-amplify/ui-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useAccountActiveYears from "../../Payouts/useAccountActiveYears";
import Headline from "../../shared/components/Headline";
import DownloadReportButton from "../DownloadReportButton";
import { useReportingPayoutsApi } from "../useReportingPayoutsApi";
import ReportingReservationsTable from "./PayoutsProjectionsTable";

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, error, loading, refetch } = useReportingPayoutsApi();
  const [month, setMonth] = useState<number>(
    parseInt(searchParams.get("month")!)
      ? parseInt(searchParams.get("month")!) - 1
      : moment().month()
  );
  const [year, setYear] = useState<number>(
    parseInt(searchParams.get("year")!) || moment().subtract(1, "months").year()
  );

  useEffect(() => {
    setSearchParams({ year: String(year), month: String(month + 1) });
    const baseDate = moment(`${year}-${month + 1}`);

    refetch({
      start: baseDate.startOf("month").format("YYYY-MM-DD"),
      end: baseDate.endOf("month").format("YYYY-MM-DD"),
    });
  }, [month, year, refetch, setSearchParams]);

  const { activeYears } = useAccountActiveYears();

  return (
    <Flex direction={"column"} grow={1}>
      <Flex direction="column">
        <Headline>Payout Projection</Headline>
        <Text>
          Reporting for how much we will need to transfer to owners by 10th of
          the month.
        </Text>
      </Flex>

      <Flex direction={"column"}>
        <Card variation="elevated">
          <Flex direction="column" grow={1}>
            <Flex direction="row" gap={50} justifyContent={"space-between"}>
              <Flex direction={"column"} gap={8}>
                <Text textAlign={"flex-start"}>Month</Text>
                <Flex gap={5} alignItems="center">
                  <SelectField
                    labelHidden={true}
                    label=""
                    size="small"
                    defaultValue={String(month)}
                    disabled={loading}
                    onChange={(e) => {
                      setMonth(parseInt(e.target.value));
                    }}
                  >
                    {moment.months().map((monthLabel, index) => (
                      <option key={monthLabel} value={index}>
                        {monthLabel}
                      </option>
                    ))}
                  </SelectField>
                  <SelectField
                    labelHidden={true}
                    label=""
                    size="small"
                    defaultValue={String(year)}
                    disabled={loading}
                    onChange={(e) => {
                      setYear(parseInt(e.target.value));
                    }}
                  >
                    {activeYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </SelectField>
                </Flex>
              </Flex>

              <Flex direction={"column"} justifyContent={"center"}>
                <Flex direction="row">
                  <Badge>by departure</Badge>
                  <DownloadReportButton
                    filename={`${year}-${
                      month + 1
                    }-reservations-${moment().format("YYYY-MM-DD HHmm")}`}
                    data={data}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        {error && (
          <Alert marginTop={5} variation="error">
            {error.message}
          </Alert>
        )}

        {loading && (
          <Flex direction={"column"} grow={1} gap={12}>
            <Placeholder height={50}></Placeholder>
            <Placeholder height={50}></Placeholder>
            <Placeholder height={50}></Placeholder>
            <Placeholder height={50}></Placeholder>
          </Flex>
        )}

        {!loading && !!data && (
          <Flex direction={"column"}>
            {!data.ok && (
              <Alert marginTop={5} variation="error">
                Validation Failed
              </Alert>
            )}
            <ReportingReservationsTable sum={data.sum} items={data.items} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
