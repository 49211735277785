import { Button, Flex, Heading, TextAreaField } from "@aws-amplify/ui-react";
import moment from "moment-timezone";
import LoaderBox from "../shared/components/LoaderBox";
import {
  useTaskComments,
  useTaskCommentsCreate,
} from "../shared/hooks/useTasksApi";

import { useState } from "react";
import TextMuted from "../shared/components/TextMuted";
import { MdComment } from "react-icons/md";

export default function TaskChat({ taskId }: { taskId: string }) {
  // use "en" locale in admin pages
  moment.locale("en");

  const { data, loading, refetch } = useTaskComments(taskId);
  const [comment, setComment] = useState<string>();
  const { create } = useTaskCommentsCreate(taskId);

  return (
    <Flex direction={"column"} grow={1} overflow={"hidden"}>
      <Heading>
        <MdComment></MdComment> Comments
      </Heading>

      {loading && <LoaderBox></LoaderBox>}

      <TextAreaField
        grow={1}
        value={comment}
        onChange={(event) => {
          setComment(event.target.value);
        }}
        label=""
        placeholder="Add your comment here"
      ></TextAreaField>

      <Button
        disabled={!comment}
        onClick={async () => {
          if (comment) {
            await create({
              body: comment,
            });

            refetch();
            setComment("");
          }
        }}
      >
        Send
      </Button>

      {data?.length === 0 && (
        <Flex direction="row">
          <TextMuted>No comments</TextMuted>
        </Flex>
      )}

      {data &&
        data.map((comment, index) => (
          <Flex direction="column" key={index}>
            <Flex>
              {comment.username}
              <TextMuted>commented</TextMuted>
            </Flex>
            <Flex>{comment.body}</Flex>
            <Flex>
              <TextMuted style={{ fontSize: 12 }}>
                {moment(comment.created_at).format("llll")}
              </TextMuted>
            </Flex>
          </Flex>
        ))}
    </Flex>
  );
}
