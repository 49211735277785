import {
  Alert,
  Button,
  Card,
  Flex,
  Heading,
  SelectField,
} from "@aws-amplify/ui-react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MdRefresh } from "react-icons/md";
import { logger } from "../../../logger";
import Box from "../../shared/components/Box";
import Headline from "../../shared/components/Headline";
import Headline2 from "../../shared/components/Headline2";
import HelpButton from "../../shared/components/HelpButton";
import LoaderBox from "../../shared/components/LoaderBox";
import Stats from "../../shared/components/Stats";
import {
  IBackgroundJob,
  JobStatus,
  JobType,
  SortFlag,
  useJobsApi,
} from "../../shared/hooks/useJobsApi";
import { useOwnerStatements } from "../../shared/hooks/useOwnerStatement";
import { useProperties } from "../../shared/hooks/useProperties";
import { formatHeader } from "../../shared/utils/formatHeader";
import useAccountActiveYears from "../useAccountActiveYears";
import { getProposalState } from "./AdminPayoutHelpers";
import AdminPayoutPlanningDetails from "./AdminPayoutPlanningDetails";
import AdminPayoutPlanningTable, {
  UIIStatementProposal,
} from "./AdminPayoutPlanningTable";

export default function PayoutPlanningPage() {
  // use "en" locale in admin pages
  moment.locale("en");

  const [loadingState, setLoadingState] = useState<
    "ready" | "sync" | "fetch" | "backgroundFetch"
  >("fetch");
  const [statementProposals, setStatementProposals] = useState<
    UIIStatementProposal[]
  >([]);
  const [stats, setStats] = useState<{ value: number; name: string }[]>([]);
  const [syncJobStatus, setSyncJobStatus] = useState<IBackgroundJob>();

  const [missingSettlements, setMissingSettlements] = useState<number[]>([]);
  const [selectedRow, setSelectedRow] = useState<UIIStatementProposal>();
  const [month, setMonth] = useState<number>(
    moment().subtract(1, "months").month()
  );
  const [year, setYear] = useState<number>(
    moment().subtract(1, "months").year()
  );
  const [error, setError] = useState<Error>();

  const { getProposals, syncProposals, publishProposal, approveProposal } =
    useOwnerStatements();

  const { getJobs } = useJobsApi();
  const { activeYears } = useAccountActiveYears();
  const { getProperties } = useProperties();
  const [propertyMap, setPropertyMap] = useState<Map<number, string>>(
    new Map()
  );

  const fetchProperties = useCallback(async () => {
    const properties = await getProperties();

    const map = properties.reduce((acc, property) => {
      acc.set(property.hostaway_id as number, property.name);

      return acc;
    }, new Map<number, string>());

    setPropertyMap(map);
  }, [getProperties]);

  const fetchProposals = useCallback(
    async (year: number, month: number, backgroundFetch = false) => {
      try {
        if (backgroundFetch === false) {
          setLoadingState("fetch");
        }

        const baseDate = moment(`${year}-${month + 1}`);

        const data = await getProposals(
          baseDate.startOf("month").format("YYYY-MM-DD"),
          baseDate.endOf("month").format("YYYY-MM-DD")
        );

        setStats(
          data.stats.map((stat) => {
            return {
              ...stat,
              name: formatHeader(stat.name),
            };
          })
        );
        setStatementProposals(
          data.proposals.map((proposal) => ({ ...proposal, inEdit: false }))
        );
        setMissingSettlements(data.missing);
      } catch (error) {
        setError(error as Error);
      } finally {
        if (backgroundFetch === false) {
          setLoadingState("ready");
        }
      }
    },
    [getProposals]
  );

  const fetchBgJobStatus = useCallback(async () => {
    try {
      const latestJob = (
        await getJobs(
          JobType.SyncPayoutProposals,
          undefined,
          1,
          SortFlag.Descending
        )
      )[0];

      setSyncJobStatus(latestJob || null);

      if (
        latestJob &&
        (latestJob.status === JobStatus.Queued ||
          latestJob.status === JobStatus.Running)
      ) {
        setLoadingState("sync");
        return;
      }

      if (loadingState === "sync" && latestJob?.progress === "100") {
        fetchProposals(year, month);
        return;
      }
    } catch (error) {
      setError(error as Error);
    }
  }, [getJobs, setSyncJobStatus, fetchProposals, loadingState, month, year]);

  const syncSettlements = useCallback(async () => {
    if (syncJobStatus !== null) {
      if (
        !syncJobStatus ||
        (syncJobStatus.status !== JobStatus.Succeeded &&
          syncJobStatus.status !== JobStatus.Failed)
      ) {
        alert("Sync already in progress");

        return;
      }
    }

    try {
      setLoadingState("sync");

      const baseDate = moment(`${year}-${month + 1}`);

      await syncProposals(
        baseDate.startOf("month").format("YYYY-MM-DD"),
        baseDate.endOf("month").format("YYYY-MM-DD")
      );
    } catch (error) {
      setError(
        new Error(
          `Could not sync proposals, check network log for details. ${
            (error as Error).message
          }`
        )
      );
    }
    // TODO fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncJobStatus]);

  const markProposalApproved = async (proposal: UIIStatementProposal) => {
    try {
      setStatementProposals(
        statementProposals.map((item) =>
          item.payout_id === proposal.payout_id
            ? { ...item, inEdit: true }
            : item
        )
      );

      await approveProposal(proposal.payout_id);
      await fetchProposals(year, month, true);
    } catch (error) {
      logger.error(error);
      setError(error as Error);
    } finally {
      setLoadingState("ready");
    }
  };

  const publishApprovedProposals = async () => {
    try {
      setLoadingState("sync");

      await Promise.all(
        statementProposals
          .filter((proposal) => proposal.status === "approved")
          .map((proposal) => publishProposal(proposal.payout_id))
      );

      await fetchProposals(year, month);
    } catch (error) {
      logger.error(error);
      setError(error as Error);
    } finally {
      setLoadingState("ready");
    }
  };

  const countApprovedProposals = () =>
    statementProposals.filter((item) => item.status === "approved").length;

  useEffect(() => {
    // will fetch once at the start, and once we want to synchronize or do any other action
    fetchBgJobStatus();
  }, [fetchBgJobStatus]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  const isSynchronised = useMemo(() => {
    // no job yet
    if (syncJobStatus === null) {
      return true;
    }

    // no job running
    if (
      syncJobStatus?.status !== JobStatus.Queued &&
      syncJobStatus?.status !== JobStatus.Running
    ) {
      return true;
    }

    return false;
  }, [syncJobStatus]);

  useEffect(() => {
    if (isSynchronised) {
      setStatementProposals([]);
      fetchProposals(year, month);
    }
  }, [month, year, fetchProposals, isSynchronised]);

  return (
    <Flex direction={"column"} grow={1}>
      <Flex>
        <Headline>Payouts Planning</Headline>
      </Flex>
      <Box>
        <Card variation="elevated">
          <Flex direction="column" grow={1}>
            <Flex direction="row" gap={50} justifyContent={"space-between"}>
              <Flex direction={"column"} gap={8}>
                <Flex gap={5} alignItems="center">
                  <SelectField
                    labelHidden={true}
                    label=""
                    size="small"
                    defaultValue={String(month)}
                    disabled={loadingState !== "ready"}
                    onChange={(e) => {
                      setMonth(parseInt(e.target.value));
                    }}
                  >
                    {moment.months().map((monthLabel, index) => (
                      <option key={monthLabel} value={index}>
                        {monthLabel}
                      </option>
                    ))}
                  </SelectField>
                  <SelectField
                    labelHidden={true}
                    label=""
                    size="small"
                    defaultValue={String(year)}
                    disabled={loadingState !== "ready"}
                    onChange={(e) => {
                      setYear(parseInt(e.target.value));
                    }}
                  >
                    {activeYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </SelectField>
                </Flex>
              </Flex>

              <Flex direction={"column"} gap={8} justifyContent={"center"}>
                <Flex gap={5} textAlign={"center"}>
                  <Flex gap={25} textAlign={"center"}>
                    <Button
                      size="small"
                      variation="primary"
                      disabled={loadingState !== "ready"}
                      onClick={syncSettlements}
                    >
                      Synchronize
                    </Button>
                  </Flex>
                  <Flex gap={25} textAlign={"center"}>
                    <Button
                      size="small"
                      variation="primary"
                      disabled={
                        loadingState !== "ready" ||
                        countApprovedProposals() === 0
                      }
                      onClick={publishApprovedProposals}
                    >
                      Publish
                    </Button>
                  </Flex>
                  <Flex gap={25} textAlign={"center"}>
                    <Button
                      disabled={!isSynchronised}
                      size="small"
                      onClick={() => fetchProposals(year, month)}
                    >
                      <MdRefresh size={21} />
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>

        {error && (
          <Alert
            marginTop={5}
            variation="error"
            isDismissible={true}
            onDismiss={() => {
              setError(undefined);
            }}
          >
            {error.message}
          </Alert>
        )}

        {loadingState === "ready" &&
          Boolean(statementProposals.length) &&
          Boolean(missingSettlements.length) && (
            <Alert marginTop={10} variation="warning" isDismissible={true}>
              <Flex direction={"column"}>
                <Flex>
                  <Heading level={6}>
                    Settlements are not defined for the selected period for
                    following properties:
                  </Heading>
                </Flex>
                <Flex direction={"row"} wrap={"wrap"} columnGap={16} rowGap={4}>
                  <ul>
                    {missingSettlements.map((id, index) => (
                      <li key={index}>
                        ({id}) {propertyMap.get(id) ?? "n/a"}
                      </li>
                    ))}
                  </ul>
                </Flex>
              </Flex>
            </Alert>
          )}

        {loadingState === "ready" &&
          syncJobStatus?.status === JobStatus.Failed &&
          syncJobStatus?.resultMessage && (
            <Alert marginTop={10} variation="warning" isDismissible={true}>
              <Flex direction={"column"}>
                <Flex>
                  <Heading level={6}>
                    Last sync failed with following messages:
                  </Heading>
                </Flex>
                <Flex direction={"row"} wrap={"wrap"} columnGap={16} rowGap={4}>
                  {syncJobStatus.resultMessage.split("\n").map((line) => (
                    <>
                      {line}
                      <br />
                    </>
                  ))}
                </Flex>
              </Flex>
            </Alert>
          )}

        {loadingState === "fetch" && (
          <Flex justifyContent={"center"} marginTop={64}>
            <LoaderBox />
          </Flex>
        )}

        {loadingState === "ready" && !statementProposals.length && (
          <Flex justifyContent={"center"} marginTop={64}>
            <Headline2>No settlements</Headline2>
          </Flex>
        )}

        {loadingState === "sync" && (
          <Flex padding={40} justifyContent={"center"}>
            <Flex direction={"column"}>
              {syncJobStatus && (
                <Flex direction={"column"} alignItems={"center"}>
                  <Flex>
                    Payout data is being synchronised at{" "}
                    {Math.round(Number(syncJobStatus.progress))}%.
                  </Flex>
                  <Flex>
                    Status: {syncJobStatus.status} #{syncJobStatus.id}
                  </Flex>
                </Flex>
              )}

              <Flex
                alignItems={"center"}
                gap={25}
                textAlign={"center"}
                justifyContent={"center"}
              >
                <Button size="small" onClick={fetchBgJobStatus}>
                  <MdRefresh size={21} />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        )}

        {(loadingState === "ready" || loadingState === "backgroundFetch") &&
          !!statementProposals.length && (
            <>
              <Flex justifyContent={"space-between"}>
                <Stats stats={stats}></Stats>
                <HelpButton url="https://writer.zoho.eu/writer/open/dqyte63f81eb251284bed93cb4325fac775e2" />
              </Flex>

              <AdminPayoutPlanningTable
                proposals={statementProposals}
                getProposalState={getProposalState}
                setSelectedRow={setSelectedRow}
              />
            </>
          )}

        {selectedRow && (
          <AdminPayoutPlanningDetails
            approveProposal={markProposalApproved}
            getProposalState={getProposalState}
            handleClose={() => setSelectedRow(undefined)}
            proposal={selectedRow}
          />
        )}
      </Box>
    </Flex>
  );
}
