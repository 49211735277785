import { useState } from "react";
import { useLazyApi } from "./useLazyApi";
import { API } from "../utils/API";

export interface BankAccount {
  bic: string;
  currency: string;
  iban: string;
  id: number;
  owner_name: string;
  use_for_payout: boolean;
  user_id: number;
}

export const useBankAccounts = (userId: number) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingSuccess, setSavingSuccess] = useState<boolean>(false);
  const [savingError, setSavingError] = useState<Error>();

  const {
    data: bankAccounts,
    error: loadingError,
    loading: isLoading,
    load: loadBankAccounts,
  } = useLazyApi<BankAccount[], undefined>(
    `/v1/admin/contracts/bank-accounts/user-id/${userId}`
  );

  const updateBankAccount = async (bankAccount: BankAccount) => {
    try {
      setIsSaving(true);
      setSavingSuccess(false);
      setSavingError(undefined);

      await API.post(`/v1/admin/contracts/bank-accounts`, {
        body: bankAccount,
      });

      setSavingSuccess(true);
    } catch (error) {
      setSavingError(error as Error);
    } finally {
      setIsSaving(false);
    }
  };

  return {
    loadBankAccounts,
    bankAccounts,
    updateBankAccount,
    isLoading,
    loadingError,
    isSaving,
    savingError,
    savingSuccess,
  };
};
