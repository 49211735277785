import {
  Badge,
  Flex,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdCheck, MdEdit, MdRemove, MdWarning } from "react-icons/md";
import { logger } from "../../logger";
import { theme } from "../../theme";
import { useTableFilter } from "../shared/components/tables/useTableFiltering";
import { Contract, useAdminContracts } from "../shared/hooks/useAdminContracts";
import { columns } from "./columns";
import Headline from "../shared/components/Headline";
import LoaderBox from "../shared/components/LoaderBox";

export default () => {
  const { getContracts } = useAdminContracts();
  const [contracts, setContracts] = useState<Contract[]>([]);
  const { rowData, sortDataFn } = useTableFilter(contracts);
  const [, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      setError(undefined);

      try {
        await getContracts();
        setContracts(await getContracts());
      } catch (error) {
        logger.error(error);
        setError(error as Error);
      } finally {
        setIsLoading(false);
      }
    };

    load();
  }, [setError, setIsLoading, getContracts]);

  return (
    <Flex direction={"column"} overflow={"hidden"}>
      <Flex>
        <Headline>Contracts</Headline>
      </Flex>

      <Flex>{isLoading && <LoaderBox></LoaderBox>}</Flex>

      <Flex overflow={"scroll"}>
        {!isLoading && Boolean(contracts.length) && (
          <Table caption="" highlightOnHover={false}>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={`column-${index}`}
                    as="th"
                    textAlign={column.textAlign || "left"}
                    onClick={() =>
                      column.fieldKey
                        ? sortDataFn(column.fieldKey, column.type)
                        : undefined
                    }
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((item, index) => (
                <TableRow
                  key={`row-${item.contract_id}-${index}`}
                  backgroundColor={
                    !item.first_name || !item.last_name
                      ? theme.errorColor
                      : undefined
                  }
                >
                  <TableCell>{item.contract_id}</TableCell>

                  <TableCell>{item.user_id}</TableCell>

                  <TableCell>
                    {item.first_name} {item.last_name}
                  </TableCell>

                  <TableCell>{item.company_name}</TableCell>

                  <TableCell>{item.name}</TableCell>

                  <TableCell textAlign="center">
                    {item.property_disabled && !item.contract_cancelled ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            Property is disabled even though the contract is not
                            cancelled!
                          </Tooltip>
                        }
                      >
                        <div>
                          <MdWarning color="red" size={30} />
                        </div>
                      </OverlayTrigger>
                    ) : item.contract_cancelled ? (
                      <MdRemove color="grey" size={30} />
                    ) : (
                      <MdCheck color="green" size={30} />
                    )}
                  </TableCell>

                  <TableCell>
                    <Flex>
                      {item.contract_type === "mgmt" && (
                        <Badge variation="success">Mgmt</Badge>
                      )}
                      {item.contract_type === "invest" && (
                        <Badge variation="info">Invest</Badge>
                      )}
                    </Flex>
                  </TableCell>

                  <TableCell>
                    <Flex>
                      <Link
                        textDecoration="underline dotted"
                        isExternal
                        href={`/contract/details/${item.contract_type}/${item.contract_id}`}
                      >
                        <MdEdit></MdEdit>
                      </Link>
                    </Flex>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Flex>
    </Flex>
  );
};
