import { Authenticator, Flex } from "@aws-amplify/ui-react";
import { Outlet, RouteObject } from "react-router-dom";
import AuthWrapper from "./domains/Auth/AuthWrapper";
import RequireRoleGuard from "./domains/Auth/RequireRoleGuard";
import BackgroundJobs from "./domains/BackgroundJobs/BackgroundJobs";
import CleaningScheduleList from "./domains/CleaningSchedule/CleaningSchedulePage";
import AdminContractDetail from "./domains/Contracts/ContractDetail";
import ContractsListPage from "./domains/Contracts/ContractsListPage";
import DocsPage from "./domains/Docs/DocsPage";
import Layout from "./domains/Layout";
import AdminPayoutPlanning from "./domains/Payouts/AdminPayoutPlanning/AdminPayoutPlanning";
import AdminPayouts from "./domains/Payouts/AdminPayouts/AdminPayouts";
import PropertiesAccessPage from "./domains/Properties/PropertiesAccessPage";
import PropertiesList from "./domains/Properties/PropertiesPage";
import AdminPropertyDetail from "./domains/Properties/PropertyDetailsPage";
import ChargesPage from "./domains/Reporting/Charges/ChargesPage";
import ReportingConsolidatedPage from "./domains/Reporting/Consolidated/ReportingConsolidatedPage";
import PayoutsProjectionsPage from "./domains/Reporting/PayoutsProjections/PayoutsProjectionsPage";
import ReportingReservationsPage from "./domains/Reporting/Reservations/ReportingReservationsPage";
import CoreReservationEdit from "./domains/Search/CoreReservationEdit";
import OccupancyOverview from "./domains/Search/OccupancyOverview";
import SearchPage from "./domains/Search/SearchPage";
import Box from "./domains/shared/components/Box";
import RentujemyLogo from "./domains/shared/components/RentujemyLogo";
import UserLanguageApplier from "./domains/shared/components/UserLanguageApplier";
import { UserProfileProvider } from "./domains/shared/components/UserProfileProvider";
import AdminUserDetailPage from "./domains/Users/AdminUserDetailPage";
import AdminUsersPage from "./domains/Users/AdminUsersPage";
import TaskPage from "./domains/CleaningSchedule/TaskPage";
import AdminServiceUsersPage from "./domains/Users/AdminServiceUsersPage";
import ServiceRepSchedulePage from "./domains/CleaningSchedule/ServiceRepSchedulePage";
import TasksReportPage from "./domains/CleaningSchedule/TasksReportPage";

const adminRoutes: RouteObject[] = [
  {
    path: "/",
    errorElement: (
      <Flex alignItems="center" justifyContent="center" height="100vh">
        <Flex direction={"column"} textAlign={"center"}>
          <Box style={{ margin: "0 auto", maxWidth: 600, textAlign: "center" }}>
            <RentujemyLogo />
          </Box>

          <p>We apologize, but something went wrong on our side.</p>
          <p>
            Please try again later. If the problem persists, please contact our
            customer service department.
          </p>
          <p>
            <a href="/">Go back to the main page.</a>
          </p>
        </Flex>
      </Flex>
    ),
    element: (
      <Authenticator.Provider>
        <AuthWrapper>
          <UserProfileProvider>
            <UserLanguageApplier>
              <Layout>
                <Outlet />
              </Layout>
            </UserLanguageApplier>
          </UserProfileProvider>
        </AuthWrapper>
      </Authenticator.Provider>
    ),
    children: [
      {
        path: "",
        element: (
          <RequireRoleGuard
            forwardCoordinatorTo={"/cleaning-schedule"}
            forwardServiceTo={"rep/schedule"}
            shouldBeAccounting={true}
          >
            <SearchPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "reservation/:id",
        element: (
          <RequireRoleGuard shouldBeAdmin={true}>
            <CoreReservationEdit />
          </RequireRoleGuard>
        ),
      },
      {
        path: "reporting",
        element: (
          <RequireRoleGuard shouldBeAccounting={true}>
            <ReportingReservationsPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "reporting/consolidated",
        element: (
          <RequireRoleGuard shouldBeAccounting={true}>
            <ReportingConsolidatedPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "reporting/charges",
        element: (
          <RequireRoleGuard shouldBeAccounting={true}>
            <ChargesPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "reporting/payouts",
        element: (
          <RequireRoleGuard shouldBeAdmin={true}>
            <PayoutsProjectionsPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "occupancy",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <OccupancyOverview />
          </RequireRoleGuard>
        ),
      },
      {
        path: "payouts-planning",
        element: (
          <RequireRoleGuard shouldBeAdmin={true}>
            <AdminPayoutPlanning />
          </RequireRoleGuard>
        ),
      },
      {
        path: "payouts",
        element: (
          <RequireRoleGuard shouldBeAdmin={true}>
            <AdminPayouts />
          </RequireRoleGuard>
        ),
      },
      {
        path: "users",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <AdminUsersPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "users/details",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <AdminUserDetailPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "property/details",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <AdminPropertyDetail />
          </RequireRoleGuard>
        ),
      },
      {
        path: "contracts",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            {" "}
            <ContractsListPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "documents",
        element: (
          <RequireRoleGuard shouldBeAdmin={true}>
            <DocsPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "properties",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <PropertiesList />
          </RequireRoleGuard>
        ),
      },
      {
        path: "access-instructions",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <PropertiesAccessPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "background-jobs",
        element: (
          <RequireRoleGuard shouldBeAdmin={true}>
            <BackgroundJobs />
          </RequireRoleGuard>
        ),
      },
      {
        path: "contract/details/:contractType/:contractId",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <AdminContractDetail />
          </RequireRoleGuard>
        ),
      },
      {
        path: "cleaning-schedule",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <CleaningScheduleList />
          </RequireRoleGuard>
        ),
      },
      {
        path: "company/people",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <AdminServiceUsersPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "rep/schedule",
        element: (
          <RequireRoleGuard shouldBeService={true}>
            <ServiceRepSchedulePage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "rep/report",
        element: (
          <RequireRoleGuard shouldBeService={true}>
            <TasksReportPage />
          </RequireRoleGuard>
        ),
      },
      {
        path: "task/:taskId",
        element: (
          <RequireRoleGuard shouldBeCoordinator={true}>
            <TaskPage />
          </RequireRoleGuard>
        ),
      },
    ],
  },
];

export default adminRoutes;
