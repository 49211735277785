import { useApi } from "./useApi";

export interface CognitoUser {
  cognito_id: string;
  email: string;
}

export const useCognitoUsers = () =>
  useApi<CognitoUser[], undefined>(`/v1/admin/cognito`);

export const useCognitoServiceUsers = () =>
  useApi<CognitoUser[], undefined>(`/v1/admin/cognito/service`);
