import React from "react";

import { Link } from "@aws-amplify/ui-react";

import { theme } from "../../../theme";

export default (props: {
  label?: string | number;
  href: string;
  utmSource?: string;
}) => (
  <Link
    color={theme.primaryColor}
    textDecoration="underline dotted"
    isExternal={true}
    href={
      props.utmSource
        ? `${props.href}?utm_source=${props.utmSource}`
        : props.href
    }
    target="_blank"
  >
    {props.label}
  </Link>
);
