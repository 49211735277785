import { Flex, Text } from "@aws-amplify/ui-react";
import { CoordinationTask } from "../../shared/hooks/core-coordination-task";
import { useMemo } from "react";
import { getColorForTask } from "./getStyleForTaskCard";

export default ({ task }: { task: CoordinationTask }) => {
  const bgColor = useMemo(() => {
    return getColorForTask({ task });
  }, [task]);

  return (
    <>
      {bgColor && (
        <Flex
          backgroundColor={bgColor}
          grow={1}
          height={30}
          justifyContent={"flex-end"}
          padding={4}
        >
          <Text style={{ color: "white" }}>{task.duration}</Text>
        </Flex>
      )}
    </>
  );
};
