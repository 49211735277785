import { useEffect, useState } from "react";
import Box from "../shared/components/Box";
import { Alert, Flex } from "@aws-amplify/ui-react";
import moment from "moment";
import { IBackgroundJob, useJobsApi } from "../shared/hooks/useJobsApi";
import React from "react";
import Headline from "../shared/components/Headline";

export default () => {
  // use "en" locale in admin pages
  moment.locale("en");

  const { getJobs } = useJobsApi();

  const [error, setError] = useState<Error>();

  const [backgroundJobs, setBackgroundJobs] = useState<IBackgroundJob[]>([]);

  const fetchJobs = async () => {
    try {
      setBackgroundJobs(await getJobs());
    } catch (error) {
      setError(error as Error);
    }
  };

  useEffect(() => {
    setBackgroundJobs([]);
    fetchJobs();

    // TODO fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Flex marginBottom={25}>
        <Headline>Background jobs</Headline>
      </Flex>

      {backgroundJobs.map((row) => (
        <div>
          {row.id} {row.type} {row.status} {row.progress}
        </div>
      ))}

      {error && (
        <Alert
          marginTop={5}
          variation="error"
          isDismissible={true}
          onDismiss={() => {
            setError(undefined);
          }}
        >
          {error.message}
        </Alert>
      )}
    </Box>
  );
};
