import Modal from "../shared/components/Modal";
import TaskPage from "./TaskPage";

export default ({
  onClose,
  taskId,
}: {
  taskId: string;
  onClose: () => void;
}) => {
  return (
    <>
      <Modal size={"xl"} title={"Task"} onClose={onClose}>
        <TaskPage taskId={taskId}></TaskPage>
      </Modal>
    </>
  );
};
